import axios from 'axios';

const API_URL = `${process.env.REACT_APP_HOST}/assessment-response/`;

const createAssessmentResponse = (assessmentResponse) => {
    return axios.post(API_URL + 'create', assessmentResponse);
}

const getAssessmentResponse = (id) => {
    return axios.get(API_URL + 'findOne/' + id);
}

const getAllAssessmentResponses = (user_id) => {
    return axios.get(API_URL + 'findAll/' + user_id);
}

const getManyAssessmentResponses = (query) => {
    return axios.put(API_URL + 'findMany', query)
}

const getAllAssessmentResponsesByAllUsers = () => {
    return axios.get(API_URL + 'findAllByAllUsers');
}

const findOneByUserIdAndAssessmentId = ({user_id, assessment_id}) => {
    return axios.post(API_URL + 'findOneByUserIdAndAssessmentId/' + user_id + '/' + assessment_id);
}

const updateAssessmentResponse = async ({data, assessmentResponseId}) => {
    return await axios.put(API_URL + "update/" + assessmentResponseId, data)
}

const scoreSetter = async ({data, assessmentResponseId}) => {
    return await axios.put(API_URL + "setScore/" + assessmentResponseId, data)
}

const checkRecruitmentResponseExists = async ({user_id, assessment_id}) => {
    return await axios.get(API_URL + "checkRecruitmentResponseExists/"+ user_id + "/" + assessment_id)
}

const AssessmentResponseService = {
    createAssessmentResponse,
    getAssessmentResponse,
    getAllAssessmentResponses,
    getManyAssessmentResponses,
    getAllAssessmentResponsesByAllUsers,
    findOneByUserIdAndAssessmentId,
    updateAssessmentResponse,
    scoreSetter,
    checkRecruitmentResponseExists
}

export default AssessmentResponseService;