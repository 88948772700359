import axios from "axios";

const API_URL = `${process.env.REACT_APP_HOST}/program/`;

const getAllPrograms = ({user_id, username, user_group}) => {

    if(user_id){return axios.get(API_URL + "getAllPrograms/" + user_id +"/"+ username+"/"+user_group)}
    else{return axios.get(API_URL + "getAllPrograms");}
}

const getAllProgramsForLearners = () => {

    return axios.get(API_URL + "getAllProgramsForLearners")
}

const getProgram = (id) => {
    return axios.get(API_URL + "getProgram/" + id);
}

const createProgram = (formData) => {

    return axios.post(API_URL + "createProgram", formData)
}

const addProgramOtherDetail = ({program_id,form}) => {
    return axios.post(API_URL + "addProgramOtherDetail/" + program_id, form);
}

const clearProgramOtherDetail = ({program_id}) => {
    return axios.put(API_URL + "clearProgramOtherDetail/" + program_id);
}

const editProgram = (formData, program_id) => {
    return axios.put(API_URL + "updateProgram/"+program_id, formData);
}

const getAllProgramsEnrolledByUser = (user_id) => {
    return axios.get(API_URL + "getAllProgramsEnrolledByUser/"+ user_id);
}

const deleteProgram = (program_id) => {
    return axios.put(API_URL + "deleteProgram/"+ program_id);
}

const unitCompletion = (learner_id, program_id, course_id, course_completion_weight, module_id, module_completion_weight, unit_id, unit_completion_weight) => {
    return axios.put(API_URL + "unitCompletion", {
        learner_id,
        program_id,
        course_id,
        course_completion_weight,
        module_id,
        module_completion_weight,
        unit_id,
        unit_completion_weight,
    });
}

const getCompletionStatus = (learner_id,program_id) => {
    console.log(API_URL + "getCompletionStatus/" + learner_id + "/" + program_id);
    return axios.get(API_URL + "getCompletionStatus/" + learner_id + "/" + program_id);
}

const addCourse = (program_id, course) => {
    return axios.post(API_URL + "addCourse/" + program_id, course);
}

const getAllCourses = (program_id) => {
    return axios.get(API_URL + "getAllCourses/" + program_id);
}

const getCourse = (program_id, course_id) => {
    return axios.get(API_URL + "getCourse/" + program_id + "/" + course_id);
}

const updateCourse = ({program_id, course_id, course}) => {
    return axios.put(API_URL + "updateCourse/" + program_id + "/" + course_id, course);
}

const deleteCourse = (program_id, course_id) => {
    return axios.put(API_URL + "deleteCourse/" + program_id + "/" + course_id);
}

const addModule = (program_id, course_id, module) => {
    return axios.post(API_URL + "addModule/" + program_id + "/" + course_id, module);
}

const addSetOfModules = (program_id, course_id, modules) => {
    return axios.post(API_URL + "addSetOfModules/" + program_id + "/" + course_id, modules);
}

// update modules
const updateModule = ({program_id, course_id, module_id, module}) => {
    return axios.put(API_URL + "updateModule/" + program_id + "/" + course_id + "/" + module_id, module);
}

const deleteModule = ({program_id, course_id, module_id}) => {
    return axios.put(API_URL + "deleteModule/" + program_id + "/" + course_id + "/" + module_id);
}

const addUnitArticle = (program_id, course_id, module_id, unitFormData) => {
    return axios.post(API_URL + "addUnitArticle/" + program_id + "/" + course_id + "/" + module_id, unitFormData);
}

const addUnitVideoConf = (program_id, course_id, module_id, unitFormData) => {
    return axios.post(API_URL + "addUnitVideoConf/" + program_id + "/" + course_id + "/" + module_id, unitFormData);
}

const addUnitVideo = (program_id, course_id, module_id, unitFormData) => {
    return axios.post(API_URL + "addUnitVideo/" + program_id + "/" + course_id + "/" + module_id, unitFormData);
}

const addUnitAssessment = (program_id, course_id, module_id, unitFormData) => {
    return axios.post(API_URL + "addUnitAssessment/" + program_id + "/" + course_id + "/" + module_id, unitFormData);
}

const addUnitEvent = (program_id, course_id, module_id, unitFormData) => {
    return axios.post(API_URL + "addUnitEvent/" + program_id + "/" + course_id + "/" + module_id, unitFormData);
}

const addUnitAssignment = (program_id, course_id, module_id, unitFormData) => {
    return axios.post(API_URL + "addUnitAssignment/" + program_id + "/" + course_id + "/" + module_id, unitFormData);
}

const editUnitArticle = (program_id, course_id, module_id, unit_id, unitFormData) => {
    return axios.put(API_URL + "editUnitArticle/" + program_id + "/" + course_id + "/" + module_id + "/" + unit_id, unitFormData)
}

const editUnitVideo = (program_id, course_id, module_id, unit_id, unitFormData) => {
    return axios.put(API_URL + "editUnitVideo/" + program_id + "/" + course_id + "/" + module_id + "/" + unit_id, unitFormData)
}

const editUnitVideoConf = (program_id, course_id, module_id, unit_id, unitFormData) => {
    return axios.put(API_URL + "editUnitVideoConf/" + program_id + "/" + course_id + "/" + module_id + "/" + unit_id, unitFormData)
}

const editUnitAssessment = (program_id, course_id, module_id, unit_id, unitFormData) => {
    return axios.put(API_URL + "editUnitAssessment/" + program_id + "/" + course_id + "/" + module_id + "/" + unit_id, unitFormData)
}

const editUnitAssignment = (program_id, course_id, module_id, unit_id, unitFormData) => {
    return axios.put(API_URL + "editUnitAssignment/" + program_id + "/" + course_id + "/" + module_id + "/" + unit_id, unitFormData)
}

const deleteUnit = (program_id, course_id, module_id, unit_id) => {
    return axios.put(API_URL + "deleteUnit/" + program_id + "/" + course_id + "/" + module_id + "/" + unit_id)
}

const uploadImage = (formData) => {
    return axios.post(API_URL + "uploadImage", formData)
}

const findSetOfPrograms = (ProgramIdsList) => {
    return axios.post(API_URL + "findSetOfPrograms", {
        "program_ids_list" : ProgramIdsList
    });
}

const filterProgramSearch = ({search_text, user_id}) => {
    return axios.get(API_URL + "filterProgramSearch",
    {
        params: {
            search_text: search_text,
            user_id: user_id
        }
    })
}

const getAllVideoConfUnits = ({batch_id}) => {
    console.log(batch_id)
    return axios.get(API_URL + "getAllVideoConfUnits/" + batch_id);
}

const ProgramService = {
    getAllPrograms,
    getProgram,
    createProgram,
    addProgramOtherDetail,
    clearProgramOtherDetail,
    editProgram,
    getAllProgramsEnrolledByUser,
    unitCompletion,
    getCompletionStatus,
    deleteProgram,
    addCourse,
    getAllCourses,
    getCourse,
    updateCourse,
    deleteCourse,
    addModule,
    addSetOfModules,
    updateModule,
    deleteModule,
    addUnitArticle,
    addUnitVideoConf,
    addUnitVideo,
    addUnitAssessment,
    addUnitEvent,
    addUnitAssignment,
    editUnitArticle,
    editUnitVideo,
    editUnitVideoConf,
    editUnitAssessment,
    editUnitAssignment,
    deleteUnit,
    uploadImage,
    findSetOfPrograms,
    filterProgramSearch,
    getAllVideoConfUnits,
    getAllProgramsForLearners
}

export default ProgramService;