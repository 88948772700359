import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// import { DragAndDropModule } from 'quill-drag-and-drop-module';
// import ImageResize from 'quill-image-resize-module-react';

// const dragAndDropModule = new DragAndDropModule();
// const quillModules = {
//   dragAndDrop: dragAndDropModule.getModule(),
// };

// ReactQuill.Quill.register('modules/dragAndDrop', quillModules.dragAndDrop);
// ReactQuill.Quill.register('modules/imageResize', ImageResize);

import {toast} from "react-toastify"

const CustomQuillEditor = ({  data = '', onChange, uploadService, mode = "advanced", purpose, disableCopyPaste = false  }) => {
  const [editorData, setEditorData] = useState('');

  const editorRef = useRef(null);

  

  useEffect(() => {
    setEditorData(data || '');
  }, [data]);

  // const handleEditorChange = (content) => {
  //   setEditorData(content);
  //   onChange(content);
  // };

  const handleEditorChange = 
  // useMemo(
    (content, delta, source, editor) =>{
      // console.log("content:",content)
      // console.log("delta:",delta)
      // console.log("source:",source)
      // console.log("editor:",editor)
      // if (source === 'user') {
        setEditorData(content);
        onChange(content);
      // }
    }
  //   ,[onChange]
  // )

  const imageHandler = async () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
  
    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append('image', file);
      if(purpose){
        formData.append('purpose', purpose);
      }
  
      const response = await uploadService(formData);
      const url = response.data.imageUrl;
  
      const editor = editorRef.current.getEditor();
      const cursorPosition = editor.getSelection()?.index || 0;
  
      editor.insertEmbed(cursorPosition, 'image', url);
    };
  };

  const getToolbarOptions = useCallback(() => {
    switch (mode) {
      case "basic":
        return [
          ['bold', 'italic', 'underline'],
          [{ 'size': ['small', false, 'large', 'huge'] }]
        ];
      case "advanced":
        return [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          ['blockquote', 'code-block'],
          [{ 'script': 'sub' }, { 'script': 'super' }],
          [{ 'indent': '-1' }, { 'indent': '+1' }],
          [{ 'direction': 'rtl' }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          ['link', 'image', 'video'],
          ['clean'],
        ];
      case "multimedia":
        return [
          ['bold', 'italic', 'underline'],
          ['link', 'image', 'video']
        ];
      case "text-based":
        return [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          ['blockquote', 'code-block'],
          [{ 'script': 'sub' }, { 'script': 'super' }],
          [{ 'indent': '-1' }, { 'indent': '+1' }],
          // [{ 'direction': 'rtl' }],
          // [{ 'size': ['small', false, 'large', 'huge'] }],
          ['link'],
          ['clean'],
        ];
      default:
        return [];
    }
  },[mode]);

  const modules = useMemo(
    () => ({
    toolbar: {
      container: getToolbarOptions(),
      handlers:{
        image: imageHandler
      }
    },
    clipboard: {
      matchVisual: false,
    },
    // imageResize: {
    //     parchment: ReactQuill.Quill.import('parchment'),
    //     modules: ['Resize', 'DisplaySize']
    // },
    // dragAndDrop: true
  }),
  []
);
  
  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'blockquote', 'code-block',
    'script', 'sub', 'super',
    'indent', 'direction',
    'size',
    'link', 'image', 'video',
  ];

  const handlePaste = useCallback((e) => {
    if (disableCopyPaste) {
      e.preventDefault();
      toast.info('Pasting is not allowed.');
    }
  }, [disableCopyPaste]);

  // Event handler for copy
  const handleCopy = useCallback((e) => {
    if (disableCopyPaste) {
      e.preventDefault();
      toast.info('Copying is not allowed.');
    }
  }, [disableCopyPaste]);

  // Attach or detach event handlers based on disableCopyPaste flag
  useEffect(() => {
    const quillInstance = editorRef.current.getEditor();

    if (disableCopyPaste) {
      quillInstance.root.addEventListener('copy', handleCopy);
      quillInstance.root.addEventListener('paste', handlePaste);
    } else {
      quillInstance.root.removeEventListener('copy', handleCopy);
      quillInstance.root.removeEventListener('paste', handlePaste);
    }

    return () => {
      quillInstance.root.removeEventListener('copy', handleCopy);
      quillInstance.root.removeEventListener('paste', handlePaste);
    };
  }, [disableCopyPaste, handleCopy, handlePaste]);


  return (
    <ReactQuill
      onPaste={handlePaste}
      onCopy={handleCopy}
      ref={editorRef}
      value={editorData}
      onChange={handleEditorChange}
      modules={modules}
      formats={formats}
      bounds={'.custom-editor'}
      placeholder="Enter text here..."
    />
  );
};

// export default CustomQuillEditor;
export {CustomQuillEditor};


