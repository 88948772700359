import { useState, useRef } from "react";
import FileService from "../services/file.service";
import axios from "axios";
import { toast } from "react-toastify";

const ImageUploader = ({ onImageUpload, purpose }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [cancelToken, setCancelToken] = useState(null);
  const fileInputRef = useRef(null);

  const generateRandomString = () => {
    return Math.random().toString(36).substring(2, 11); // Adjust the length of the random string as needed
  };

  const uniqueId = "image_upload_" + `${generateRandomString()}`

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    try {
      if (!selectedFile) {
        toast.error("Please select a file.");
        return;
      }

      const formData = new FormData();
      formData.append("image", selectedFile);
      formData.append("purpose", purpose)

      const cancelSource = axios.CancelToken.source();
      setCancelToken(cancelSource);

      const imageData = await FileService.uploadImage(
        formData,
        (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(progress);
        },
        cancelSource.token
      );

      toast.success("Image uploaded successfully.");

      onImageUpload(imageData);

      // setSelectedFile(null);
      setUploadProgress(0);
      setCancelToken(null);
    } catch (error) {
      console.log(error);
      toast.error("An error occurred while uploading the image.");
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleCancelUpload = () => {
    if (cancelToken) {
      cancelToken.cancel("Upload cancelled by user.");
      setCancelToken(null);
      setUploadProgress(0);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    setSelectedFile(droppedFile);
  };

  return (
    <>
      <div className="fade-in-from-top fade-out-from-bottom">
        {/* <label className="block text-sm font-medium text-gray-700">
          Upload Image
        </label> */}
        <div
          className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <div className="space-y-1 text-center">
            {selectedFile && (
              <img loading="lazy" 
                src={URL.createObjectURL(selectedFile)}
                alt="Selected Image"
                className="mx-auto h-40 w-40 object-cover rounded-lg"
              />
            )}
              <>
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className="flex text-sm text-gray-600">
                  <label
                    htmlFor={uniqueId}
                    className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
                  >
                    <span>
                        {selectedFile ? "Change" : "Select"} Image
                    </span>
                    <input
                      id={uniqueId}
                      name={uniqueId}
                      type="file"
                      accept="image/*"
                      className="sr-only"
                      onChange={handleFileChange}
                      ref={fileInputRef}
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-500">PNG, JPG, GIF up to 2MB</p>
              </>
          </div>
        </div>
        <button
            type="button"
          className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={handleUpload}
          disabled={!selectedFile}
        >
          Upload
        </button>
        {uploadProgress > 0 && (
          <div className="mt-2">
            <div className="w-full bg-gray-200 rounded-lg">
              <div
                className="h-2 bg-blue-500 rounded-lg"
                style={{ width: `${uploadProgress}%` }}
              ></div>
            </div>
            <p className="mt-1 text-xs text-gray-500">{`${uploadProgress}% Uploaded`}</p>
          </div>
        )}
        {uploadProgress > 0 && (
          <button
          type="button"
            className="mt-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            onClick={handleCancelUpload}
          >
            Cancel Upload
          </button>
        )}
      </div>
    </>
  );
};

export default ImageUploader;
