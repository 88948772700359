/* eslint-disable */
import {BsFillArrowUpCircleFill} from 'react-icons/bs'
import { Link } from 'react-router-dom';

const currentYear = () => {
    return new Date().getFullYear();
}

const Footer = () => {
    return(
        <footer
        className="text-center bg-slate-200">
        <div className="p-4 text-center text-neutral-700 dark:text-neutral-200">
          © 2023-24 Copyright: 
          <a
            className="no-underline text-neutral-800 dark:text-neutral-400"
            > HPDS LLC, all right reserved
            </a>
        </div>
      </footer>
    )
}

export default Footer;