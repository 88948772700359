import React from "react";
import { useState, useEffect, useCallback } from "react";
import Navigationbar from "../../Navigationbar";
import Footer from "../../Footer";
import RegisteredView from "./RegisteredView";
import RegistrationForm from "./RegistrationForm";
import NewNavigationBar from "../../NewNavigationBar";
import AutomationService from "../../../services/automation.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../slices/auth";
import LandingSection from "./LandingSection";

export default function LearnerRecruitment() {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [registrationStatus, setRegistrationStatus] = useState();
  const [automationData, setAutomationData] = useState();
  const [recruitmentData, setRecruitmentData] = useState();
  const [loading, setLoading] = useState(true);

  //logout logic
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  //data fetch
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [automations, regData] = await Promise.all([
          AutomationService.getAllAutomations(),
          AutomationService.getRegistrationStatus({
            user_id: currentUser.user_id,
          }),
        ]);

        setAutomationData(automations.data);
        setRecruitmentData(regData.data.recruitment_data);
        setRegistrationStatus(regData.data.recruitment_data.is_registered);
        setLoading(false);
      } catch (error) {
        // Handle errors
        toast.error("Failed to fetch data. Please try again.");
      }
    };

    fetchData();
  }, [currentUser, registrationStatus]);

  const showToast = () => {
    toast.info("Session Data expires");
  };
  return (
    <>
      <div>
        {loading ? (
          <>
            <div className="w-full h-[100vh] flex justify-center items-center">
              {!currentUser ? (
                <div>
                  {() => {
                    logOut();
                    navigate("/sign-in");
                    showToast();
                  }}
                </div>
              ) : (
                <div className=" text-2xl font-semibold">Loading...</div>
              )}
            </div>
          </>
        ) : (
          <div className="poppins">
            <div className="mt-14 pt-20 pb-10">
              <LandingSection />
            </div>
            <div className=" max-w-[1130px] mt-10  mx-auto">
              {registrationStatus ? (
                <div>
                  <RegisteredView
                    currentUser={currentUser}
                    recruitmentData={recruitmentData}
                    automationId={automationData[0]?.automation_id}
                    setRecruitmentData={setRecruitmentData}
                    registrationStatus={registrationStatus}
                  />
                </div>
              ) : (
                <div>
                  <RegistrationForm
                    registrationStatus={registrationStatus}
                    setRegistrationStatus={setRegistrationStatus}
                    automationId={automationData[0]?.automation_id}
                    currentUser={currentUser}
                  />
                </div>
              )}
            </div>
            <Footer />
          </div>
        )}
      </div>
    </>
  );
}
