import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuthService from "../services/auth.service";

const user = JSON.parse(localStorage.getItem("user"));

export const register = createAsyncThunk(
  "auth/register",
  async ({fname, lname, username, email, password, phone_number, tnc_accepted, is_underage,parent_email, parent_phone_number, parent_name, roles, expert_type }, thunkAPI) => {
    try {
      const response = await AuthService.register(fname, lname, username, email, password, phone_number, tnc_accepted, is_underage, parent_email, parent_phone_number, parent_name, roles, expert_type );
      thunkAPI.dispatch(setMessage(response.data.message));
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async ({ username, password }, thunkAPI) => {
    try {
      const data = await AuthService.login(username, password);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  await AuthService.logout();
});

export const generateOtp = createAsyncThunk("auth/generateOtp", async ({email, fname, parent_email, is_underage, type}, thunkAPI) => {
  console.log("email at slice for OTP Generation:",email);
  const data = await AuthService.generateOtp(email, fname, parent_email, is_underage, type);
  console.log(data)
  return data;
});

export const verifyOtp = createAsyncThunk("auth/verifyOtp", async ({email,otp},thunkAPI) => {
  console.log("email at slice for OTP verification:",email);
  const data = await AuthService.verifyOtp(email,otp);
  return data;
});

export const updatePassword = createAsyncThunk("auth/updatePassword", async ({email, old_password, new_password},thunkAPI) => {
  const data = await AuthService.updatePassword(email, old_password, new_password);
  return data;
});

export const selectRole = createAsyncThunk("auth/selectRole", async ({role, user_id}, thunkAPI) =>{
  const data = await AuthService.selectRole({role, user_id});
  return data;
})

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // Adding setAuthState reducer
    setAuthState: (state, action) => {
      state.isLoggedIn = action.payload.isLoggedIn;
      state.user = action.payload.user;
    },
  },
  extraReducers: {
    [register.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
    },
    [register.rejected]: (state, action) => {
      state.isLoggedIn = false;
    },
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [selectRole.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      // state.user = action.payload;
      // set the user state such that the useSelector takes the new values of the state again
      state.user = {...state.user, user_group: action.payload.user_group, user_group_id: action.payload.user_group_id}
      console.log(state.user)
    },
    [selectRole.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },

  },
});

export const { setAuthState } = authSlice.actions;

const { reducer } = authSlice;
export default reducer;