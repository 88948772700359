import React from "react";
import ReactQuillParser from "../../../ReactQuillComponent/ReactQuillParser"

export default function PersonalityTest({ data }) {
  let score = data.reduce((accumulator, currentObject) => {
    return accumulator + currentObject.score_obtained;
  }, 0);
  let remark = "";
  
  if (score > 60) {
    remark =
      "<p>Others see you as someone they should <b>'handle with care'</b>.</p> <p>You're seen as <b>vain</b>, <b>self-centered</b>, and who is <b>extremely dominant</b>.</p> <p>Others may admire you, wishing they could be more like you, but don't always trust you, hesitating to become too deeply involved with you.</p>";
  } else if (score > 50) {
    remark =
      "<p>Others see you as an <b>exciting</b>, <b>highly volatile</b>, rather <b>impulsive</b> personality.</p><p>You are seen as a <b>natural leader</b>, who's quick to make decisions, though not always the right ones. They see you as a <b>bold</b> and <b>adventuresome</b>, someone whi will try anything once and someone who <b>takes chances</b>.</p> <p>They enjoy being in your company because of the excitement you radiate.</p>";
  } else if (score > 40) {
    remark =
      "<p>Others see you as <b>fresh</b>, <b>lively</b>, <b>charming</b>, <b>amusing</b>, <b>practical</b>, and always interesting.</p> <p>You are seen as someone who is constantly in the <b>center of attention</b>, but sufficiently well-balanced and someone who does not let attention go to their head.</p> <p>They also see you as <b>kind</b>, <b>considerate</b>, <b>understanding</b>, and someone who will always cheer them up and help them out.</p>";
  } else if (score > 30) {
    remark =
      "<p>Other see you as <b>sensible</b>, <b>cautious</b>, <b>careful</b>, and <b>practical</b>.</p> <p>They see you as <b>clever</b>, <b>gifted</b>, or <b>talented</b> but <b>modest</b></p>";
  } else {
    remark = "";
  }
  console.log(score, "score");
  return (
    <div>
      <div className="w-full p-2 pb-4">
        <div className=" border-dotted border-4 text-sm h-48 rounded-md">
          <ReactQuillParser content={remark}/>
        </div>
      </div>
    </div>
  );
}
