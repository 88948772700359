import axios from "axios";

const API_URL = `${process.env.REACT_APP_HOST}/notification/`;

const getAllNotifications = (id) => {
    return axios.get(API_URL + "findAll/" + id);
}

const createNotification = (title, description, user_id, is_read) => {

    return axios.post(API_URL + "create", 
        {
            title,
            description,
            user_id,
            is_read
        });
}

const updateNotification = (notification_id, is_read) => {
    return axios.post(API_URL + "update/",
        {
            notification_id,
            is_read
        });
}

const NotificationService = {
    getAllNotifications,
    createNotification,
    updateNotification
}

export default NotificationService;