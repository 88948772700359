import React from "react";
import { useState } from "react";
import AuthService from "../services/auth.service";
import { setMessage } from "../slices/message";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const OtpVerification = ({ email, redirectLocation = "/sign-in" ,setIsOtpVerified}) => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [otpVerified, setOtpVerified] = useState(false);

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const handleOtpVerification = (event) => {
    event.preventDefault();
    console.log("handleOtpVerification", email, otp);
    AuthService.verifyOtp({ email, otp }).then(
      () => {
        console.log("OTP verified successfully!");
        toast.success("OTP verified successfully!");
        setOtpVerified(true);
        let user = localStorage.getItem('user');
        user = user ? JSON.parse(user) : null;
        if (user) {
            user.otp_verified = true; // Modify the field
            localStorage.setItem('user', JSON.stringify(user)); // Save back to local storage
        }
        if(setIsOtpVerified){
          setIsOtpVerified(true)
        }
        navigate(`${redirectLocation}`);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setOtpVerified(false);
        toast.error(resMessage);
        setMessage(resMessage);
      }
    );
  };

  return (
    <div className="">
      <form onSubmit={handleOtpVerification}>
        <div className="form-group mb-6">
          <div htmlFor="username" className=" text-sm ">
            OTP <span className="text-red-500">*</span>
          </div>
          <input
            type="text"
            className="form-control"
            name="otp"
            value={otp}
            onChange={handleOtpChange}
            required
          />
        </div>
        <div className="form-group">
          <button
            className="btn btn-primary btn-block"
            onClick={handleOtpVerification}
          >
            Verify
          </button>
        </div>
        <div className="text-sm  text-slate-400 mt-4 ">
         Status: {otpVerified ? "Verified" : "Not Verified"}
        </div>
      </form>
    </div>
  );
};

export default OtpVerification;
