// import { configureStore } from '@reduxjs/toolkit'
// import authReducer from "./slices/auth";
// import messageReducer from "./slices/message";
// import notificationReducer from "./slices/notification";
// import programReducer from "./slices/program";
// import assessmentReducer from "./slices/assessment";

// const reducer = {
//   auth: authReducer,
//   message: messageReducer,
//   notification: notificationReducer,
//   program: programReducer,
//   assessment: assessmentReducer,
// }

// export const store = configureStore({
//   reducer: reducer,
//   devTools: true,
// });

import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./slices/auth";
import messageReducer from "./slices/message";
import notificationReducer from "./slices/notification";
import programReducer from "./slices/program";
import assessmentReducer from "./slices/assessment";

// Function to load state from localStorage
const loadState = () => {
  try {
    const serializedState = localStorage.getItem('assessmentState');
    if (serializedState === null) {
      return undefined; // No state in localStorage, return undefined to use the initial state
    }
    return { assessment: JSON.parse(serializedState) }; // Parse the serialized state
  } catch (err) {
    return undefined; // Errors should result in using initial state
  }
};

// Function to save state to localStorage
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('assessmentState', serializedState);
  } catch (err) {
    // Ignore write errors or handle them in a way you prefer
  }
};

const persistedState = loadState(); // Load any persisted state

const store = configureStore({
  reducer: {
    auth: authReducer,
    message: messageReducer,
    notification: notificationReducer,
    program: programReducer,
    assessment: assessmentReducer,
  },
  preloadedState: persistedState, // Use the loaded state as the initial state
  devTools: true,
});

// Subscribe to store changes and save the relevant part of the state to localStorage
store.subscribe(() => {
  const state = store.getState();
  const assessmentState = state.assessment;
  localStorage.setItem('assessmentState', JSON.stringify(assessmentState));
});

export { store };
