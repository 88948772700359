import React from "react";

const IndicatorComp = ({ data, topic, color }) => {
  return (
    <div className=" py-4">
      <div className={`bg-${color}-200 rounded-lg p-2`}>
        <div className={`border-2 border-${color}-500 p-2`}>{topic}</div>
        <div className="p-2 text-sm">
          {data?.map((e, index) => {
            return (
              <div className=" py-1 flex justify-between" key={index}>
                <div>{e?.item}</div>
                <div className="p-2 border-2 border-black">{e?.response}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const IndicatorListComp = ({ data, topic, color }) => {
  return (
    <div className=" py-4">
      <div className={`bg-${color}-200 rounded-lg p-2`}>
        <div className={`border-2 border-${color}-500 p-2`}>{topic}</div>
        <div className="p-2 text-sm flex flex-wrap">
          {data?.map((e, index) => {
            return (
              <>
                {e?.response ? (
                  <div
                    className=" p-2 flex justify-between bg-slate-100 rounded-full mr-2 my-2"
                    key={index}
                  >
                    <div>{e?.item}</div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default function Indicators({ data }) {
  console.log("Indica", data);
  const feelingAboutEnglishData = [
    {
      item: "I enjoy learning English",
      response: data?.feeling_about_english[0]?.response[0]?.value,
    },
    {
      item: "I enjoy learning new things",
      response: data?.feeling_about_english[1]?.response[0]?.value,
    },
    {
      item: "I have bigger burden in preparation due to additional subject",
      response: data?.feeling_about_english[2]?.response[0]?.value,
    },
    {
      item: "I am struggling to learn English because it is not my specialty",
      response: data?.feeling_about_english[3]?.response[0]?.value,
    },
    {
      item: "I feel English is not needed",
      response: data?.feeling_about_english[4]?.response[0]?.value,
    },
  ];
  const improvementData = [
    {
      item: "Pronunciation",
      response:
        data?.improvement[0]?.response[0]?.value === "Slightly Agree" ||
        data?.improvement[0]?.response[0]?.value === "Agree"
          ? true
          : false,
    },
    {
      item: "Speaking skills",
      response:
        data?.improvement[1]?.response[0]?.value === "Slightly Agree" ||
        data?.improvement[1]?.response[0]?.value === "Agree"
          ? true
          : false,
    },
    {
      item: "Listening skills",
      response:
        data?.improvement[2]?.response[0]?.value === "Slightly Agree" ||
        data?.improvement[2]?.response[0]?.value === "Agree"
          ? true
          : false,
    },
    {
      item: "Reading skills",
      response:
        data?.improvement[3]?.response[0]?.value === "Slightly Agree" ||
        data?.improvement[3]?.response[0]?.value === "Agree"
          ? true
          : false,
    },
    {
      item: "Writing skills",
      response:
        data?.improvement[4]?.response[0]?.value === "Slightly Agree" ||
        data?.improvement[4]?.response[0]?.value === "Agree"
          ? true
          : false,
    },
  ];
  const engPresentationData = [
    {
      item: "My feelings of resistance to English have decreased",
      response: data?.improvement[0]?.response[0]?.value,
    },
    {
      item: "I improved my pronunciation and listening ability",
      response: data?.improvement[1]?.response[0]?.value,
    },
    {
      item: "I became interested in foreign countries",
      response: data?.improvement[2]?.response[0]?.value,
    },
    {
      item: "I gained communication skills",
      response: data?.improvement[3]?.response[0]?.value,
    },
    {
      item: "I dislike English now",
      response: data?.improvement[4]?.response[0]?.value,
    },
  ];
  const learningDirectionData = [
    {
      item: "Useful games",
      response:
        data?.improvement[0]?.response[0]?.value === "Slightly Agree" ||
        data?.improvement[0]?.response[0]?.value === "Agree"
          ? true
          : false,
    },
    {
      item: "Easy English songs",
      response:
        data?.improvement[1]?.response[0]?.value === "Slightly Agree" ||
        data?.improvement[1]?.response[0]?.value === "Agree"
          ? true
          : false,
    },
    {
      item: "Useful lesson plans",
      response:
        data?.improvement[2]?.response[0]?.value === "Slightly Agree" ||
        data?.improvement[2]?.response[0]?.value === "Agree"
          ? true
          : false,
    },
    {
      item: "Culture of foreign countries",
      response:
        data?.improvement[3]?.response[0]?.value === "Slightly Agree" ||
        data?.improvement[3]?.response[0]?.value === "Agree"
          ? true
          : false,
    },
    {
      item: "English activities suitable for the development stages",
      response:
        data?.improvement[4]?.response[0]?.value === "Slightly Agree" ||
        data?.improvement[4]?.response[0]?.value === "Agree"
          ? true
          : false,
    },
    {
      item: "Websites for useful teaching materials",
      response:
        data?.improvement[5]?.response[0]?.value === "Slightly Agree" ||
        data?.improvement[5]?.response[0]?.value === "Agree"
          ? true
          : false,
    },
    {
      item: "How to make an one-hour presentation plan",
      response:
        data?.improvement[6]?.response[0]?.value === "Slightly Agree" ||
        data?.improvement[6]?.response[0]?.value === "Agree"
          ? true
          : false,
    },
    {
      item: "How to engage in conversation",
      response:
        data?.improvement[7]?.response[0]?.value === "Slightly Agree" ||
        data?.improvement[7]?.response[0]?.value === "Agree"
          ? true
          : false,
    },
    {
      item: "How to develop group presentations",
      response:
        data?.improvement[8]?.response[0]?.value === "Slightly Agree" ||
        data?.improvement[8]?.response[0]?.value === "Agree"
          ? true
          : false,
    },
    {
      item: "English language pedagogy",
      response:
        data?.improvement[9]?.response[0]?.value === "Slightly Agree" ||
        data?.improvement[9]?.response[0]?.value === "Agree"
          ? true
          : false,
    },
    {
      item: "Methodology of cross-cultural understanding",
      response:
        data?.improvement[10]?.response[0]?.value === "Slightly Agree" ||
        data?.improvement[10]?.response[0]?.value === "Agree"
          ? true
          : false,
    },
    {
      item: "English language pedagogy that suites me",
      response:
        data?.improvement[11]?.response[0]?.value === "Slightly Agree" ||
        data?.improvement[11]?.response[0]?.value === "Agree"
          ? true
          : false,
    },
    {
      item: "Useful teaching materials and tools (i.e., picture books and videos) and how to use them.",
      response:
        data?.improvement[12]?.response[0]?.value === "Slightly Agree" ||
        data?.improvement[12]?.response[0]?.value === "Agree"
          ? true
          : false,
    },
    {
      item: "How to choose materials",
      response:
        data?.improvement[13]?.response[0]?.value === "Slightly Agree" ||
        data?.improvement[13]?.response[0]?.value === "Agree"
          ? true
          : false,
    },
    {
      item: "How to develop communication",
      response:
        data?.improvement[14]?.response[0]?.value === "Slightly Agree" ||
        data?.improvement[14]?.response[0]?.value === "Agree"
          ? true
          : false,
    },
  ];
  return (
    <div>
      <div className="">
        <IndicatorComp
          data={feelingAboutEnglishData}
          topic={"What I feel about learning English"}
          color={"blue"}
        />
      </div>
      <div className="">
        <IndicatorComp
          data={engPresentationData}
          topic={"What I feel after experiencing the communication in English"}
          color={"green"}
        />
      </div>
      <div className="">
        <IndicatorListComp
          data={improvementData}
          topic={"Skill I need to improve to become better at english"}
          color={"green"}
        />
      </div>
      <div className="">
        <IndicatorListComp
          data={learningDirectionData}
          topic={"What I feel needed to be done to get better at English"}
          color={"red"}
        />
      </div>
    </div>
  );
}
