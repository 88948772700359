import axios from "axios";

const API_URL = `${process.env.REACT_APP_HOST}/automation/`;

const scheduleMail = async ({ email, subject, html, cc, scheduleTime }) => {
  const res = await axios.post(API_URL + "scheduleEmail");
};

const getAllAutomations = async () => {
  const res = await axios.get(API_URL + "getAll");

  return res;
};

const getAllComponents = async ({ automation_id }) => {
  const res = await axios.get(API_URL + automation_id + "/component/getAll");
  return res;
};

const getRegistrationStatus = async ({ user_id }) => {
  const res = await axios.get(API_URL + "registrationStatus/" + user_id);
  return res;
};

const getOneComponent = ({ automation_id, component_id }) => {
  return axios.get(
    API_URL + automation_id + "/component/getOne/" + component_id
  );
};

const addComponent = ({ automation_id, componentData }) => {
  return axios.post(API_URL + automation_id + "/component/add", componentData);
};

const editComponent = ({ automation_id, component_id, componentData }) => {
  return axios.post(
    API_URL + automation_id + "/component/" + component_id + "/edit",
    componentData
  );
};

const deleteComponent = ({ automation_id, component_id }) => {
  return axios.get(
    API_URL + automation_id + "/component/" + component_id + "/delete"
  );
};

const addInstitute = ({ automation_id, instituteData }) => {
  return axios.post(API_URL + automation_id + "/institute/add", instituteData);
};

const editInstitute = ({ automation_id, institute_id, instituteData }) => {
  return axios.post(
    API_URL + automation_id + "/institute/edit/" + institute_id,
    instituteData
  );
};

const getOneInstitute = ({ automation_id, institute_id }) => {
  return axios.get(
    API_URL + automation_id + "/institute/getOne/" + institute_id
  );
};

const getAllInstitute = ({ automation_id }) => {
  return axios.get(API_URL + automation_id + "/institute/getAll");
};

const deleteInstitute = ({ automation_id, institute_id }) => {
  return axios.get(
    API_URL + automation_id + "/institute/" + institute_id + "/delete"
  );
};

const registerUserForRecruitment = ({ automation_id, institute_id, data }) => {
  return axios.post(
    API_URL +
      automation_id +
      "/institute/" +
      institute_id +
      "/user/registration",
    data
  );
};

const getSingleUserDetail = ({ automation_id, institute_name, user_id }) => {
  return axios.get(
    API_URL +
      automation_id +
      "/institute/" +
      institute_name +
      "/user/" +
      user_id
  );
};
const sendMail = async ({ template_id, fname, email }) => {
  return await axios.post(API_URL + "sendMail", {
    template_id: template_id,
    fname: fname,
    email: email,
  });
};

const sendMailWithBody = async ({ subject,cc, mail_body, email }) => {
  return await axios.post(API_URL + "sendMailWithBody", {
    subject: subject,
    cc: cc,
    mail_body: mail_body,
    email: email,
  });
};

const sendEmailByUtil = async ({ template_id, email, var_fields }) => {
  return await axios.post(API_URL + "sendmail", {
    template_id: template_id,
    email: email,
    var_fields: var_fields,
  });
};

const userRoundOnboarding = async ({
  automation_id,
  institute_name,
  user_id,
  round,
  response_id,
  submission_time,
  time_taken,
}) => {
  return await axios.post(API_URL + "userRoundOnboarding", {
    automation_id: automation_id,
    institute_name: institute_name,
    user_id: user_id,
    round: round,
    response_id: response_id,
    submission_time: submission_time,
    time_taken: time_taken,
  });
};

const changeSelectionStatus = async ({
  automation_id,
  institute_name,
  user_id,
  round,
  status,
}) => {
  console.log("Called change status");
  return  axios.post(API_URL + "changeSelectionStatus", {
    automation_id: automation_id,
    institute_name: institute_name,
    user_id: user_id,
    round: round,
    status: status,
  });
};

const AutomationService = {
  // createSetting,
  // viewSettings,
  // updateSetting,
  // deleteSetting
  scheduleMail,
  getAllAutomations,
  getAllComponents,
  getOneComponent,
  addComponent,
  editComponent,
  deleteComponent,
  addInstitute,
  editInstitute,
  getOneInstitute,
  getAllInstitute,
  deleteInstitute,
  registerUserForRecruitment,
  getRegistrationStatus,
  sendMail,
  userRoundOnboarding,
  getSingleUserDetail,
  sendEmailByUtil,
  changeSelectionStatus,
  sendMailWithBody
};

export default AutomationService;
