/* eslint-disable*/
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import ProgramService from "../services/program.service";

export const getAllPrograms = createAsyncThunk(
    "program/getAllPrograms",
    async (data, thunkAPI) => {
        try {
            const response = await ProgramService.getAllPrograms(data.id);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue();
        }
    }
)

export const getProgram = createAsyncThunk(
    "program/getProgram",
    async (data, thunkAPI) => {
        try {
            const response = await ProgramService.getProgram(data.id);
            console.log(response.data);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue();
        }
    }
)

export const createProgram = createAsyncThunk(
    "program/createProgram",
    async (data, thunkAPI) => {
        try {
            const response = await ProgramService.createProgram(
                data.title,
                data.description,
                data.user_id,
                data.is_read
            );
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue();
        }
    }
)

export const getAllProgramsEnrolledByUser = createAsyncThunk(
    "program/getAllProgramsEnrolledByUser",
    async (data, thunkAPI) => {
        try {
            const response = await ProgramService.getAllProgramsEnrolledByUser(data.id);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue();
        }
    }
)

export const getAllProgramsEnrolledByUserAndNotCompleted = createAsyncThunk(
    "program/getAllProgramsEnrolledByUserAndNotCompleted",
    async (data, thunkAPI) => {
        try {
            const response = await ProgramService.getAllProgramsEnrolledByUserAndNotCompleted(data.id);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue();
        }
    }
)

export const getAllProgramsEnrolledByUserAndCompleted = createAsyncThunk(
    "program/getAllProgramsEnrolledByUserAndCompleted",
    async (data, thunkAPI) => {
        try {
            const response = await ProgramService.getAllProgramsEnrolledByUserAndCompleted(data.id);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue();
        }
    }
)

export const updateProgram = createAsyncThunk(
    "program/updateProgram",
    async (data, thunkAPI) => {
        try {
            const response = await ProgramService.updateProgram(
                data.program_id,
                data.is_read
            );
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue();
        }
    }
)

export const deleteProgram = createAsyncThunk(
    "program/deleteProgram",
    async (data, thunkAPI) => {
        try {
            const response = await ProgramService.deleteProgram(data.id);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue();
        }
    }
)

export const unitCompletion = createAsyncThunk(
    "program/unitCompletion",
    async (data, thunkAPI) => {
        try {
            const response = await ProgramService.unitCompletion(
                data.program_id,
                data.unit_id,
                data.is_completed
            );
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue();
        }
    }
)

export const getCompletionStatus = createAsyncThunk(
    "program/getCompletionStatus",
    async (data, thunkAPI) => {
        try {
            const response = await ProgramService.getCompletionStatus(data.user_id,data.program_id);
            // console.log(response.data)
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue();
        }
    }
)

const initialState = {
    programs: [],
    program: [],
    programsEnrolledByUser: [],
    programsEnrolledByUserAndNotCompleted: [],
    programsEnrolledByUserAndCompleted: [],
    programCompletionStatus: [],
    loading: false,
    error: null,
}

const programSlice = createSlice({
    name: "program",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllPrograms.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllPrograms.fulfilled, (state, action) => {
                console.log(action.payload)
                state.loading = false;
                state.programs = action.payload;
            })
            .addCase(getAllPrograms.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })
            .addCase(getProgram.pending, (state) => {
                state.loading = true;
            })
            .addCase(getProgram.fulfilled, (state, action) => {
                state.loading = false;
                state.program = action.payload;
            })
            .addCase(getProgram.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })
            .addCase(createProgram.pending, (state) => {
                state.loading = true;
            })
            .addCase(createProgram.fulfilled, (state, action) => {
                state.loading = false;
                state.program = action.payload;
            })
            .addCase(createProgram.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })
            .addCase(getAllProgramsEnrolledByUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllProgramsEnrolledByUser.fulfilled, (state, action) => {
                state.loading = false;
                state.programsEnrolledByUser = action.payload;
            })
            .addCase(getAllProgramsEnrolledByUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })
            .addCase(getAllProgramsEnrolledByUserAndNotCompleted.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllProgramsEnrolledByUserAndNotCompleted.fulfilled, (state, action) => {
                state.loading = false;
                state.programsEnrolledByUserAndNotCompleted = action.payload;
            })
            .addCase(getAllProgramsEnrolledByUserAndNotCompleted.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })
            .addCase(getAllProgramsEnrolledByUserAndCompleted.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllProgramsEnrolledByUserAndCompleted.fulfilled, (state, action) => {
                state.loading = false;
                state.programsEnrolledByUserAndCompleted = action.payload;
            })
            .addCase(getAllProgramsEnrolledByUserAndCompleted.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })
            .addCase(updateProgram.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateProgram.fulfilled, (state, action) => {
                state.loading = false;
                state.program = action.payload;
            })
            .addCase(updateProgram.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })
            .addCase(deleteProgram.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteProgram.fulfilled, (state, action) => {
                state.loading = false;
                state.program = action.payload;
            })
            .addCase(deleteProgram.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })
            .addCase(unitCompletion.pending, (state) => {
                state.loading = true;
            })
            .addCase(unitCompletion.fulfilled, (state, action) => {
                state.loading = false;
                state.programCompletionStatus = action.payload;
            })
            .addCase(unitCompletion.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })
            .addCase(getCompletionStatus.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCompletionStatus.fulfilled, (state, action) => {
                state.loading = false;
                state.programCompletionStatus = action.payload;
            })
            .addCase(getCompletionStatus.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })
    }
})

export default programSlice.reducer;