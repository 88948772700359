import axios from "axios";

const API_URL = `${process.env.REACT_APP_HOST}/test/`;

const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

const getUserBoard = () => {
  return axios.get(API_URL + "user");
  // return axios.get(API_URL + "user", { headers: authHeader() });
};

const getInstructorBoard = () => {
  return axios.get(API_URL + "mod");
};

const getAdminBoard = () => {
  return axios.get(API_URL + "admin");
};

const editProfile = (email, introduction, fname, lname, phone_number, education_level, education, skills, interests, languages, specialization, profile_image, hobbies) => {
  console.log(email, introduction, fname, lname, phone_number, education_level, education, skills, interests, languages, specialization, profile_image, hobbies)
  const response = axios.post(API_URL + "edit-profile", { email, introduction, fname, lname, phone_number, education_level, education, skills, interests, languages, specialization, profile_image, hobbies });
  // console.log("service data",response.data)
  return response;
}

const getAllUsers = () => {
  return axios.get(API_URL + "getAllUsers");
}

const getUser = (user_id) => {
  return axios.get(API_URL + "getUser/" + user_id);
}

const getProfile = (user_id) => {
  return axios.get(API_URL + "getProfile/" + user_id);
}

const deleteUser = (user_id) => {
  return axios.put(API_URL + "deleteUser", { user_id });
}

const addUser = (
  formData
) =>{
  for (var key of formData.entries()) {
    console.log(key[0] + ', ' + key[1]);
}
  return axios.post(API_URL + "addUser", 
  formData
  );
}

const editUser = (
  formData
) => {
  console.log({
    formData
  })
  return axios.post(API_URL + "editUser", 
    formData
  );
}

const getAllInstructors = () =>{ 
  return axios.get(API_URL + "getAllInstructors");
}

const getAllLearners = () => {
  return axios.get(API_URL + "getAllLearners");
}

const getLearner = (user_id) => {
  return axios.get(API_URL + "getLearner/" + user_id);
}

const getInstructor = (user_id) => {
  return axios.get(API_URL + "getInstructor/" + user_id);
}

const getAllUserRoles = () => {
  return axios.get(API_URL + "getAllUserRoles");
}

const findSetOfPrograms = (program_ids_list) => {
  return axios.post(API_URL + "findSetOfPrograms", { "program_ids_list": program_ids_list });
}

const filterInstructorSearch = async ({search_text}) => {
  const instructors = await axios.get(API_URL + "filterInstructorSearch",{
    params: {
      search_text: search_text
    }
  })
  return instructors;
}

const filterLearnerSearch = async ({search_text}) => {
  const learners = await axios.get(API_URL + "filterLearnerSearch",{
    params: {
      search_text: search_text
    }
  })
  return learners;
}

const getSetOfInstructors = async ({instructor_ids}) => {
  const instructors = await axios.get(API_URL + "getSetOfInstructors",{
    params: {
      instructor_ids: instructor_ids
    }
  })
  return instructors;
}

const getSetOfLearners = async ({learner_ids}) => {
  const learners = await axios.get(API_URL + "getSetOfLearners",{
    params: {
      learner_ids: learner_ids
    }
  })
  return learners;
}

const filterLearnerSearchWithExceptions = async ({search_text,exceptions_user_ids}) => {
  console.log(search_text)
  console.log(exceptions_user_ids)
  const learners = await axios.post(API_URL + "filterLearnerSearchWithExceptions",{
      search_text: search_text,
      learner_user_ids: exceptions_user_ids
  })
  return learners;
}

const editUserProfile = async ({formData}) =>{
  return await axios.post(API_URL + "editUserProfile",formData)
} 

const resetRecruitmentRegistration = async ({user_id}) => {
  return await axios.get(API_URL + "resetRecruitmentRegistration/" + user_id)
}

const UserService = {
  getPublicContent,
  getUserBoard,
  getInstructorBoard,
  getAdminBoard,
  editProfile,
  getAllUsers,
  getUser,
  getProfile,
  deleteUser,
  addUser,
  editUser,
  getAllInstructors,
  getAllLearners,
  getLearner,
  getInstructor,
  getAllUserRoles,
  findSetOfPrograms,  
  filterInstructorSearch,
  filterLearnerSearch,
  getSetOfInstructors,
  getSetOfLearners,
  filterLearnerSearchWithExceptions,
  editUserProfile
}

export default UserService;