import React from "react";

export default function LearningActivities({ data }) {
  return (
    <div className="p-4">
      <div className="bg-pink-200 rounded-lg p-2">
        <div className="border-2 border-pink-500 p-2">
          English learning methods
        </div>
        <div className="p-2 text-sm">
          {data[0]?.response?.map((e, index) => {
            return (
              <div className=" py-1" key={index}>
                {index+1}. {" " + e?.value} 
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
