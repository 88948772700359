/* eslint-disable */
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Link } from "react-router-dom";
// import socketIO from "socket.io-client";
// const socket = socketIO.connect(`${process.env.REACT_APP_HOST}`);

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./tailwind.css";
import { library } from "@fortawesome/fontawesome-svg-core";

import React, { lazy, Suspense } from "react";

// Import the specific icons
import {
  faArrowRightFromBracket,
  faPersonChalkboard,
  faUserGear,
  faComments,
  faFilePen,
  faLightbulb,
  faChartPie,
  faGears,
  faCalendarDays,
  faSitemap,
  faHandshake,
  faTriangleExclamation,
  faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";

// Add icons to the library
library.add(
  faArrowRightFromBracket,
  faPersonChalkboard,
  faUserGear,
  // faComments,
  faFilePen,
  faLightbulb,
  faChartPie,
  faGears,
  faCalendarDays,
  faSitemap,
  faHandshake
);

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BoardAdmin = lazy(() => import("./app/pages/Admin/BoardAdmin"));
const ProgramContent = lazy(() => import("./app/pages/User/ProgramContent"));
const CourseContent = lazy(() => import("./app/pages/User/CourseContent"));
const ModuleContent = lazy(() => import("./app/pages/User/ModuleContent"));
const Assessment = lazy(() => import("./app/pages/User/Assessment"));
const AssessmentResponse = lazy(() =>
  import("./app/pages/User/AssessmentResponse")
);

import Login from "./app/pages/Login";
import ForgotPassword from "./app/pages/ForgotPassword";
import ChangePassword from "./app/pages/ChangePassword";
import Register from "./app/pages/Register";
import SelectRole from "./app/pages/SelectRole";

import RecruitmentReport from "./app/pages/User/Report/RecruitmentReport";
import LearnerRecruitmentPage from "./app/pages/User/LearnerRecruitmentPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Logo from "./assets/images/new-logo.png";

const LazyLoader = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <div className="text-3xl font-bold text-gray-800">Loading....</div>
    </div>
  );
};

const SuspenseLoader = ({ children }) => {
  return <Suspense fallback={<LazyLoader />}>{children}</Suspense>;
};

const App = () => {
  const { user: currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    // socket.on("notification", (data) => {
    //   console.log("notification", data);
    // });
  }, [currentUser]);

  return (
    <Router>
      <div className="text-dark">
        <Routes>
          <Route
            path="/"
            element={
              currentUser ? (
                <SuspenseLoader>
                  <BoardAdmin />
                </SuspenseLoader>
              ) : (
                <Login />
              )
            }
          />
          {/* <Route path="/home" element={<Home />} />
          <Route path="/programs" element={<Programs />} /> */}
          <Route path="/sign-in" element={<Login />} />
          <Route path="/select-role" element={<SelectRole />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/sign-up" element={<Register />} />
          {/* <Route
            path="/program-overview/:programId"
            element={<ProgramOverview socket={socket} />}
          />
          <Route
            path="/program-content/:programId"
            element={
              <SuspenseLoader>
                <ProgramContent />
              </SuspenseLoader>
            }
          />
          <Route
            path="/program-content/:programId/course-content/:courseId"
            element={
              <SuspenseLoader>
                <CourseContent />
              </SuspenseLoader>
            }
          />
          <Route
            path="/program-content/:programId/course-content/:courseId/module-content/:moduleId"
            element={
              <SuspenseLoader>
                <ModuleContent />
              </SuspenseLoader>
            }
          />
          <Route
            path="/program-content/:programId/course-content/:courseId/module-content/:moduleId/unit-content/:unit-content"
            element={
              <SuspenseLoader>
                <ModuleContent />
              </SuspenseLoader>
            }
          /> */}
          <Route
            path="/assessment/:assessment_id"
            element={
              <SuspenseLoader>
                <Assessment />
              </SuspenseLoader>
            }
          />
          <Route
            path="/assessment-response/:assessment_response_id"
            element={
              <SuspenseLoader>
                <AssessmentResponse />
              </SuspenseLoader>
            }
          />
          {/* <Route path="/assessments" element={<AssessDash />} /> */}

          {/* Expert support */}
          {/* <Route path="/expert-support" element={<ExpertUser />} />
          <Route
            path="/expert-support/overview/:programId"
            element={<ExpertProgram />}
          />
          <Route
            path="/expert-support/content/:programId"
            element={<MenteeProgramView />}
          />

          <Route path="/profile/:user_id" element={<ProfileLearner />} />
          <Route
            path="/events/event-overview/:eventId"
            element={<EventOverview />}
          />
          <Route
            path="/events/registered/:eventId"
            element={<RegisteredEvent />}
          />
          <Route path="/events" element={<EventsLearnerView />} />
          <Route
            path="/hpdsExpert/profile/:id"
            element={<HpdsExpertProfile />}
          />
          <Route path="/becomeExpert" element={<BeAnExpert />} />
          <Route path="/becomeInstructor" element={<BeAnInstructor />} />
          <Route path="/becomeSpeaker" element={<BecomeASpeaker />} />
          <Route
            path="/institute/:org_id/chapters"
            element={<InstituteChapters />}
          />
          <Route path="/institute/:org_id/clubs" element={<InstituteClubs />} />
          <Route
            path="/institute/:org_id/collaborations"
            element={<InstituteCollaborations />}
          />
          <Route
            path="/registration/HpdsExpert"
            element={<HpdsExpertRegistration />}
          />
          <Route
            path="/registration/organization"
            element={<OrganizationRegistration />}
          />
          <Route path="/chapter/:chapter_id" element={<IndividualChapter />} />
          <Route path="/club/:chapter_id" element={<IndividualClub />} />
          <Route
            path="/collaboration/:chapter_id"
            element={<Collaboration />}
          />
          <Route path="/offerings/programs" element={<ProgramOffering />} />
          <Route path="/offerings/events" element={<EventOffering />} />
          <Route
            path="/offerings/expert-support"
            element={<ExpertOffering />}
          />
          <Route
            path="/offerings/assessments"
            element={<AssessmentOffering />}
          />
          <Route
            path="/offerings/chapters-clubs"
            element={<ChaptersAndClubOffering />}
          />
          <Route path="/sixp" element={<SixP />} />
          <Route path="/newHome" element={<NewHome />} />
          <Route path="/test/calendar" element={<CardTree />} />
          <Route path="/event/competitions" element={<AllCompetitions />} />
          <Route path="/article/:article_id" element={<Article />} /> */}
          <Route
            path="/recruitment"
            element={
              currentUser ? (
                <LearnerRecruitmentPage />
              ) : (
                <div>
                  <div className="w-full flex justify-start items-start">
                    <img
                      src={Logo}
                      alt=""
                      className="w-96 h-12 object-contain"
                    />
                  </div>

                  <div className="w-full h-[80vh] flex justify-center items-center flex-col">
                    <div className=" my-4 text-blue-500">
                      <FontAwesomeIcon
                        className="h-32 w-32"
                        icon={faTriangleExclamation}
                      />
                    </div>
                    <div className="  text-lg text-slate-500 bg-slate-100 rounded-full px-3 py-3 shadow-md cursor-pointer">
                      <Link to="/" className="no-underline">
                        <FontAwesomeIcon icon={faAngleLeft} /> Back to Login
                      </Link>
                    </div>
                  </div>
                </div>
              )
            }
          />
          <Route
            path="/report/recruitment/:user_id/:round"
            element={<RecruitmentReport />}
          />
          <Route
            path={`/assessment/:assessment_id/:response_for_id`}
            element={<Assessment />}
          />
        </Routes>
        <ToastContainer />
      </div>
    </Router>
  );
};

export default App;
