import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navigationbar from "../../../components/Navigationbar";
import Footer from "../../../components/Footer";
import MainReport from "../../../components/Report/Recruitment/MainReport";
import AutomationService from "../../../services/automation.service";
import NewNavigationBar from "../../../components/NewNavigationBar";

import LandingSection from "../../../components/Automations/HpdsRecruitmentLearner/LandingSection"


export default function RecruitmentReport() {
  const [screenSize, setScreenSize] = useState("sm"); // Default to the smallest breakpoint initially

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const width = entry.contentRect.width;
        if (width >= 1280) {
          setScreenSize("xl");
        } else if (width >= 1024) {
          setScreenSize("lg");
        } else if (width >= 768) {
          setScreenSize("md");
        } else {
          setScreenSize("sm");
        }
      }
    });

    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.disconnect();
    };
  }, []); // Empty dependency array ensures this effect runs once after the initial render

  let { user_id } = useParams();
  let { round } = useParams();
  console.log(round, user_id)
  const [automationData, setAutomationData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getAutomations = async () => {
      setLoading(true);
      const automations = await AutomationService.getAllAutomations();
      console.log(automations);
      setAutomationData(automations.data);
      setLoading(false);
    };
    getAutomations();
  }, []);

  return (
    <div>
      <NewNavigationBar />
      <div className=" pt-14">
        {/* <LandingSection screenSize={screenSize}/> */}
        {/* <div
          className="w-full flex justify-center items-center "
          style={{
            backgroundImage: `url(https://img.freepik.com/free-photo/paper-job-office-teamwork-business-analyst_1418-46.jpg?w=996&t=st=1705252404~exp=1705253004~hmac=0866d54737d6ea3d955443b6521d4df61a56f46770cae03e9d3ec3cc474fe53e)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "200px",
            boxShadow: "inset 0 0 10px 5px rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="w-full flex justify-center text-dark items-center text-5xl xl:text-5xl font-semibold ">
            Recruitment Reports
          </div>
        </div> */}
      </div>
      {loading ? (
        <>loading</>
      ) : (
        <div className="w-full border-t-2 bg-white poppins py-20">
          <MainReport
            round_id={round}
            automationId={automationData[0]?.automation_id}
            user_id={user_id}
            screenSize={screenSize}
          />

        </div>
      )}
      <Footer />
    </div>
  );
}
