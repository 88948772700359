import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  assessmentData: null,
  assessmentMetadata: {
    response_for_type: null,
    institute_name: null,
    assessment_id: null,
    is_completed: false 
  },   // New state for metadata
  currentResponse: null       // New state for current assessment response
};

export const assessmentSlice = createSlice({
  name: 'assessment',
  initialState,
  reducers: {
    setAssessmentData: (state, action) => {
      state.assessmentData = action.payload;
    },
    setAssessmentMetadata: (state, action) => {
      state.assessmentMetadata = action.payload;
    },
    setCurrentResponse: (state, action) => {
      state.currentResponse = action.payload;
    },
    setAssessmentCompleted: (state) => {
        state.assessmentMetadata.is_completed = true;
    },
    // To add more actions
  },
});

export const { setAssessmentData, setAssessmentMetadata, setCurrentResponse, setAssessmentCompleted } = assessmentSlice.actions;

export default assessmentSlice.reducer;
