import React, { useState } from "react";
import LearnerRecruitment from "../../components/Automations/HpdsRecruitmentLearner/LearnerRecruitment";
import NewNavigationBar from "../../components/NewNavigationBar";

export default function LearnerRecruitmentPage() {
  return (
    <div>
      <div className="border-b-2">
        <NewNavigationBar />
      </div>
      <LearnerRecruitment />
    </div>
  );
}
