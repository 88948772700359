import {Link} from 'react-router-dom'
import moment from 'moment';

const Notifications = ({notifications}) => {
    return(
        <>
            <div className="absolute right-0 mt-2 w-96 bg-white rounded-md overflow-hidden shadow-xl z-10">
                {/* <div className="px-4 py-3">
                <p className="text-sm leading-5">
                    Notifications
                </p>
                </div> */}
                <div className='flex flex-col justify-center'>
                    {notifications.map((notification)=>(
                        <div key={notification.notification_id} className='w-full border-b border-b-1 border-sky-800 hover:bg-sky-800 hover:text-white'>
                            <Link to={notification.link} className='no-underline hover:text-white'>
                                <div className='hover:text-white text-sky-800 p-2'>
                                    <div className=' text-normal p-1 grid grid-cols-12'>
                                        <div className='col-span-8'>
                                            {notification.title}    
                                        </div>
                                        <div className='col-span-4 text-end text-sm'>
                                            <span className = " border border-1 rounded-lg p-1 px-2">
                                            {moment(notification.cdate_time).fromNow()}
                                            </span>
                                        </div>
                                    </div>
                                    <div className=' text-sm p-1'>
                                        {notification.description}
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}

                </div>
                {/* <ul>
                {notifications.map((notification) => (
                    <li key={notification.notification_id}>
                    <Link to={notification.link} className="block px-4 py-3 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
                        <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                            </svg>
                        </div>
                        <div className="min-w-0 flex-1">
                            <p className="text-sm leading-5 font-medium text-gray-900 truncate">
                            {notification.title}
                            </p>
                            <p className="text-sm leading-5 text-gray-500 truncate">
                            {notification.description}
                            </p>
                        </div>
                        <div className="flex-shrink-0 whitespace-no-wrap text-sm leading-5 text-gray-500">
                            
                        </div>
                        </div>
                    </Link>
                    </li>
                ))}
                </ul> */}
            </div>
        </>
    )
}

export default Notifications