import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  RadialLinearScale,
} from "chart.js";
import { Bar, Pie, PolarArea } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  RadialLinearScale
);
export default function SkillAudit({ data }) {
  console.log("Skill", data);
  const [selectedSkill, setSelectedSkill] = useState(null);

  let getRateScore = (type, order) => {
    if (type === 1) {
      let len = data?.organized_learning?.length;
      let score =
        data?.organized_learning[order - 1]?.response[0]?.value?.toLowerCase() ===
        "i can do this well"
          ? 5
          : data?.organized_learning[order - 1]?.response[0]?.value?.toLowerCase() ===
            "ok, but i need more practice"
          ? 3
          : 0;
    
      return score;
    }
    if (type === 2) {
      let len = data?.information_seeking?.length;
      let score =
        data?.information_seeking[order - 1]?.response[0]?.value?.toLowerCase() ===
        "i can do this well"
          ? 5
          : data?.information_seeking[order - 1]?.response[0]?.value?.toLowerCase() ===
            "ok, but i need more practice"
          ? 3
          : 0;

      return score;
    }
    if (type === 3) {
      let len = data?.note_making?.length;
      let score =
        data?.note_making[order - 1]?.response[0]?.value?.toLowerCase() ===
        "i can do this well"
          ? 5
          : data?.note_making[order - 1]?.response[0]?.value?.toLowerCase() ===
            "ok, but i need more practice"
          ? 3
          : 0;

      return score;
    }
    if (type === 4) {
      let len = data?.writing?.length;
      let score =
        data?.writing[order - 1]?.response[0]?.value?.toLowerCase() ===
        "i can do this well"
          ? 5
          : data?.writing[order - 1]?.response[0]?.value?.toLowerCase() ===
            "ok, but i need more practice"
          ? 3
          : 0;

      return score;
    }
    if (type === 5) {
      let len = data?.spoken_communication?.length;
      let score =
        data?.spoken_communication[order - 1]?.response[0]?.value?.toLowerCase() ===
        "i can do this well"
          ? 5
          : data?.spoken_communication[order - 1]?.response[0]?.value?.toLowerCase() ===
            "ok, but i need more practice"
          ? 3
          : 0;
      return score;
    }
    if (type === 6) {
      let len = data?.information_technology?.length;
      let score =
        data?.information_technology[order - 1]?.response[0]?.value?.toLowerCase() ===
        "i can do this well"
          ? 5
          : data?.information_technology[order - 1]?.response[0]?.value?.toLowerCase() ===
            "ok, but i need more practice"
          ? 3
          : 0;

      return score;
    }
    if (type === 7) {
      let len = data?.working_with_numbers?.length;
      let score =
        data?.working_with_numbers[order - 1]?.response[0]?.value?.toLowerCase() ===
        "i can do this well"
          ? 5
          : data?.working_with_numbers[order - 1]?.response[0]?.value?.toLowerCase() ===
            "ok, but i need more practice"
          ? 3
          : 0;

      return score;
    }
    if (type === 8) {
      let len = data?.revision_exam?.length;
      let score =
      data?.revision_exam[order - 1]?.response[0]?.value?.toLowerCase() ===
      "i can do this well"
        ? 5
        : data?.revision_exam[order - 1]?.response[0]?.value?.toLowerCase() ===
          "ok, but i need more practice"
        ? 3
        : 0;

      return score;
    }
    if (type === 9) {
      let len = data?.stress_management?.length;
      let score =
        data?.stress_management[order - 1]?.response[0]?.value?.toLowerCase() ===
        "i can do this well"
          ? 5
          : data?.stress_management[order - 1]?.response[0]?.value?.toLowerCase() ===
            "ok, but i need more practice"
          ? 3
          : 0;

      return score;
    }
    if (type === 10) {
      let len = data?.plp?.length;
      let score =
        data?.plp[order - 1]?.response[0]?.value?.toLowerCase() ===
        "i can do this well"
          ? 5
          : data?.plp[order - 1]?.response[0]?.value?.toLowerCase() ===
            "ok, but i need more practice"
          ? 3
          : 0;

      return score;
    }
  };

  let getPriorityScore = (type, order) => {
    if (type === 1) {
      let len = data?.organized_learning?.length;
      let score =
        data?.organized_learning[order + len - 1]?.response[0]?.value ===
        "Very Important"
          ? 5
          : data?.organized_learning[order + len - 1]?.response[0]?.value ===
            "Quite Important"
          ? 3
          : 1;

      return score;
    }
    if (type === 2) {
      let len = data?.information_seeking?.length;
      let score =
        data?.information_seeking[order + len - 1]?.response[0]?.value ===
        "Very Important"
          ? 5
          : data?.information_seeking[order + len - 1]?.response[0]?.value ===
            "Quite Important"
          ? 3
          : 1;

      return score;
    }
    if (type === 3) {
      let len = data?.note_making?.length;
      let score =
        data?.note_making[order + len - 1]?.response[0]?.value ===
        "Very Important"
          ? 5
          : data?.note_making[order + len - 1]?.response[0]?.value ===
            "Quite Important"
          ? 3
          : 1;

      return score;
    }
    if (type === 4) {
      let len = data?.writing?.length;
      let score =
        data?.writing[order + len - 1]?.response[0]?.value === "Very Important"
          ? 5
          : data?.writing[order + len - 1]?.response[0]?.value ===
            "Quite Important"
          ? 3
          : 1;

      return score;
    }
    if (type === 5) {
      let len = data?.spoken_communication?.length;
      let score =
        data?.spoken_communication[order + len - 1]?.response[0]?.value ===
        "Very Important"
          ? 5
          : data?.spoken_communication[order + len - 1]?.response[0]?.value ===
            "Quite Important"
          ? 3
          : 1;

      return score;
    }
    if (type === 6) {
      let len = data?.information_technology?.length;
      let score =
        data?.information_technology[order + len - 1]?.response[0]?.value ===
        "Very Important"
          ? 5
          : data?.information_technology[order + len - 1]?.response[0]
              ?.value === "Quite Important"
          ? 3
          : 1;

      return score;
    }
    if (type === 7) {
      let len = data?.working_with_numbers?.length;
      let score =
        data?.working_with_numbers[order + len - 1]?.response[0]?.value ===
        "Very Important"
          ? 5
          : data?.working_with_numbers[order + len - 1]?.response[0]?.value ===
            "Quite Important"
          ? 3
          : 1;

      return score;
    }
    if (type === 8) {
      let len = data?.revision_exam?.length;
      let score =
        data?.revision_exam[order + len - 1]?.response[0]?.value ===
        "Very Important"
          ? 5
          : data?.revision_exam[order + len - 1]?.response[0]?.value ===
            "Quite Important"
          ? 3
          : 1;

      return score;
    }
    if (type === 9) {
      let len = data?.stress_management?.length;
      let score =
        data?.stress_management[order + len - 1]?.response[0]?.value ===
        "Very Important"
          ? 5
          : data?.stress_management[order + len - 1]?.response[0]?.value ===
            "Quite Important"
          ? 3
          : 1;

      return score;
    }
    if (type === 10) {
      let len = data?.plp?.length;
      let score =
        data?.plp[order + len - 1]?.response[0]?.value === "Very Important"
          ? 5
          : data?.plp[order + len - 1]?.response[0]?.value === "Quite Important"
          ? 3
          : 1;

      return score;
    }
  };

  let tempData = [
    {
      skill: "Organized Learning",
      sub: [
        {
          name: "I have strategies to help me plan and manage my time",
          rate: getRateScore(1, 1),
          priority: getPriorityScore(1, 1),
        },
        {
          name: "I am able to effectively prioritize my tasks and activities",
          rate: getRateScore(1, 2),
          priority: getPriorityScore(1, 2),
        },
        {
          name: "I am able to work to deadlines",
          rate: getRateScore(1, 3),
          priority: getPriorityScore(1, 3),
        },
        {
          name: "I am aware of what makes my learning more effective.",
          rate: getRateScore(1, 4),
          priority: getPriorityScore(1, 4),
        },
      ],
    },
    {
      skill: "Information Seeking Skills",
      sub: [
        {
          name: "I am able to find a specific book or journal in the library",
          rate: getRateScore(2, 1),
          priority: getPriorityScore(2, 1),
        },
        {
          name: "I am able to use a variety of different sources to find information",
          rate: getRateScore(2, 2),
          priority: getPriorityScore(2, 2),
        },
        {
          name: "I am able to access and search electronic resources ",
          rate: getRateScore(2, 3),
          priority: getPriorityScore(2, 3),
        },
        {
          name: "I am able to use search gateways on the internet to find information.",
          rate: getRateScore(2, 4),
          priority: getPriorityScore(2, 4),
        },
        {
          name: "I am able to evaluate the information I find.",
          rate: getRateScore(2, 5),
          priority: getPriorityScore(2, 5),
        },
      ],
    },
    {
      skill: "Reading and Note-making Skill",
      sub: [
        {
          name: "I can decide which parts of a book I need to read.",
          rate: getRateScore(3, 1),
          priority: getPriorityScore(3, 1),
        },
        {
          name: "I have a system for recording where I find information (i.e. book, author, date)",
          rate: getRateScore(3, 2),
          priority: getPriorityScore(3, 2),
        },
        {
          name: "I can select and use different reading strategies (e.g., skim, scan, in-depth)",
          rate: getRateScore(3, 3),
          priority: getPriorityScore(3, 3),
        },
        {
          name: "I can make effective notes when listening(e.g., during lectures)",
          rate: getRateScore(3, 4),
          priority: getPriorityScore(3, 4),
        },
        {
          name: "I have a system for recording and storing my notes",
          rate: getRateScore(3, 5),
          priority: getPriorityScore(3, 5),
        },
      ],
    },
    {
      skill: "Writing Skills",
      sub: [
        {
          name: "I can analyse assignment (essay, report etc) questions to determine what is expected",
          rate: getRateScore(4, 1),
          priority: getPriorityScore(4, 1),
        },
        {
          name: "I understand the difference between and essay and a report",
          rate: getRateScore(4, 2),
          priority: getPriorityScore(4, 2),
        },
        {
          name: "I can punctuate, use grammar and spelling correctly",
          rate: getRateScore(4, 3),
          priority: getPriorityScore(4, 3),
        },
        {
          name: "I am confident that I can express my ideas clearly in written form",
          rate: getRateScore(4, 4),
          priority: getPriorityScore(4, 4),
        },
        {
          name: "I am able to adapt my writing styles to suit the appropriate media/ audience",
          rate: getRateScore(4, 5),
          priority: getPriorityScore(4, 5),
        },
        {
          name: "I understand the need to reference my work to avoid plagiarism",
          rate: getRateScore(4, 6),
          priority: getPriorityScore(4, 6),
        },
      ],
    },
    {
      skill: "Spoken Communication Skill",
      sub: [
        {
          name: "I am able to express my views verbally",
          rate: getRateScore(5, 1),
          priority: getPriorityScore(5, 1),
        },
        {
          name: "I am confident speaker in front of a group of people",
          rate: getRateScore(5, 2),
          priority: getPriorityScore(5, 2),
        },
        {
          name: "I can prepare, plan and deliver a presentation",
          rate: getRateScore(5, 3),
          priority: getPriorityScore(5, 3),
        },
        {
          name: "I can use visual aids to support a presentation",
          rate: getRateScore(5, 4),
          priority: getPriorityScore(5, 4),
        },
        {
          name: "I work well as a member of a group or team",
          rate: getRateScore(5, 5),
          priority: getPriorityScore(5, 5),
        },
        {
          name: "I am able to listen and appreciate the views of others",
          rate: getRateScore(5, 6),
          priority: getPriorityScore(5, 6),
        },
      ],
    },
    {
      skill: "Information Technology",
      sub: [
        {
          name: "I am able to use a word processing software package to produce my assignments",
          rate: getRateScore(6, 1),
          priority: getPriorityScore(6, 1),
        },
        {
          name: "I can use a variety of different computer software",
          rate: getRateScore(6, 2),
          priority: getPriorityScore(6, 2),
        },
      ],
    },
    {
      skill: "Working with numbers",
      sub: [
        {
          name: "I am competent in working with simple calculations",
          rate: getRateScore(7, 1),
          priority: getPriorityScore(7, 1),
        },
        {
          name: "I can present numerical information accurately",
          rate: getRateScore(7, 2),
          priority: getPriorityScore(7, 2),
        },
        {
          name: "I can competently use a variety of numerical techniques",
          rate: getRateScore(7, 3),
          priority: getPriorityScore(7, 3),
        },
        {
          name: "I can interpret and present information in graphs and illustrations",
          rate: getRateScore(7, 4),
          priority: getPriorityScore(7, 4),
        },
      ],
    },
    {
      skill: "Revision and Exam Techniques",
      sub: [
        {
          name: "I am able to plan my revision time",
          rate: getRateScore(8, 1),
          priority: getPriorityScore(8, 1),
        },
        {
          name: "I am able to set my self goals",
          rate: getRateScore(8, 2),
          priority: getPriorityScore(8, 2),
        },
        {
          name: "I am able to use a variety of different revision techniques",
          rate: getRateScore(8, 3),
          priority: getPriorityScore(8, 3),
        },
        {
          name: "I can select and use techniques to help me retain and recall information",
          rate: getRateScore(8, 4),
          priority: getPriorityScore(8, 4),
        },
        {
          name: "I use strategies to help me in the exam room (e.g., planning time, coping with anxiety)",
          rate: getRateScore(8, 5),
          priority: getPriorityScore(8, 5),
        },
      ],
    },

    {
      skill: "Stress Management",
      sub: [
        {
          name: "I know what causes stress",
          rate: getRateScore(8, 1),
          priority: getPriorityScore(8, 1),
        },
        {
          name: "I am aware of my personal symptoms of stress",
          rate: getRateScore(8, 2),
          priority: getPriorityScore(8, 2),
        },
        {
          name: "I can use strategies to help me cope with my stress",
          rate: getRateScore(8, 3),
          priority: getPriorityScore(8, 3),
        },
      ],
    },
    {
      skill: "Personal Development Planning",
      sub: [
        {
          name: "I am able to identify my personal goals",
          rate: getRateScore(10, 1),
          priority: getPriorityScore(10, 1),
        },
        {
          name: "I am a good judge of what my strengths and areas for development are",
          rate: getRateScore(10, 2),
          priority: getPriorityScore(10, 2),
        },
        {
          name: "I am able to identify opportunities for learning outside my course, e.g., clubs, societies, employment",
          rate: getRateScore(10, 3),
          priority: getPriorityScore(10, 3),
        },
        {
          name: "I am able to plan for my personal development",
          rate: getRateScore(10, 4),
          priority: getPriorityScore(10, 4),
        },
      ],
    },
  ];

  console.log(tempData, "Tempaa");
  const skillScores = [];

  tempData.forEach((skillData) => {
    const skillName = skillData.skill;
    const subs = skillData.sub;
    let totalScore = 0;

    subs.forEach((sub) => {
      // Calculate the weighted score for each sub-object
      const subScore = sub.rate + sub.priority;
      totalScore += subScore;
    });

    // Calculate the average score for the skill
    const averageScore = totalScore / subs.length;
    const score = (averageScore ).toFixed(2);

    // Push the skill and its average score to the skillScores array
    skillScores.push({ skill: skillName, score: score });
  });
  console.log(skillScores, "skill");

  let polarChartLabel = skillScores.map((e) => e.skill);
  const polarchartData = {
    labels: polarChartLabel,
    datasets: [
      {
        label: "Skill Score out of 10",
        data: skillScores.map((e) => e.score),
        backgroundColor: [
          "rgba(255, 99, 132, 0.5)",
          "rgba(54, 162, 235, 0.5)",
          "rgba(255, 206, 86, 0.5)",
          "rgba(75, 192, 192, 0.5)",
          "rgba(153, 102, 255, 0.5)",
          "rgba(255, 159, 64, 0.5)",
          "rgba(155, 99, 132, 0.5)",
          "rgba(154, 162, 235, 0.5)",
          "rgba(155, 206, 86, 0.5)",
          "rgba(175, 192, 192, 0.5)",
          "rgba(53, 102, 255, 0.5)",
          "rgba(155, 159, 64, 0.5)",
        ],
        borderWidth: 1,
      },
    ],
  };
  console.log(polarChartLabel, "polarchar");
  const handleLabelClick = (event, chartElements) => {
    if (chartElements.length > 0) {
      const clickedLabel = polarchartData.labels[chartElements[0].index];
      setSelectedSkill(clickedLabel);
    }
  };
  return (
    <div>
      <div className="w-full py-3">
        <div className="w-full flex justify-center items-center">
          <div className="w-1/2 ">
            <PolarArea
              data={polarchartData}
              options={{
                onClick: handleLabelClick,
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-12">
          {tempData.map((e, index) => {
            if (selectedSkill === e.skill) {
              const data = {
                labels: e?.sub?.map((obj) => obj?.name),
                datasets: [
                  {
                    label: "Self Rating",
                    data: e?.sub?.map((obj) => obj?.rate),
                    backgroundColor: "rgba(255, 99, 132, 0.6)",
                    borderColor: "rgba(255, 99, 132, 1)",
                    borderWidth: 1,
                    barPercentage: 0.5,
                  },
                  {
                    label: "Priority of Development",
                    data: e?.sub?.map((obj) => obj?.priority),
                    backgroundColor: "rgba(54, 162, 235, 0.6)",
                    borderColor: "rgba(54, 162, 235, 1)",
                    borderWidth: 1,
                    barPercentage: 0.5,
                  },
                ],
              };

              const options = {
                indexAxis: "y",
                scales: {
                  x: {
                    stacked: true,
                    ticks: {
                      stepSize: 1, // Set the step size to control the scale intervals
                      max: 13, // Set the maximum scale out of 10
                    },
                  },
                  y: {
                    stacked: true,
                  },
                },
              };
              return (
                <div className="col-span-12 md:col-span-12">
                  <div className="w-full p-2 flex justify-center items-center flex-col py-3">
                    <div className=" p-2 rounded-t-md w-full bg-blue-500 text-white text-base font-bold">
                      {index + 1}. {e.skill}
                    </div>
                    <div className="w-full p-2 rounded-b-md shadow-md">
                      <Bar data={data} options={options} />
                    </div>
                  </div>
                </div>
              );
            } else {
              return <></>;
            }
          })}
        </div>
      </div>
    </div>
  );
}
