import React, { useState, useEffect } from 'react';


const FileUploader = ({ uploadService, onUploadComplete, acceptedTypes, fileParent, existingFile = null, maxNumberOfFiles = 1 }) => {
  const [file, setFile] = useState(existingFile ? [...existingFile] : null);
  const [error, setError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [previewFile, setPreviewFile] = useState(null);

  const types = Array.isArray(acceptedTypes) ? acceptedTypes : ["image/png", "image/jpeg", "application/pdf", "text/plain", "application/msword"];

  const generateRandomString = () => {
    return Math.random().toString(36).substring(2, 11);
  };

  const uniqueId = "file_upload_" + `${generateRandomString()}`;

  const handleFileSelection = (fileSource, e) => {
    e.preventDefault(); // Needed for drag-and-drop, harmless for file input
  
    if (existingFile) return;
  
    let selected = fileSource;
  
    // Add a field for upload status
    selected = Array.from(selected).map(f => ({ file: f, uploadStatus: "pending" }));
  
    let error = "";
  
    // check if all the uploaded files are of allowed types
    for (let i = 0; i < selected.length; i++) {
      console.log("Selected file:", selected[i]);  // Debugging line
      console.log("Selected file type:", selected[i].file.type);  // Debugging line
      if (selected[i] && !types.includes(selected[i].file.type)) {
        error = "Please select a valid file type";
        setError(error);
        break;
      }
    }
  
    if (error === "") {
      setError("");
    }
  
    if(maxNumberOfFiles > 1){
      if (selected.length > maxNumberOfFiles) {
        error = `Please select ${maxNumberOfFiles} files or less`;
        setError(error);
      }else{
        setError("");
        setFile((prev) => {
          if (prev) {
            return [...prev, ...selected];
          } else {
            return [...selected];
          }
        });
      }
    }else{
      setFile(selected[0]);
    }
  };
  
    
  const handleChange = (e) => {
    handleFileSelection(e.target.files, e);
  };
  
  const handleDrop = (e) => {
    handleFileSelection(e.dataTransfer.files, e);
  };
  

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileRemove = (index) => {
    // Remove file at the given index
    // setFile(prevFiles => prevFiles.filter((_, idx) => idx !== index));
    setFile(prevFiles => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (maxNumberOfFiles > 1 && Array.isArray(file)) {
      for (let i = 0; i < file.length; i++) {
        try {
          const config = {
            onUploadProgress: (progressEvent) => {
              const progress = Math.round((progressEvent.loaded * 100) / (progressEvent.total * file.length));
              setUploadProgress(prevProgress => prevProgress + progress);
            },
          };
  
          const formData = new FormData();
          formData.append("file", file[i].file);
  
          const uploadedFile = await uploadService(formData, fileParent, config);
          onUploadComplete(uploadedFile.data);
          // change file upload status
          setFile(prevFiles => {
            const newFiles = [...prevFiles];
            newFiles[i].uploadStatus = "uploaded";
            return newFiles;
          });
        } catch (error) {
          console.error("Error uploading file:", error);
          setError(`Error uploading file: ${file[i].name}`);
        }
      }
      setError(null);
      setUploadProgress(0);
    } else {
      try {
        const config = {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(progress);
          },
        };
  
        const formData = new FormData();
        console.log("file.file :",file.file)
        formData.append("file", file.file);
  
        const uploadedFile = await uploadService(formData, fileParent, config);
        onUploadComplete(uploadedFile.data);
        // change file upload status
        setFile(prevFile => ({ ...prevFile, uploadStatus: "uploaded" }));

        setError(null);
        setUploadProgress(0);
        
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };
  
  const renderFilePreview = (file) => {
    const fileType = file?.file ? file?.file?.type : file?.value?.mimetype
    const types = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    ]
    console.log("file.file :",file?.file)
    console.log("file.value :",file?.value)
    console.log(fileType)
    // check if the file.file is a file or an object
    let fileURL = "";
    if (file.file instanceof File) {
      console.log("file.file is a file")
      fileURL = URL.createObjectURL(file.file);
    }else{
      console.log("file.file is an object")
      console.log(file.value)
      fileURL = file.value.path;
      // if the URL is like "http://{process.env.REACT_APP_HOST}:8080/files\assessments\2e25d9b5a8a2994fb26af8d0d43013ec1692285864738.pdf" or "http://{process.env.REACT_APP_HOST}:8080/files\\assessments\\2e25d9b5a8a2994fb26af8d0d43013ec1692285864738.pdf" convert it to "http://{process.env.REACT_APP_HOST}:8080/files/assessments/2e25d9b5a8a2994fb26af8d0d43013ec1692285864738.pdf"
      fileURL = fileURL.replace(/\\/g, "/");
      console.log(fileURL)
      // Fetch the PDF file
      fetch(fileURL)
      .then(response => response.blob()) // Convert the response to a Blob
      .then(blob => {
        // Create an object URL for the Blob
        const objectURL = URL.createObjectURL(blob);
        fileURL = objectURL;
      })
    }

  
    if (fileType.startsWith("image/")) {
      return <img src={fileURL} alt="Preview" />;
    } else if (fileType === "application/pdf") {
      return (<>
        <div style={{ height: '100vh', width: '100%' }}>
          <iframe
            src={fileURL} // Assuming file is a URL path to the PDF
            type="application/pdf"
            width="100%"
            height="98%"
            style={{ border: 'none' }} // Adjust as needed
          />
        </div>
      </>
      );
    }

    return (<>
        <div>Preview not available for this file type</div>
        {/* a well designed div that says that to download the file, click below: followed by the button */}
        <div>
          To Download the file, click below:
          <div
          className={
            "p-2 bg-blue-500 hover:bg-blue-700 text-white rounded-full flex items-center justify-center w-10 h-10"
          }
          data-te-toggle="tooltip"
          title="Download the file"
          onClick={() => window.open(fileURL)}
        >
          {/* SVG for download */}
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
          </svg>
        </div>
        </div>
      </>);
  };

  const FilePreviewModal = ({ file, onClose }) => {
    return (
      <>
        <div
          className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
          style={{ overflowY: 'hidden' }}
        >
          <div className="absolute inset-0 bg-white rounded-none shadow-lg flex flex-col">
            {/* Cross button */}
            <button onClick={onClose} className="absolute top-2 right-2 focus:outline-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            {/*content*/}
            <div className="px-5 py-2 flex-auto justify-center">
              {file && renderFilePreview(file)}
            </div>
          </div>
        </div>

      </>
    );
  };

  const getFileURL = (file) => {
    if (file.file instanceof File) {
      return URL.createObjectURL(file.file);
    } else {
      let fileURL = file.value?.path?.replace(/\\/g, "/");
      return fileURL;
    }
  };

  const handlePreview = (file) => {
    console.log(file)
    const fileURL = getFileURL(file);
    window.open(fileURL, '_blank');
  };
  
  useEffect(() => {
    if (previewFile) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => { // Clean up function
      document.body.style.overflow = 'auto';
    };
  }, [previewFile]);

  

  return (
    <div className='container mx-auto'>
      {existingFile && (
        <>
        {
          existingFile.map((f, index) => (<>
            <div className="flex items-center justify-between" key={index}>
              <span className="text-gray-700">{f?.value?.file_original_name}</span>
              <div className="space-x-4 flex">
                {/*  a button to view the file */}
                <div
                  className="p-2 bg-blue-500 hover:bg-blue-700 text-white rounded-full cursor-pointer"
                  onClick={() => {
                    console.log(f)
                    handlePreview(f)
                    setPreviewFile(f)
                  }}
                  data-te-toggle="tooltip"
                  title="Preview the file"
                >
                  {/* SVG for preview */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    className="w-6 h-6 bi bi-eye"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8s-3-5.5-8-5.5C5 2.5 0 8 0 8s3 5.5 8 5.5C13 13.5 16 8 16 8zM2.05 8c1.495-2.655 4.616-4.5 7.95-4.5 1.19 0 2.318.266 3.32.724a.5.5 0 0 1-.185.961A7.49 7.49 0 0 0 8 4c-2.896 0-5.772 1.64-7.06 4.265a.5.5 0 1 1-.89-.43zM13.95 8a7.5 7.5 0 0 1-7.9 4.5 7.49 7.49 0 0 1-3.15-.714.5.5 0 1 1 .341-.94 6.99 6.99 0 0 0 2.814.654c2.896 0 5.772-1.64 7.06-4.265a.5.5 0 1 1 .891.431C13.778 8.94 13.89 8.476 13.95 8zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                  </svg>
                </div>
              </div>
            </div>
          </>))
        }
        </>
      )}
      {!existingFile && (<>
          <div onDrop={handleDrop} onDragOver={handleDragOver} className="fade-in-from-top fade-out-from-bottom w-full">
            {/* <label className="block text-sm font-medium text-gray-700">Upload File</label> */}
            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 rounded-md border-4 border-dashed cursor-pointer" onDragOver={handleDragOver} onDrop={handleDrop}>
              <div className="space-y-1 text-center">
                <div className="flex text-sm text-gray-600">
                  <label
                    htmlFor={uniqueId}
                    className="relative cursor-pointer rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none"
                  >
                    <div className="flex flex-col items-center justify-center pt-7">
                      <svg
                        className="w-10 h-10 text-sky-800 group-hover:text-sky-800"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                        />  
                      </svg>
                      <p className="lowercase text-sm text-gray-400 group-hover:text-sky-800 pt-1 tracking-wider">
                        {maxNumberOfFiles > 1 ? "Select Files" : "Select a file"} or drag and drop
                      </p>
                    </div>
                    {/* <span>Select File(s)</span> */}
                    <input
                      id={uniqueId}
                      name={uniqueId}
                      type="file"
                      multiple={maxNumberOfFiles > 1}
                      accept={types.join(', ')}
                      className="sr-only"
                      onChange={handleChange}
                      // disabled={!existingFile}
                    />
                  </label>
                </div>
                {error && <div className="text-xs text-red-500">{error}</div>}
              </div>
            </div>
          </div>
          {maxNumberOfFiles > 1 && file && file.length > 0 ? (
            <div>
              <p>Selected Files:</p>
              <ul>
                {Array.from(file).map((f, index) => (
                  <li key={index} className="flex items-center justify-between">
                    <span className="text-gray-700">{f?.file?.name}</span>
                    <div className="space-x-4 flex">
                      {/* add a button for upload if the status is pending or show a tick mark if the file has been uploaded */}
                      {f.uploadStatus === "pending" ? (<>
                        <div
                          onClick={handleSubmit}
                          className="p-2 bg-blue-500 hover:bg-blue-700 text-white rounded-full cursor-pointer"
                          data-te-toggle="tooltip"
                          title="Upload the file"
                        >
                          {/* SVG for upload */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                            />
                          </svg>
                        </div>
                      </>) : (<>
                        <div
                          className="p-2 bg-green-500 hover:bg-green-700 text-white rounded-full"
                          data-te-toggle="tooltip"
                          title="File uploaded"
                        >
                          {/* SVG for tick mark */}
                          <svg
                            className="w-6 h-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </div>
                      </>)}
                      <div
                        className="p-2 bg-blue-500 hover:bg-blue-700 text-white rounded-full cursor-pointer"
                        onClick={() => {
                          handlePreview(f)
                          setPreviewFile(f)
                        }}
                        data-te-toggle="tooltip"
                        title="Preview the file"
                      >
                        {/* SVG for preview */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          className="w-6 h-6 bi bi-eye"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8s-3-5.5-8-5.5C5 2.5 0 8 0 8s3 5.5 8 5.5C13 13.5 16 8 16 8zM2.05 8c1.495-2.655 4.616-4.5 7.95-4.5 1.19 0 2.318.266 3.32.724a.5.5 0 0 1-.185.961A7.49 7.49 0 0 0 8 4c-2.896 0-5.772 1.64-7.06 4.265a.5.5 0 1 1-.89-.43zM13.95 8a7.5 7.5 0 0 1-7.9 4.5 7.49 7.49 0 0 1-3.15-.714.5.5 0 1 1 .341-.94 6.99 6.99 0 0 0 2.814.654c2.896 0 5.772-1.64 7.06-4.265a.5.5 0 1 1 .891.431C13.778 8.94 13.89 8.476 13.95 8zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                        </svg>
                      </div>
                      {existingFile ? null : 
                        (f.uploadStatus === "pending" && <div
                          className="p-2 bg-red-500 hover:bg-red-700 text-white rounded-full cursor-pointer"
                          onClick={() => handleFileRemove(index)}
                          data-te-toggle="tooltip"
                          title="Remove the file"
                          >
                            {/* SVG for remove */}
                            <svg
                              className="w-6 h-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>                  
                        </div>)
                      }
                    </div>
                  </li>
                
                ))}
              </ul>
            </div>
          ) : null}
          {maxNumberOfFiles <= 1 && file ? (
            <div className="flex items-center justify-between">
              <span className="text-gray-700">Selected File: {file?.file?.name}</span>
              <div className="space-x-4 flex">
                {file.uploadStatus === "pending" ? (
                  <>
                    <div
                      onClick={handleSubmit}
                      className="p-2 bg-blue-500 hover:bg-blue-700 text-white rounded-full cursor-pointer"
                      data-te-toggle="tooltip"
                      title="Upload the file"
                    >
                      {/* SVG for upload */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                        />
                      </svg>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="p-2 bg-green-500 hover:bg-green-700 text-white rounded-full"
                      data-te-toggle="tooltip"
                      title="File uploaded"
                    >
                      {/* SVG for tick mark */}
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </div>
                  </>
                )}
                <div
                  className="p-2 bg-blue-500 hover:bg-blue-700 text-white rounded-full cursor-pointer"
                  onClick={() => {
                    setPreviewFile(file)
                    handlePreview(file)
                  }}
                  data-te-toggle="tooltip"
                  title="Preview the file"
                >
                  {/* SVG for preview */}
                  Preview
                </div>
                {!existingFile && 
                  <div
                    className="p-2 bg-red-500 hover:bg-red-700 text-white rounded-full cursor-pointer"
                    onClick={() => setFile(null)}
                    data-te-toggle="tooltip"
                    title="Deselect the file"
                  >
                    {/* SVG for remove */}
                    Deselect
                  </div>
                }
              </div>
            </div>
          
          ) : null}
        </>)
      }
      {/* {previewFile && <FilePreviewModal file={previewFile} onClose={() => setPreviewFile(null)} />} */}
    </div>
  );
};


export default FileUploader;
