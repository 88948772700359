/* eslint-disable */
import { useState,useEffect } from "react";
import { useParams } from "react-router-dom";

import AssessmentResponseService from "../../services/assessment-response.service";
import AssessmentService from "../../services/assessment.service";
import QuestionService from "../../services/question.service";
import FileService from "../../services/file.service"

import JsPDF from 'jspdf';

import Navigationbar from "../../components/Navigationbar";
import QuestionTypes from "../../components/QuestionTypes";

import { toast } from "react-toastify";

const AssessmentResponse = ({assessmentResponseId}) => {
  console.log("asess Response", assessmentResponseId)
    const {assessment_response_id} = assessmentResponseId?assessmentResponseId:useParams();
    // const {assessment_response_id} = 2;

    const [assessment,setAssessment] = useState({})
    // const [assessmentResponse,setAssessmentResponse] = useState({});
    const [pageQuestions,setPageQuestions] = useState([]);
    const [questionResponse,setQuestionResponse] = useState([]);
    const [loading,setLoading] = useState(true);

    useEffect(() => {
  async function fetchData(assessment_response_id) {
    try {
      const response = await AssessmentResponseService.getAssessmentResponse(assessment_response_id);
      const compResponse = response.data[0].question_response.map(async (questionResponse) => {
        if (questionResponse.question_type === "upload") {
          const fileResponse = questionResponse.response.map(async (file) => {
            try {
              if (!file.value) {
                // If file.value is undefined or null, skip the API call
                return {
                  ...file,
                  value: null // Or some other placeholder value indicating the file is unavailable
                };
              }
              const fileData = await FileService.getFile(file.value);
              return {
                ...file,
                value: fileData.data
              };
            } catch (fileError) {
              console.error('Error fetching file data:', fileError);
              // Handle individual file errors, e.g., log them or set a placeholder value
              return {
                ...file,
                error: 'Failed to load file data',
              };
            }
          });
          const newFileResponse = await Promise.all(fileResponse);
          return {
            ...questionResponse,
            response: newFileResponse
          };
        } else {
          return questionResponse;
        }
      });

      const newCompResponse = await Promise.all(compResponse);
      setQuestionResponse(newCompResponse);

      const id = response.data[0].assessment_id;
      const questionSet = await QuestionService.getSetOfQuestions(id);
      const assessmentData = await AssessmentService.getAssessment(id);

      setAssessment(assessmentData.data);

      const pageQuestions = assessmentData.data.question_page.map((page) => {
        return page.page_questions.map((question) => {
          return questionSet.data.find((element) => element.question_id === question.question_id);
        });
      });

      setPageQuestions(pageQuestions);
      setLoading(false);
    } catch (e) {
      console.error(e);
      toast.error("Pardon me, something went wrong!");
    }
  }

  fetchData(assessment_response_id?assessment_response_id:assessmentResponseId);

}, [assessment_response_id]);  // Assuming `assessment_response_id` is a dependency


    const generatePDF = () => {
        const report = new JsPDF('portrait','pt','a4');
        report.html(document.querySelector('#report')).then(() => {
            report.save('report.pdf');
        });
    }

    return (
        <>
            <div className="">
              {!assessmentResponseId && <Navigationbar />}
                <div className="bg-sky-100">
                {loading ? (<>
                        <div className="flex justify-center">
                            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
                        </div>
                    </>):(<>
                        <div className=' pb-4'>
                            {!assessmentResponseId && <div className='container-medium-948px p-0'>
                            <div className='p-2'>
                                <div className='' onClick={generatePDF}>
                                <span className='text-normal p-2 px-3 cursor-pointer bg-sky-800 rounded-3xl hover:bg-sky-600 shadow-md'>
                                    <span className='text-white '>
                                    Export PDF
                                    </span>
                                </span>
                                </div>
                            </div>
                            </div>}
                            <div className='container-medium-948px p-2 mt-5' id="report">
                              <div className=' bg-white rounded-lg shadow-md p-2'>
                                {
                                  pageQuestions.map((page,pageIndex)=>{
                                      if(page.length === 0){
                                        return null
                                      }
                                      return(
                                        <div className='border-b-4  rounded-lg border-sky-800 mt-2'>
                                          <div className='p-2'>
                                            <span className='heading-main'>
                                              <span className='text-sky-800'>
                                                  {assessment.question_page[pageIndex].page_name}
                                              </span>
                                            </span>
                                          </div>
                                          <div className="p-2">
                                            {
                                              page.map((question,questionIndex)=>{
                                                  return(
                                                      <div key={questionIndex}>
                                                          <QuestionTypes
                                                            question={question}
                                                            questionIndex={questionIndex}
                                                            pageNo={pageIndex + 1}
                                                            questionResponse={questionResponse}
                                                          />
                                                          {/* <QuestionTypeStructureComponent
                                                              question={question}
                                                              questionIndex={questionIndex}
                                                              questionResponse={questionResponse}
                                                              pageNo={
                                                                  pageIndex + 1
                                                              }
                                                          /> */}
                                                      </div>
                                                  )
                                              })
                                            }
                                          </div>
                                        </div>
                                      )
                                  })
                                }
                              </div>
                            </div>
                        </div>
                    </>)}
                </div>
            </div>
        </>
    );
}

export default AssessmentResponse;