import React from "react";
import ReactQuillParser from "../../../ReactQuillComponent/ReactQuillParser";

export default function Achievements({ data }) {
  return (
    <div className="  rounded-lg">
      <div className="w-full rounded-t-lg bg-amber-500 text-white text-md font-semibold p-2 px-3">
        Achievements
      </div>
      <div className="bg-white rounded-b-lg p-3 border-b-2 border-x-2 h-[50vh] overflow-y-auto custom-scrollbar">
        {(data?.question_type === "vmti" && (
          <div className="flex flex-wrap bg-white">
            {data?.response.map((e) => {
              return (
                <div className="rounded-full bg-slate-100 px-2 py-1 text-xs m-2">
                  {e?.value}
                </div>
              );
            })}
          </div>
        )) ||
          (data?.question_type === "sa" && (
            <div>
              <div className="text-sm ">{data.response[0]?.value}</div>
            </div>
          )) ||
          (data?.question_type === "la" && (
            <div>
              <div className="text-sm p-2 ">{data.response[0]?.value}</div>
            </div>
          )) ||
          (data?.question_type === "ebq" && (
            <div className="text-sm">
              <ReactQuillParser content={data.response[0]?.value?data.response[0]?.value:""}  />
            </div>
          ))}
      </div>
    </div>
  );
}
