import React, { useState, useEffect, useCallback } from "react";
import {useDispatch, useSelector } from "react-redux";
import { logout } from "../slices/auth";
import { Navigate, useNavigate } from "react-router-dom";

import UserService from "../services/user.service";
import AuthService from "../services/auth.service";

import { selectRole } from "../slices/auth";

const SelectRole = () => {
    const [loading, setLoading] = useState(true)
    const [rolesList, setRolesList] = useState([])
    const [selectedRole, setSelectedRole] = useState("")
    const { user: currentUser } = useSelector((state) => state.auth);

    const dispatch = useDispatch();
    let navigate = useNavigate();

    const logOut = useCallback(() => {
        dispatch(logout());
    },[dispatch])

    const handleRoleSelection = (selectedUserRole) => {
        setLoading(true)
        const role = selectedUserRole?selectedUserRole:selectedRole
        dispatch(selectRole({role:role, user_id: currentUser?.user_id}))
        .unwrap()
        .then((res) => {
            // setSuccessful(true);
            setLoading(true)
            if(role === "learner"){
                navigate("/")
            }else{
                navigate("/")
            }
        })
    }

    useEffect(()=>{
        const getPromises = async () => {
            if(currentUser?.roles.length === 1){
                handleRoleSelection(currentUser?.roles[0])
            }else{
                // const roles = await UserService.getAllUserRoles()
                // console.log(currentUser?.roles)
                // console.log(roles.data)
                // setRolesList(roles.data)
                // console.log(currentUser?.roles)
                setRolesList(currentUser?.roles.map((role)=>{
                    if((role === "instructor" || role === "expert" || role === "eventContributor")){
                        return("HPDS Scientist")
                    }else{
                        return(role)
                    }
                }))

                setRolesList((rolesList)=>{
                    // make the list unique
                    return [...new Set(rolesList)]
                })

                setLoading(false)
            }
        }
        getPromises();
    },[])

    return(<>
        {
            loading?(<>loading</>):(
                <>
                    <div className="flex h-screen items-center justify-center">
                        <div className={
                            "flex flex-col justify-center items-center w-full max-w-md px-4 py-8 bg-white shadow-lg rounded-lg"
                        }>
                
                            <label htmlFor="role">Select a role to continue</label><br/>
                            <select id="role" name="role" data-te-select-init
                            onChange={(e)=>{
                                setSelectedRole(e.target.value)
                                // console.log(e.target.value)
                            }}
                            className=" my-3 block w-full mt-1 text-sm dark:border-gray-600 dark:bg-neutral-800 dark:text-neutral-100 form-select focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
                            >
                                <option value="" disabled selected>Select a role</option>
                                {
                                    rolesList?.map((role)=>{
                                        return(<option value={role}>{
                                            (role === "HPDS Scientist") ? (role + 
                                                // if user has instructor, expert or eventContributor role, show it
                                                (currentUser?.roles.includes("instructor") ? " [Instructor] " : "") +
                                                (currentUser?.roles.includes("expert") ? " [Expert] " : "") +
                                                (currentUser?.roles.includes("eventContributor") ? " [Event Contributor] " : "")
                                            ) : role
                                        }</option>)
                                    })
                                }
                            </select>

                            {/* 2 buttons in a row in tailwind*/}
                            <div className="flex flex-row">
                                <button className="btn btn-primary mx-4" onClick={()=>{
                                    navigate("/sign-in")
                                    logOut()
                                }}>Logout</button>
                                <button 
                                onClick={()=>{
                                    handleRoleSelection()
                                }}
                                className="btn btn-primary">submit</button>
                            </div>

                        </div>
                    </div>
                </>
            )
        }
    </>)

}

export default SelectRole;