import axios from "axios";

const API_URL = `${process.env.REACT_APP_HOST}/assessment/`;

const getAllAssessments = () => {
    return axios.get(API_URL + "getAllAssessments");
}

const getAssessment = (id) => {
    return axios.get(API_URL + "getAssessment/" + id);
}

const createAssessment = (assessment) => {
    
    return axios.post(API_URL + "createAssessment", assessment);
}

const updateAssessment = (assessment) => {
    return axios.post(API_URL + "updateAssessment", assessment);
}

const deleteAssessment = (assessment_id) => {
    return axios.post(API_URL + "deleteAssessment", {assessment_id:assessment_id});
}

const uploadImage = (FormData) => {
    return axios.post(API_URL + "uploadImage", FormData);
}

const filterAssessmentSearch = ({searchText}) => {
    return axios.post(API_URL + "filterAssessmentSearch", {search:searchText});
}

const AssessmentService = {
    getAllAssessments,
    getAssessment,
    createAssessment,
    updateAssessment,
    deleteAssessment,
    uploadImage,
    filterAssessmentSearch
}

export default AssessmentService;