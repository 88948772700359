import React from "react";

export default function Basic({ data }) {


  let sumOfYears = data?.time_spent.reduce(
    (acc, curr) => acc + parseInt(curr?.response[0]?.value),
    0
  );

  return (
    <div className="w-full">
      <div className="p-2">
        <span className="font-semibold">
          Total Time spent in Learning English -{" "}
        </span>
        <span>{sumOfYears}</span> years
      </div>
      <div className="p-2">
        <span className="font-semibold">
          Average grade in English in lifetime -{" "}
        </span>
        <span>{data?.grade[0]?.response[0]?.value}</span> /10
      </div>
      <div className="p-2">
        <span className="font-semibold">
          Current English Ability-{" "}
        </span>
        <span>{data?.current_ability[0]?.response[0]?.value}</span>
      </div>
    </div>
  );
}
