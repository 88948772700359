import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import Logo from "../../assets/images/new-logo.png";
import OtpVerification from "./OtpVerification";

import { register } from "../slices/auth";
import { generateOtp } from "../slices/auth";
import { clearMessage } from "../slices/message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faCheck, faClose } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

const Tnc = ({ openTnc, setOpenTnc }) => {
  const data = {
    detail: "",
  };
  return (
    <div>
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="w-full flex items-center justify-between p-3 border-b border-solid border-blueGray-200 rounded-t">
                <div className="text-xl font-semibold">
                  Terms and Conditions
                </div>
                <div
                  className=" cursor-pointer p-1 px-2 text-sm hover:bg-slate-100 rounded-md"
                  onClick={() => setOpenTnc(false)}
                >
                  <FontAwesomeIcon icon={faClose} />
                </div>
              </div>
              <div className="h-full">
                <div className="p-4 w-full ">
                  <div className="w-full ">
                    <div className=" text-md">
                      <div className="text-md text-red-500">
                        This document contains proprietary and confidential
                        information.
                      </div>
                      <div>
                        <ul className="">
                          <p className="mt-2">
                            <span className="font-semibold">1. </span> All data
                            submitted to is provided in reliance upon its
                            consent not to use or disclose any information
                            contain herein except in the context of its business
                            dealings with Human Potential Development Science
                            Trust.
                          </p>
                          <p className="mt-2">
                            <span className="font-semibold">2. </span>The
                            Recipient agrees to instruct each employee that they
                            must not disclose any information concerning this
                            document to others except to the extent that such
                            matter are generally known to, and are available for
                            use by, the public.
                          </p>
                          <p className="mt-2">
                            <span className="font-semibold">3. </span>The
                            recipient also agrees not to duplicate or distribute
                            or permit others to duplicate or distribute any
                            material contained herein without Human Potential
                            Development Science Trust express written consent.
                          </p>
                          <p className="mt-2">
                            <span className="font-semibold">4. </span> Human
                            Potential Development Science PVT LTD AND LLC
                            retains all title, ownership and intellectual
                            property rights to the material and trademarks
                            contained herein, including all supporting
                            documentation, files, marketing material, and
                            multimedia.
                          </p>
                        </ul>
                      </div>
                      <div className=" mt-4 text-md font-semibold">
                        BY ACCEPTANCE OF THIS DOCUMENT, THE RECIPIENT AGREES TO
                        BE BOUND BY THE AFOREMENTIONED STATEMENT.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" w-full flex items-center justify-end p-2 border-t border-solid border-blueGray-200 rounded-b">
                <div
                  className=" py-2 border-2  text-sm text-white bg-blue-500 rounded-md cursor-pointer px-3 hover:bg-blue-700  hover:border-blue-500  mr-2"
                  onClick={() => setOpenTnc(false)}
                >
                  <FontAwesomeIcon icon={faClose} />
                  {" Close"}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    </div>
  );
};

const Register = () => {
  const [successful, setSuccessful] = useState(false);
  const [is_underage, set_is_underage] = useState(false);
  const [phone_no, set_phone_no] = useState("");
  const [parent_phone_no, set_parent_phone_no] = useState("");
  const [user_email, setuser_email] = useState("");
  const [otpEntryWindow, setOtpEntryWindow] = useState(false);
  const [tnc_accepted, set_tnc_accepted] = useState(false);
  const [user_parent_email, set_user_parent_email] = useState("");
  const [first_name, set_first_name] = useState("");
  const [country, set_country] = useState("");
  const [roles, setRoles] = useState(["learner"]);

  const [openTnc, setOpenTnc] = useState(false);

  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const initialValues = {
    fname: "",
    lname: "",
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    parent_name: "",
    parent_email: "",
    parent_phone_number: "",
    role: "learner",
    expert_type: "mentor",
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .matches(
        /^[a-zA-Z0-9_]+$/,
        "Username can only contain letters, numbers, and underscores"
      )
      .test(
        "len",
        "The username must be between 3 and 20 characters.",
        (val) =>
          val && val.toString().length >= 3 && val.toString().length <= 20
      )
      .required("This field is required!"),
    email: Yup.string()
      .email("This is not a valid email.")
      .required("This field is required!"),
    password: Yup.string()
      .test(
        "len",
        "The password must be between 6 and 40 characters.",
        (val) =>
          val && val.toString().length >= 6 && val.toString().length <= 40
      )
      .required("This field is required!"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("You need to confirm your password"),
  });

  const handleRegister = (formValue) => {
    const { lname, username, email, password, parent_name } = formValue;
    const phone_number = phone_no;
    const parent_phone_number = parent_phone_no;
    const fname = first_name;
    const parent_email = user_parent_email;
    const expert_type = formValue.expert_type;

    setuser_email(email);

    setSuccessful(false);

    dispatch(
      register(
        !is_underage
          ? {
              fname,
              lname,
              username,
              email,
              password,
              phone_number,
              tnc_accepted,
              is_underage,
              country,
              roles,
              expert_type,
            }
          : {
              fname,
              lname,
              username,
              email,
              password,
              phone_number,
              parent_email,
              parent_phone_number,
              parent_name,
              is_underage,
              country,
              roles,
              expert_type,
            }
      )
    )
      .unwrap()
      .then(() => {
        setSuccessful(true);
      })
      .catch((error) => {
        setSuccessful(false);
      });
  };

  const sendOtp = (email, fname, parent_email, is_underage) => {
    // console.log("email", email);
    // console.log("fname", fname);
    // console.log("parent_email", parent_email);
    // console.log("is_underage", is_underage);
    dispatch(generateOtp({ email, fname, parent_email, is_underage }))
      .unwrap()
      .then(() => {
        setSuccessful(true);
      })
      .catch(() => {
        setSuccessful(false);
      });
  };

  useEffect(() => {
    if (
      !successful &&
      message &&
      message !== "User was registered successfully!"
    ) {
      toast.error(message);
    }
  }, [message]);

  return (
    <div className="container-fluid my-5 col-lg-6 poppins">
      {!successful ? (
        <>
          <div className="card card-container ">
            <div className="py-3">
              <a href="/">
                <img
                  loading="lazy"
                  src={Logo}
                  alt="logo"
                  className="w-96 h-12 object-contain"
                />
              </a>
            </div>
            <div className="mb-2 ms-3">
              <h3 className="mb-1 fw-bold">Sign Up</h3>
              <span>Enter the following details to Sign Up</span>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleRegister}
            >
              {({ errors, touched }) => (
                <Form>
                  {!successful && (
                    <div className="row m-2">
                      <div className="form-group mt-2 col-sm-6">
                        <label htmlFor="fname">First Name:</label>
                        <Field
                          name="fname"
                          type="text"
                          value={first_name}
                          onChange={(e) => {
                            set_first_name(e.target.value);
                          }}
                          className={
                            errors.fname && touched.fname
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                        />
                        <ErrorMessage
                          name="fname"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="form-group mt-2 col-sm-6">
                        <label htmlFor="lname">Last Name:</label>
                        <Field
                          name="lname"
                          type="text"
                          className={
                            errors.lname && touched.lname
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                        />
                        <ErrorMessage
                          name="lname"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="form-group mt-2 col-sm-6">
                        <label htmlFor="username">Username</label>
                        <Field
                          name="username"
                          type="text"
                          className={
                            "form-control" +
                            (errors.username && touched.username
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="username"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="form-group mt-2 col-sm-6">
                        <label htmlFor="email">Email</label>
                        <Field
                          name="email"
                          type="email"
                          className={
                            "form-control" +
                            (errors.email && touched.email ? " is-invalid" : "")
                          }
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="form-group mt-2 col-sm-6">
                        <label htmlFor="password">Password</label>
                        <Field
                          name="password"
                          type="password"
                          className={
                            "form-control" +
                            (errors.password && touched.password
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="form-group mt-2 col-sm-6">
                        <label htmlFor="confirmPassword">
                          Confirm Password
                        </label>
                        <Field
                          name="confirmPassword"
                          type="password"
                          className={
                            "form-control" +
                            (errors.confirmPassword && touched.confirmPassword
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="confirmPassword"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="form-group mt-2 col-sm-6">
                        <label htmlFor="phone_number">Phone Number</label>
                        <PhoneInput
                          country={country}
                          value={phone_no}
                          onChange={(phone_no, country) => {
                            // console.log("phone_no",phone_no);
                            // console.log("country",country.name);
                            set_phone_no(phone_no);
                            set_country(country.name);
                          }}
                          inputClass="form-control d-flex container-fluid w-100"
                        />
                        <ErrorMessage
                          name="phone_number"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>

                      {/* <div className="form-group mt-2 col-sm-6">
                        <label htmlFor="role">Sign Up as :</label>
                        <Field
                          name="role"
                          as="select"
                          className={
                            "form-control" +
                            (errors.role && touched.role
                              ? " is-invalid"
                              : "")
                          }
                          value={roles[0]}
                          onChange={
                            (e)=>{
                              setRoles([e.target.value])
                            }
                          }
                        >
                          <option value="learner">Learner</option>
                          <option value="instructor">Instructor</option>
                          <option value="expert">Expert</option>
                        </Field>
                        <ErrorMessage
                          name="role"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div> */}

                      {/* {
                        (roles.includes("expert"))?(<>
                          <div className="form-group mt-2 col-sm-6">
                            <label htmlFor="expert_type">Expert Type:</label>
                            <Field
                              name="expert_type"
                              as="select"
                              className={
                                "form-control" +
                                (errors.role && touched.role
                                  ? " is-invalid"
                                  : "")
                              }
                              
                            >
                              <option value="mentor">Mentor</option>
                              <option value="counselor">Counselor</option>
                              <option value="coach">Coach</option>
                              <option value="guide">Guide</option>
                            </Field>
                            <ErrorMessage
                              name="expert_type"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </>):(<></>)
                      } */}

                      {/* Accept Terms and conditions checkbox */}
                      {is_underage ? (
                        <></>
                      ) : (
                        <>
                          <div className="my-3 col-lg-12 col-md-12">
                            <div className="d-md-flex justify-content-between align-items-center">
                              <div className="mb-3 mb-md-0">
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    id="agree-to-tnc"
                                    className="form-check-input"
                                    onChange={() => {
                                      set_tnc_accepted(!tnc_accepted);
                                    }}
                                  />
                                  <label
                                    title=""
                                    className="form-check-label flex flex-wrap "
                                  >
                                    <div className="w-fit">
                                      By checking this, you are hereby accepting
                                      all the
                                    </div>
                                    <div
                                      className="w-fit ml-1 text-blue-600 underline cursor-pointer"
                                      onClick={() => {
                                        setOpenTnc(true);
                                      }}
                                    >
                                      Terms and Conditions
                                    </div>
                                    <div className="w-fit">as mentioned.</div>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {openTnc ? (
                        <Tnc openTnc={openTnc} setOpenTnc={setOpenTnc} />
                      ) : (
                        <></>
                      )}
                      <div className="my-3 col-lg-12 col-md-12">
                        <div className="d-md-flex justify-content-between align-items-center">
                          <div className="mb-3 mb-md-0">
                            <div className="form-check form-switch">
                              <input
                                type="checkbox"
                                id="underage"
                                className="form-check-input"
                                onChange={() => {
                                  set_is_underage(!is_underage);
                                }}
                              />
                              <label title="" className="form-check-label">
                                Under the age of 18
                              </label>
                            </div>
                          </div>
                          <a href="sign-in">Have an Account? Sign In</a>
                        </div>
                      </div>

                      {is_underage ? (
                        <>
                          <div className="form-group my-2 col-sm-6">
                            <label htmlFor="parent_name">
                              Parent/Guardian Name
                            </label>
                            <Field
                              name="parent_name"
                              type="text"
                              className={
                                "form-control" +
                                (errors.parent_name && touched.parent_name
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="parent_name"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="form-group my-2 col-sm-6">
                            <label htmlFor="parent_email">
                              Parent/Guardian E-mail ID
                            </label>
                            <Field
                              name="parent_email"
                              type="email"
                              value={user_parent_email}
                              onChange={(e) => {
                                set_user_parent_email(e.target.value);
                              }}
                              className={
                                "form-control" +
                                (errors.parent_email && touched.parent_email
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="parent_email"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="form-group my-2 col-sm-6">
                            <label htmlFor="parent_phone_number">
                              Parent/Guardian Phone Number
                            </label>
                            <PhoneInput
                              country={"us"}
                              value={parent_phone_no}
                              onChange={set_parent_phone_no}
                              inputClass="form-control d-flex container-fluid w-100"
                            />
                            <ErrorMessage
                              name="parent_phone_number"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      <div className="form-group mt-2">
                        <button
                          type="submit"
                          disabled={!tnc_accepted || is_underage}
                          className={
                            !tnc_accepted && !is_underage
                              ? " text-white bg-blue-600 hover:bg-blue-800 rounded-md p-2 text-sm cursor-not-allowed"
                              : "text-white bg-blue-600 hover:bg-blue-800 rounded-md p-2 text-sm cursor-pointer"
                          }
                        >
                          Sign Up
                        </button>
                      </div>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </>
      ) : (
        <>
          {message && (
            <>
              <div>
                <div className="form-group">
                  <div
                    className={
                      successful ? "alert alert-success" : "alert alert-danger"
                    }
                    role="alert"
                  >
                    {message}
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="card card-container">
            <a href="/">
              <img
                loading="lazy"
                src={Logo}
                alt="logo"
                className="w-96 h-12 object-contain"
              />
            </a>

            <div className="mt-4 mx-10 text-lg md:text-xl lg:text-2xl font-semibold text-dark">
              Email Verification
            </div>
            <div className="text-sm mx-10  text-slate-400 mt-2">
              Press the verify button to send an OTP
            </div>
            <div className="text-sm mt-4 mx-10  text-blue-600  text-md">
              Email - {user_parent_email ? user_parent_email : user_email}
            </div>

            <div className="mt-6  mx-10 ">
              {!otpEntryWindow ? (
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    sendOtp(
                      user_email,
                      first_name,
                      user_parent_email,
                      is_underage
                    );
                    setOtpEntryWindow(true);
                  }}
                >
                  Verify
                </button>
              ) : (
                <></>
              )}
            </div>
            {otpEntryWindow && (
              <div className="mx-10">
                <OtpVerification email={user_email} />
              </div>
            )}
            <div className="mb-10"></div>
          </div>
        </>
      )}
    </div>
  );
};

export default Register;
