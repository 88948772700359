import React from "react";
import QuillParser from "../../../ReactQuillComponent/ReactQuillParser";

const GoalCard = ({ desc, topic, color }) => {
  return (
    <div
      className={`w-full p-2  shadow-inner border-2 border-${color}-500 rounded-lg bg-white`}
    >
      <div
        className={`p-2 text-lg font-semibold border-b-4 border-${color}-500 flex text-start`}
      >
        {topic}
      </div>
      <div className="p-2 ">
        <QuillParser content={desc} />
      </div>
    </div>
  );
};

export default function GoalSetting({ data }) {
  console.log("goal", data);
  return (
    <div className=" px-2 ">
      <div className="w-full grid grid-cols-12 ">
        <div className="col-span-12 md:col-span-6">
          <div className="p-2">
            <GoalCard
              desc={data?.smart?.specific[0]?.response[0]?.value}
              topic={"Specific"}
              color={"green"}
            />
          </div>
          <div className="p-2">
            <GoalCard
              desc={data?.smart?.measurable[0]?.response[0]?.value}
              topic={"Measurable"}
              color={"purple"}
            />
          </div>
          <div className="p-2">
            <GoalCard
              desc={data?.smart?.achievable[0]?.response[0]?.value}
              topic={"Achievable"}
              color={"red"}
            />
          </div>
          <div className="p-2">
            <GoalCard
              desc={data?.smart?.relavent[0]?.response[0]?.value}
              topic={"Relavent"}
              color={"amber"}
            />
          </div>
          <div className="p-2">
            <GoalCard
              desc={data?.smart?.time[0]?.response[0]?.value}
              topic={"Time bound"}
              color={"blue"}
            />
          </div>
        </div>
        <div className="col-span-12 md:col-span-6">
          <div className="p-2">
            <GoalCard
              desc={data?.goal_setting[0]?.response[0]?.value}
              topic={"What do you hope to accomplish by completing your goal?"}
              color={"blue"}
            />
          </div>
          <div className="p-2">
            <GoalCard
              desc={data?.goal_setting[1]?.response[0]?.value}
              topic={"Why is it important to you to accomplish this?"}
              color={"green"}
            />
          </div>
          <div className="p-2">
            <GoalCard
              desc={data?.goal_setting[2]?.response[0]?.value}
              topic={"What are the first three steps you need to complete in order to achieve that goal?"}
              color={"blue"}
            />
          </div>
          <div className="p-2">
            <GoalCard
              desc={data?.goal_setting[3]?.response[0]?.value}
              topic={"Of these three, which is the most important to you and why?"}
              color={"blue"}
            />
          </div>
          <div className="p-2">
            <GoalCard
              desc={data?.goal_setting[4]?.response[0]?.value}
              topic={"What aspects of reading or writing will you need to build or strengthen in order to meet your goal?"}
              color={"blue"}
            />
          </div>
          <div className="p-2">
            <GoalCard
              desc={data?.goal_setting[5]?.response[0]?.value}
              topic={"What knowledge, skills and strategies do you already possess that will help you reach your goal?"}
              color={"blue"}
            />
          </div>
          <div className="p-2">
            <GoalCard
              desc={data?.goal_setting[6]?.response[0]?.value}
              topic={"List any concerns or potential obstacles you have that may stand in the way of achieving your goal."}
              color={"blue"}
            />
          </div>
        </div>
      </div>
      <div className="p-2 py-6">
        <GoalCard
          desc={data?.action_plan[0]?.response[0]?.value}
          topic={"Action Plan"}
          color={"lime"}
        />
      </div>
    </div>
  );
}
