import React from "react";
import Basic from "./EnglishComm/Basic";
import LearningActivities from "./EnglishComm/LearningActivities";
import FutureActivities from "./EnglishComm/FutureActivities";
import Indicators from "./EnglishComm/Indicators";

export default function EnglishComm({ data }) {
  console.log(data, "Eng data");
  return (
    <div>
      <div className="w-full grid grid-cols-12">
        <div className=" col-span-12 md:col-span-8">
          <div className="mt-6 mr-4">
            <div className="p-2 bg-amber-200  rounded-lg">
              <Basic data={data.basic} />
            </div>
            <div className="">
              <Indicators data={data} />
            </div>
          </div>
        </div>
        <div className=" col-span-12 md:col-span-4">
          <div>
            <LearningActivities data={data.learning_activities} />
          </div>
          <div>
            <FutureActivities data={data.future_activities} />
          </div>
        </div>
      </div>
    </div>
  );
}
