import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);
export default function LearningStyle({ data }) {
  console.log(data, "lerning");

  const audio = data?.audio?.reduce((accumulator, currentValue) => {
    if (currentValue?.response[0]?.value === "Often") {
      return accumulator + 5;
    } else if (currentValue?.response[0]?.value === "Sometimes") {
      return accumulator + 3;
    } else {
      return accumulator + 1;
    }
  }, 0);
  const kinesthetic = data?.kinesthetic?.reduce((accumulator, currentValue) => {
    if (currentValue?.response[0]?.value === "Often") {
      return accumulator + 5;
    } else if (currentValue?.response[0]?.value === "Sometimes") {
      return accumulator + 3;
    } else {
      return accumulator + 1;
    }
  }, 0);
  const visual = data?.visual?.reduce((accumulator, currentValue) => {
    if (currentValue?.response[0]?.value === "Often") {
      return accumulator + 5;
    } else if (currentValue?.response[0]?.value === "Sometimes") {
      return accumulator + 3;
    } else {
      return accumulator + 1;
    }
  }, 0);

  let score = {
    audio: audio,
    visual: visual,
    kinesthetic: kinesthetic,
  };

  let pieChartData = {
    labels: ["Audio", "Visual", "Kinesthetic"],
    datasets: [
      {
        data: [score.audio, score.visual, score.kinesthetic], // Data values for the segments
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"], // Colors for segments
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"], // Colors for hover effect
      },
    ],
  };
  const options = {
    plugins: {
      tooltip: {
        enabled: true, // Keep the tooltip open permanently
      },
    },
  };
  console.log(score, "score");
  return (
    <div className="w-full p-2 py-3">
      <div className="grid grid-cols-12">
        <div className="col-spna-12 md:col-span-6">
          <Pie data={pieChartData} options={options} />
        </div>
        <div className="col-spna-12 md:col-span-6">
          <div className="w-full p-2 h-full flex justify-center flex-col">
            <div className="text-sm font-bold">Remark:</div>
            {/* <div className="text-sm ">You prefer to learn by Audio</div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
