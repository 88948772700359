/* eslint-disable*/
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import NotificationService from "../services/notification.service";

export const getAllNotifications = createAsyncThunk(
    "notification/getAllNotifications",
    async (data, thunkAPI) => {
        try {
            if(data.id === undefined) return {};
            const response = await NotificationService.getAllNotifications(data.id);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue();
        }
    }
)

export const createNotification = createAsyncThunk(
    "notification/createNotification",
    async (data, thunkAPI) => {
        try {
            const response = await NotificationService.createNotification(
                data.title,
                data.description,
                data.user_id,
                data.is_read
            );
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue();
        }
    }
)

export const updateNotification = createAsyncThunk(
    "notification/updateNotification",
    async (data, thunkAPI) => {
        try {
            const response = await NotificationService.updateNotification(
                data.notification_id,
                data.is_read
            );
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue();
        }
    }
)

export const deleteNotification = createAsyncThunk(
    "notification/deleteNotification",
    async (data, thunkAPI) => {
        try {
            const response = await NotificationService.deleteNotification(data.notification_id);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue();
        }
    }
)

const initialState = {
    notifications: [],
    status: "idle",
    error: null
}

const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        setNotifications(state, action) {
            state.notifications = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllNotifications.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(getAllNotifications.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.notifications = action.payload;
            })
            .addCase(getAllNotifications.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(createNotification.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(createNotification.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.notifications.push(action.payload);
            })
            .addCase(createNotification.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(updateNotification.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(updateNotification.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.notifications = state.notifications.map((notification) => {
                    if (notification.notification_id === action.payload.id) {
                        return action.payload;
                    }
                    return notification;
                });
            })
            .addCase(updateNotification.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(deleteNotification.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(deleteNotification.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.notifications = state.notifications.filter((notification) => {
                    return notification.id !== action.payload.id;
                });
            })
            .addCase(deleteNotification.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
    }
})

export default notificationSlice.reducer