import axios from "axios";

const API_URL = `${process.env.REACT_APP_HOST}/question/`;

const getQuestion = (id) => {
    return axios.get(API_URL + "getQuestion/" + id);
}

const addQuestion = (question) => {
    return axios.post(API_URL + "addQuestion", question);
}

const getQuestionsByCreator = (user_id) => {
    return axios.get(API_URL + "getQuestionsByCreator/" + user_id)
}

const updateQuestion = (question, question_id) => {
    return axios.post(API_URL + "updateQuestion/"+question_id, question);
}

const deleteQuestion = (id) => {
    return axios.post(API_URL + "deleteQuestion/"+ id);
}

const getSetOfQuestions = (assessment_id) => {
    return axios.get(API_URL + "getSetOfQuestions/" + assessment_id);
}

const getAllQuestions = () => {
    return axios.get(API_URL + "getAllQuestions");
}

const uploadQuestionImage = (formData) => {
    return axios.post(API_URL + "uploadImage", formData);
}

const QuestionService = {
    getQuestion,
    getQuestionsByCreator,
    addQuestion,
    updateQuestion,
    deleteQuestion,
    getSetOfQuestions,
    getAllQuestions,
    uploadQuestionImage
}

export default QuestionService;