import React, { useEffect, useState } from "react";
import Introduction from "./Introduction";
import AcademicResults from "./AcademicResults";
import Expertise from "./Expertise";
import Proficiencies from "./Proficiencies";
import AcademicProgress from "./AcademicProgress";
import Achievements from "./Achievements";
import Projects from "./Projects";
import Hobbies from "./Hobbies";
import AngerSituation from "./AngerSituation";
import Goals from "./Goals";
import Strengths from "./Strengths";
import StressSituation from "./StressSituation";
import ValueSystem from "./ValueSystem";
import Weaknesses from "./Weaknesses";
import AreasOfInterests from "./AreasOfInterests";
import EventParticipations from "./EventParticipations";
import OrgnaizedEvents from "./OrganizedEvents";
import IndustryEngagements from "./IndustryEngagements";
import FieldProjects from "./FieldProjects";
import SurveysOrPolls from "./SurveysOrPolls";

export default function CandidateEvaluation({ data }) {
  console.log("Candidate Dat", data)
  let round1_ques = [
    {
      type: "Introduction",
      question_id: 40,
    },
    {
      type: "Hobbies",
      question_id: 27,
    },
    {
      type: "Strengths",
      question_id: 28,
    },
    {
      type: "Weaknesses",
      question_id: 29,
    },
    {
      type: "Areas of Interests",
      question_id: 41,
    },
    {
      type: "Expertise",
      question_id: 33,
    },
    {
      type: "Current Proficiencies",
      question_id: 47,
    },
    {
      type: "Anger Situation",
      question_id: 30,
    },
    {
      type: "Difficult Situation",
      question_id: 45,
    },
    {
      type: "Goals",
      question_id: 44,
    },
    {
      type: "Achievements",
      question_id: 43,
    },
    {
      type: "Academic/Career Progress",
      question_id: 32,
    },
    {
      type: "Value System",
      question_id: 31,
    },
    {
      type: "Academic Results",
      question_id: 42,
    },
    {
      type: "Research Papers",
      question_id: 48,
    },
    {
      type: "Event Participations",
      question_id: 52,
    },
    {
      type: "Organized Events",
      question_id: 53,
    },
    {
      type: "Surveys or Polls",
      question_id: 49,
    },
    {
      type: "Industry Engagements",
      question_id: 50,
    },
    {
      type: "Field Projects",
      question_id: 54,
    },
  ];
  let questionResponse = [
    {
      question_id: 40,
      question_type: "sa",
      response: [
        {
          id: 1,
          res_type: "",
          value:
            "I am a dedicated and skill-driven individual, propelled by the motivation to contribute my expertise while consistently pushing the boundaries of my skillset. My unwavering commitment to achieving impactful outcomes drives me to constantly strive for excellence. With a passion for growth and a hunger for knowledge, I am eager to channel my abilities into endeavors that make a real difference.",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 27,
      question_type: "vmti",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Photography",
        },
        {
          id: 2,
          res_type: "",
          value: "Playing Guitar",
        },
        {
          id: 3,
          res_type: "",
          value: "Table Tennis",
        },
        {
          id: 4,
          res_type: "",
          value: "Reading Books",
        },
        {
          id: 5,
          res_type: "",
          value: "Learning about new Technologies",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 28,
      question_type: "vmti",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Problem Solving",
        },
        {
          id: 2,
          res_type: "",
          value: "Analytical Thinking",
        },
        {
          id: 3,
          res_type: "",
          value: "Optimistic Attitude",
        },
        {
          id: 4,
          res_type: "",
          value: "Adaptive",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 29,
      question_type: "vmti",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Public Speaking",
        },
        {
          id: 2,
          res_type: "",
          value: "Presentations",
        },
        {
          id: 3,
          res_type: "",
          value: "Impatience",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 30,
      question_type: "sa",
      response: [
        {
          id: 1,
          res_type: "",
          value:
            "It could be anything from encountering injustice or inefficiency to dealing with certain types of behavior.",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 31,
      question_type: "sa",
      response: [
        {
          id: 1,
          res_type: "",
          value: "My Father",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 43,
      question_type: "la",
      response: [
        {
          id: 1,
          res_type: "",
          value:
            "Secured 96% in 10th, Became Western Odisha Topper in 10th, Participated in olympiads and talent hunts, Secured 89% in 12th,Scored 8.81 in B.Tech",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 47,
      question_type: "vmti",
      response: [
        {
          id: 1,
          res_type: "",
          value: "C",
        },
        {
          id: 1,
          res_type: "",
          value: "C++",
        },
        {
          id: 1,
          res_type: "",
          value: "HTML",
        },
        {
          id: 1,
          res_type: "",
          value: "CSS",
        },
        {
          id: 1,
          res_type: "",
          value: "JavaScript",
        },
        {
          id: 1,
          res_type: "",
          value: "Java",
        },
        {
          id: 1,
          res_type: "",
          value: "Python",
        },
        {
          id: 1,
          res_type: "",
          value: "postgreSQL",
        },
        {
          id: 1,
          res_type: "",
          value: "Bootstrap",
        },
        {
          id: 1,
          res_type: "",
          value: "React.js",
        },
        {
          id: 1,
          res_type: "",
          value: "DSA",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 32,
      question_type: "la",
      response: [
        {
          id: 1,
          res_type: "",
          value: "HighSchool - 96%  Intermediate - 89%  B.Tech - 8.81 CGPA",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 41,
      question_type: "vmti",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Photography",
        },
        {
          id: 1,
          res_type: "",
          value: "Community involvement",
        },
        {
          id: 1,
          res_type: "",
          value: "Blogging",
        },
        {
          id: 1,
          res_type: "",
          value: "Traveling",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 11,
      question_type: "vmti",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Web Development",
        },
        {
          id: 1,
          res_type: "",
          value: "DBMS",
        },
        {
          id: 1,
          res_type: "",
          value: "Object Oriented Programming",
        },
        {
          id: 1,
          res_type: "",
          value: "Computer Architecture",
        },
        {
          id: 1,
          res_type: "",
          value: "Operating Systems",
        },
        {
          id: 1,
          res_type: "",
          value: "UI/UX Development",
        },
        {
          id: 1,
          res_type: "",
          value: "Communication",
        },
        {
          id: 1,
          res_type: "",
          value: "Leadership",
        },
        {
          id: 1,
          res_type: "",
          value: "Time Management",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 12,
      question_type: "sa",
      response: [
        {
          id: 1,
          res_type: "",
          value:
            "The toughest phase of my life was when I appeared for JEE mains , I suffered from Chicken Pox hence I was unable to focus on the questions and wasn't able to deliver my 100%.",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 13,
      question_type: "ebq",
      response: [
        {
          id: 1,
          res_type: "",
          value:
            "<p>1. Dental Website <br>It's a fully responsive website focusing on the dental services <br> Built with HTML, React js, Node js, and Bootstrap <br> https://github.com/simranpattanayak/Donto-master.git <br></p><br><p> 2. To-do List <br> It's an interface where users can add and delete the day to day chores for <br> easy organized and systematic execution <br> Built with HTML, CSS, React js <br> https://github.com/simranpattanayak/to-do-app.git </p>",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 14,
      question_type: "la",
      response: [
        {
          id: 1,
          res_type: "",
          value:
            "Personal: Gaining a stable work-life balance and keeping a healthy lifestyle,   Professional: Advancing within an establishment and keep working on new and major projects,    Academic: Graduating with a project in my portfolio that I can be proud of.",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 15,
      question_type: "ebq",
      response: [
        {
          id: 1,
          res_type: "",
          value:
            "<p>My most important accomplishments involve grabbing the Harvard World Record as the first Indian and youngest ever AI Researcher to be selected as a contributor to Artificial Intelligence Software of Choice (AISOC) 2023 Report organised by Artificial Intelligence Accelerator Institute (AIAI), USA.</p><p><br>My other accomplishments involve developing a Deep Learning, Machine Learning Integrated model which can detect PCOS based on input as the picture of skin.</p><p> <br>Other achievements involve my selection in the Odisha U-19 Cricket team as a right arm fast bowler and also becoming the leading wicket taker in the eastern wing gradually</p>",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
  ];
  console.log("ataa", data);
  const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   const getRounds = async () => {
  //     setLoading(true);
  //     let responseId = data?.reponse_id;
  //     if (!data) {
  //       const response = await AssessmentResponseService.getAssessmentResponse(
  //         responseId
  //       );
  //       setSelectedResponse(response.data);
  //     }

  //     setLoading(false);
  //   };
  //   getRounds();
  // }, [data]);
  return (
    <div>
      {data ? (
        <div>
          {loading ? (
            <>Loading</>
          ) : (
            <div className="w-full h-full pt-16">
              <div className="grid grid-cols-12">
                <div className="col-span-12 md:col-span-7">
                  <div className="w-full h-full p-3">
                    <div className=" pb-2">
                      {round1_ques.find((e) => e.type === "Introduction") && (
                        <Introduction
                          data={data[0]?.question_response?.find(
                            (q) =>
                              q.question_id ===
                              round1_ques?.find(
                                (e) => e.type === "Introduction"
                              )?.question_id
                          )}
                        />
                      )}
                    </div>
                    <div className="grid grid-cols-12">
                      <div className="col-span-12 md:col-span-7 h-full">
                        <div className="w-full h-full pt-3 pr-3">
                          {round1_ques.find((e) => e.type === "Expertise") && (
                            <Expertise
                              data={data[0]?.question_response?.find(
                                (q) =>
                                  q.question_id ===
                                  round1_ques?.find(
                                    (e) => e.type === "Expertise"
                                  )?.question_id
                              )}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-span-12 md:col-span-5">
                        <div className="w-full h-full pt-3 pl-3">
                          {round1_ques.find(
                            (e) => e.type === "Current Proficiencies"
                          ) && (
                            <Proficiencies
                              data={data[0]?.question_response?.find(
                                (q) =>
                                  q.question_id ===
                                  round1_ques?.find(
                                    (e) => e.type === "Current Proficiencies"
                                  )?.question_id
                              )}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-span-12">
                        <div className="w-full h-full pt-5">
                          {round1_ques.find(
                            (e) => e.type === "Research Papers"
                          ) && (
                            <Projects
                              data={data[0]?.question_response?.find(
                                (q) =>
                                  q.question_id ===
                                  round1_ques?.find(
                                    (e) => e.type === "Research Papers"
                                  )?.question_id
                              )}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-span-12">
                        <div className="w-full h-full pt-5">
                          {round1_ques.find(
                            (e) => e.type === "Surveys or Polls"
                          ) && (
                            <SurveysOrPolls
                              data={data[0]?.question_response?.find(
                                (q) =>
                                  q.question_id ===
                                  round1_ques?.find(
                                    (e) => e.type === "Surveys or Polls"
                                  )?.question_id
                              )}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-span-12">
                        <div className="w-full h-full pt-5">
                          {round1_ques.find(
                            (e) => e.type === "Industry Engagements"
                          ) && (
                            <IndustryEngagements
                              data={data[0]?.question_response?.find(
                                (q) =>
                                  q.question_id ===
                                  round1_ques?.find(
                                    (e) => e.type === "Industry Engagements"
                                  )?.question_id
                              )}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-span-12">
                        <div className="w-full h-full pt-5">
                          {round1_ques.find(
                            (e) => e.type === "Field Projects"
                          ) && (
                            <FieldProjects
                              data={data[0]?.question_response?.find(
                                (q) =>
                                  q.question_id ===
                                  round1_ques?.find(
                                    (e) => e.type === "Field Projects"
                                  )?.question_id
                              )}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-12 md:col-span-5">
                  <div className="w-full h-full">
                    <div className="p-3">
                      {round1_ques.find(
                        (e) => e.type === "Academic Results"
                      ) && (
                        <AcademicResults
                          data={data[0]?.question_response?.find(
                            (q) =>
                              q.question_id ===
                              round1_ques?.find(
                                (e) => e.type === "Academic Results"
                              )?.question_id
                          )}
                        />
                      )}
                    </div>
                    <div className="p-3">
                      {round1_ques.find(
                        (e) => e.type === "Academic/Career Progress"
                      ) && (
                        <AcademicProgress
                          data={data[0]?.question_response?.find(
                            (q) =>
                              q.question_id ===
                              round1_ques?.find(
                                (e) => e.type === "Academic/Career Progress"
                              )?.question_id
                          )}
                        />
                      )}
                    </div>
                    <div className="p-3">
                      {round1_ques.find((e) => e.type === "Achievements") && (
                        <Achievements
                          data={data[0]?.question_response?.find(
                            (q) =>
                              q.question_id ===
                              round1_ques?.find(
                                (e) => e.type === "Achievements"
                              )?.question_id
                          )}
                        />
                      )}
                    </div>
                    <div className="p-3">
                      {round1_ques.find((e) => e.type === "Goals") && (
                        <Goals
                          data={data[0]?.question_response?.find(
                            (q) =>
                              q.question_id ===
                              round1_ques?.find((e) => e.type === "Goals")
                                ?.question_id
                          )}
                        />
                      )}
                    </div>
                    <div className="p-3">
                      {round1_ques.find(
                        (e) => e.type === "Event Participations"
                      ) && (
                        <EventParticipations
                          data={data[0]?.question_response?.find(
                            (q) =>
                              q.question_id ===
                              round1_ques?.find(
                                (e) => e.type === "Event Participations"
                              )?.question_id
                          )}
                        />
                      )}
                    </div>
                    <div className="p-3">
                      {round1_ques.find(
                        (e) => e.type === "Organized Events"
                      ) && (
                        <OrgnaizedEvents
                          data={data[0]?.question_response?.find(
                            (q) =>
                              q.question_id ===
                              round1_ques?.find(
                                (e) => e.type === "Organized Events"
                              )?.question_id
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-2">
                <div className="grid grid-cols-12">
                  <div className="col-span-12 md:col-span-3">
                    <div className="w-full h-full">
                      <div className="p-3">
                        {round1_ques.find((e) => e.type === "Hobbies") && (
                          <Hobbies
                            data={data[0]?.question_response?.find(
                              (q) =>
                                q.question_id ===
                                round1_ques?.find((e) => e.type === "Hobbies")
                                  ?.question_id
                            )}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-3">
                    <div className="w-full h-full">
                      <div className="p-3">
                        {round1_ques.find((e) => e.type === "Strengths") && (
                          <Strengths
                            data={data[0]?.question_response?.find(
                              (q) =>
                                q.question_id ===
                                round1_ques?.find((e) => e.type === "Strengths")
                                  ?.question_id
                            )}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-3">
                    <div className="w-full h-full">
                      <div className="p-3">
                        {round1_ques.find((e) => e.type === "Weaknesses") && (
                          <Weaknesses
                            data={data[0]?.question_response?.find(
                              (q) =>
                                q.question_id ===
                                round1_ques?.find(
                                  (e) => e.type === "Weaknesses"
                                )?.question_id
                            )}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-3">
                    <div className="w-full h-full">
                      <div className="p-3">
                        {round1_ques.find(
                          (e) => e.type === "Areas of Interests"
                        ) && (
                          <AreasOfInterests
                            data={data[0]?.question_response?.find(
                              (q) =>
                                q.question_id ===
                                round1_ques?.find(
                                  (e) => e.type === "Areas of Interests"
                                )?.question_id
                            )}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-2">
                <div className="grid grid-cols-12">
                  <div className="col-span-12 md:col-span-4">
                    <div className="w-full h-full">
                      <div className="p-3">
                        {round1_ques.find(
                          (e) => e.type === "Anger Situation"
                        ) && (
                          <AngerSituation
                            data={data[0]?.question_response?.find(
                              (q) =>
                                q.question_id ===
                                round1_ques?.find(
                                  (e) => e.type === "Anger Situation"
                                )?.question_id
                            )}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-4">
                    <div className="w-full h-full">
                      <div className="p-3">
                        {round1_ques.find((e) => e.type === "Value System") && (
                          <ValueSystem
                            data={data[0]?.question_response?.find(
                              (q) =>
                                q.question_id ===
                                round1_ques?.find(
                                  (e) => e.type === "Value System"
                                )?.question_id
                            )}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-4">
                    <div className="w-full h-full">
                      <div className="p-3">
                        {round1_ques.find(
                          (e) => e.type === "Difficult Situation"
                        ) && (
                          <StressSituation
                            data={data[0]?.question_response?.find(
                              (q) =>
                                q.question_id ===
                                round1_ques?.find(
                                  (e) => e.type === "Difficult Situation"
                                )?.question_id
                            )}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <>No Data</>
      )}
    </div>
  );
}
