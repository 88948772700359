/* eslint-disable */
import { useState, useEffect } from "react";
import { Form, useNavigate } from "react-router-dom";
import { generateOtp, verifyOtp } from "../slices/auth";
import { useDispatch } from "react-redux";
import AuthService from "../services/auth.service";
import ChangePassword from "./ChangePassword";
import Logo from "../../assets/images/new-logo.png";
// import logo from '../../assets/images/login-img.png';
import {toast} from "react-toastify"

const ForgotPassword = () => {
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpCorrect, setOtpCorrect] = useState(false);
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();

  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/login`;
    navigate(path);
  };

  const sendOtp = (email) => {
    // console.log("email", email);
    dispatch(generateOtp({ email, type: "changePwd" }))
      .unwrap()
      .then((res) => {
        if(res){
          setOtpSent(true);
          toast.success(res)
        }

      })
      .catch((err) => {
        setOtpSent(false);
        toast.error(err)
      });
  };

  const confirmOtp = (email, otp) => {
    AuthService.verifyOtp({ email, otp }).then(
      () => {
        setOtpCorrect(true);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setOtpCorrect(false);
      }
    );
  };

  return (
    <>
      {otpCorrect ? (
        <>
          <ChangePassword email_id_passed={email} />
        </>
      ) : (
        <>
          <div className="container d-flex flex-column poppins">
            <div
              className="row align-items-center justify-content-center
                            min-vh-100"
            >
              <div className="col-12 col-md-8 col-lg-4">
                <div className="card shadow-sm">
                  <div className="card-title">
                    <a href="/">
                      <img
                        loading="lazy"
                        src={Logo}
                        className="rounded mb-3"
                        height={50}
                      />
                    </a>
                  </div>
                  <div className="card-body">
                    <div className="mb-6">
                      <div className="mt-2 text-lg md:text-xl lg:text-3xl font-semibold text-dark">
                        Forgot Password?
                      </div>
                      <div className="mb-2 mt-2 text-slate-400 text-sm">
                        Enter your registered email ID to reset the password
                      </div>
                    </div>
                    <form className=" mb-8">
                      <div className="my-10">
                        <div className=" mt-2 mb-4">
                          <div htmlFor="email" className="">
                            Email <span className="text-red-500">*</span>
                          </div>
                          <input
                            type="email"
                            id="email"
                            className="form-control"
                            name="email"
                            placeholder="Enter your email"
                            required=""
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                          />
                        </div>
                       { <div className="mb-3 d-grid">
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              sendOtp(email);
                            }}
                            className="btn btn-primary"
                          >
                            {otpSent ? "Resend OTP" : "Send OTP"}
                          </button>
                        </div>}
                      </div>
                      <hr />
                      {otpSent ? (
                        <div className="my-10">
                          <div className="mb-3">
                          <div htmlFor="otp" className="">
                            Enter the OTP <span className="text-red-500">*</span>
                          </div>
                            <input
                              type="text"
                              id="otp"
                              className="form-control"
                              name="otp"
                              placeholder="Enter the OTP"
                              required=""
                              onChange={(e) => {
                                setOtp(e.target.value);
                              }}
                            />
                          </div>
                          <div className="mb-3 d-grid">
                            <button
                              onClick={(e) => {
                                confirmOtp(email, otp);
                                e.preventDefault();
                              }}
                              className="btn btn-primary"
                            >
                              Verify OTP
                            </button>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      <span>
                        Don't have an account? <a href="sign-up">sign up</a>
                      </span>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ForgotPassword;
