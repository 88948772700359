import React, { useState } from "react";
import EnglishComm from "./EnglishComm";
import GoalSetting from "./GoalSetting";
import SkilAudit from "./SkillAudit";
import LearningStyle from "./LearningStyle";
import PersonalityTest from "./PersonalityTest";

export default function HumanIntel({ data }) {
  console.log("assess_response", data[0]?.question_response);
  let menus = [
    "Communicative English",
    "Skill Audit",
    "Learning Style",
    "Personality Test",
    "Smart Goal Setting",
  ];

  const [selectedMenus, setSelectedMenus] = useState(menus[0]);
  let round2_ques = {
    learning_style: {
      visual: [112, 113, 117, 120, 124, 126, 129, 132],
      audio: [111, 115, 118, 121, 123, 128, 131, 134],
      kinesthetic: [114, 116, 119, 122, 125, 127, 130, 133],
    },
    personality_test: [135, 136, 137, 138, 139, 140, 141, 142, 143, 144],
    eng_comm: {
      basic: {
        time_spent: [64, 65, 66, 67],
        grade: [68],
        current_ability: [34],
        interest: [70],
        travel: [72],
      },
      learning_activities: [73],
      future_activities: [74],
      feeling_about_english: [75, 76, 77, 78, 79],
      eng_presentation: [80, 81, 82, 83, 84],
      improvement: [85, 86, 87, 88, 89, 90],
      proficiency_needed: [90, 91, 92, 93, 94],
      way_to_improve: [
        96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110,
      ],
    },
    smart_goals: {
      smart: {
        specific: [145],
        measurable: [146],
        achievable: [147],
        relavent: [148],
        time: [149],
      },
      goal_setting: [150, 151, 152, 153, 154, 155, 156],
      action_plan: [157],
    },
    skill_audit: {
      organized_learning: [158, 159, 160, 161, 162, 163, 164, 165],
      information_seeking: [166, 167, 177, 169, 170, 171, 172, 173, 175, 176],
      note_making: [178, 179, 180, 181, 182, 183, 184, 185, 186, 187],
      writing: [188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199],
      spoken_communication: [
        200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211,
      ],
      information_technology: [212, 213, 214, 215],
      working_with_numbers: [216, 217, 218, 219, 220, 221, 222, 223],
      revision_exam: [224, 225, 226, 227, 228, 229, 230, 231, 232, 233],
      stress_management: [234, 235, 236, 237, 238, 239],
      plp: [240, 241, 242, 243, 244, 245, 246, 247],
    },
  };
  let questionResponses = [
    {
      question_id: 1,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Sometimes",
        },
      ],
      score_obtained: 3,
      score_by: NaN,
    },
    {
      question_id: 2,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Often",
        },
      ],
      score_obtained: 5,
      score_by: NaN,
    },
    {
      question_id: 3,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Sometimes",
        },
      ],
      score_obtained: 3,
      score_by: NaN,
    },
    {
      question_id: 4,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Seldom",
        },
      ],
      score_obtained: 1,
      score_by: NaN,
    },
    {
      question_id: 5,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Sometimes",
        },
      ],
      score_obtained: 3,
      score_by: NaN,
    },
    {
      question_id: 6,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Sometimes",
        },
      ],
      score_obtained: 3,
      score_by: NaN,
    },
    {
      question_id: 7,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Often",
        },
      ],
      score_obtained: 5,
      score_by: NaN,
    },
    {
      question_id: 8,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Sometimes",
        },
      ],
      score_obtained: 3,
      score_by: NaN,
    },
    {
      question_id: 9,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Sometimes",
        },
      ],
      score_obtained: 3,
      score_by: NaN,
    },
    {
      question_id: 10,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Often",
        },
      ],
      score_obtained: 5,
      score_by: NaN,
    },
    {
      question_id: 11,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Sometimes",
        },
      ],
      score_obtained: 3,
      score_by: NaN,
    },
    {
      question_id: 12,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Sometimes",
        },
      ],
      score_obtained: 3,
      score_by: NaN,
    },
    {
      question_id: 13,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Sometimes",
        },
      ],
      score_obtained: 3,
      score_by: NaN,
    },
    {
      question_id: 14,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Often",
        },
      ],
      score_obtained: 5,
      score_by: NaN,
    },
    {
      question_id: 15,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Sometimes",
        },
      ],
      score_obtained: 3,
      score_by: NaN,
    },
    {
      question_id: 16,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Often",
        },
      ],
      score_obtained: 5,
      score_by: NaN,
    },
    {
      question_id: 17,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Sometimes",
        },
      ],
      score_obtained: 3,
      score_by: NaN,
    },
    {
      question_id: 18,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Sometimes",
        },
      ],
      score_obtained: 3,
      score_by: NaN,
    },
    {
      question_id: 19,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Often",
        },
      ],
      score_obtained: 5,
      score_by: NaN,
    },
    {
      question_id: 20,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Sometimes",
        },
      ],
      score_obtained: 3,
      score_by: NaN,
    },
    {
      question_id: 21,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Sometimes",
        },
      ],
      score_obtained: 3,
      score_by: NaN,
    },
    {
      question_id: 22,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Often",
        },
      ],
      score_obtained: 5,
      score_by: NaN,
    },
    {
      question_id: 23,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Sometimes",
        },
      ],
      score_obtained: 3,
      score_by: NaN,
    },
    {
      question_id: 24,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Sometimes",
        },
      ],
      score_obtained: 3,
      score_by: NaN,
    },
    {
      question_id: 25,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "In the morning",
        },
      ],
      score_obtained: 2,
      score_by: NaN,
    },
    {
      question_id: 26,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Less fast head up, looking the world in the face.",
        },
      ],
      score_obtained: 7,
      score_by: NaN,
    },
    {
      question_id: 27,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Have your hands clasped",
        },
      ],
      score_obtained: 2,
      score_by: NaN,
    },
    {
      question_id: 28,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Your legs stretched out or straight",
        },
      ],
      score_obtained: 2,
      score_by: NaN,
    },
    {
      question_id: 29,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Big appreciated laugh",
        },
      ],
      score_obtained: 6,
      score_by: NaN,
    },
    {
      question_id: 30,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Make a quite entrance, looking around for someone you know.",
        },
      ],
      score_obtained: 4,
      score_by: NaN,
    },
    {
      question_id: 31,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Vary between these two extremes",
        },
      ],
      score_obtained: 1,
      score_by: NaN,
    },
    {
      question_id: 32,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Yellow or light blue",
        },
      ],
      score_obtained: 5,
      score_by: NaN,
    },
    {
      question_id: 33,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Stretched out face down on your stomach",
        },
      ],
      score_obtained: 6,
      score_by: NaN,
    },
    {
      question_id: 34,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "You usually have dreamless sleep",
        },
      ],
      score_obtained: 6,
      score_by: NaN,
    },
    {
      question_id: 35,
      question_type: "number",
      response: [
        {
          id: 1,
          res_type: "",
          value: 3,
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 36,
      question_type: "number",
      response: [
        {
          id: 1,
          res_type: "",
          value: 4,
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 37,
      question_type: "number",
      response: [
        {
          id: 1,
          res_type: "",
          value: 3,
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 38,
      question_type: "number",
      response: [
        {
          id: 1,
          res_type: "",
          value: 4,
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 39,
      question_type: "number",
      response: [
        {
          id: 1,
          res_type: "",
          value: 8.1,
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 40,
      question_type: "tsfna",
      response: [
        {
          id: 1,
          res_type: "option",
          value: "True",
        },
        {
          id: 1,
          res_type: "text",
          value:
            "By watching youtube videos on proper usage of English and and taking free verbal ability test time to time.",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 41,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "No",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 42,
      question_type: "mca",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Playing English games",
        },
        {
          id: 2,
          res_type: "",
          value: "Playing with words (i.e., tongue twister) and chants",
        },
        {
          id: 3,
          res_type: "",
          value: "Reading English picture books (picture-story show)",
        },
        {
          id: 4,
          res_type: "",
          value:
            "Introducing various languages (i.e., greetings, sayings, etc.)",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 43,
      question_type: "mca",
      response: [
        {
          id: 1,
          res_type: "",
          value:
            "Introducing various foreign foods (i.e., showing pictures, tasting, etc.)",
        },
        {
          id: 2,
          res_type: "",
          value:
            "Introducing other countries' holidays and festivals (i.e., Halloween, Christmas, etc.)",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 44,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Agree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 45,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Slightly Agree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 46,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Slightly Disagree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 47,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Agree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 48,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Disagree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 49,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Disagree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 50,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Slightly Agree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 51,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Agree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 52,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Slightly Disagree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 53,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Agree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 54,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Agree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 55,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Slightly Disagree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 56,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Slightly Agree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 57,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Agree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 58,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Disagree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 59,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Disagree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 60,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Slightly Agree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 61,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Agree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 62,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Agree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 63,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Agree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 64,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Disagree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 65,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Slightly Agree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 66,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Agree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 67,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Agree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 68,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Agree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 69,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Disagree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 70,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Slightly Agree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 71,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Agree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 72,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Agree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 73,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Agree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 74,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Disagree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 75,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Slightly Agree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 76,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Agree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 77,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Agree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 78,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Agree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 79,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value: "Disagree",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 80,
      question_type: "mcq",
      response: [
        {
          id: 1,
          res_type: "",
          value:
            "I do not think it is sufficient enough, but I have no problem understanding class lessons.",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 81,
      question_type: "la",
      response: [
        {
          id: 1,
          res_type: "",
          value:
            "My specific goal is to become an expert within the field of AI, specialising in Machine Learning and Deep Learning.",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 82,
      question_type: "la",
      response: [
        {
          id: 1,
          res_type: "",
          value:
            "I will consider my goal achieved when I publish a research paper on AI, receive feedbacks from AI professionals on my project that demonstrates cervical cancer detection using AI in place of infrared imaging whose accuracy will go above 90% slowly and gradually, and create portfolio showcasing atleast three to four such mega projects on AI.",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 83,
      question_type: "la",
      response: [
        {
          id: 1,
          res_type: "",
          value:
            "I have a solid foundation in Machine Learning. While becoming an AI expert is ambitious, I have access to online courses, research papers, and a supportive network of AI practitioners. With 'consistent' and 'dedicated effort, I believe I can achieve the goal",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 84,
      question_type: "la",
      response: [
        {
          id: 1,
          res_type: "",
          value:
            "Pursuing expertise in ML aligns with my passion for both AI and DS. It also resonates with my career goal of contributing meaningfully to AI applications.",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 85,
      question_type: "vmti",
      response: [
        {
          id: 1,
          res_type: "",
          value: "I will achieve my goal by Today's Date: 26 August 2023",
        },
        {
          id: 2,
          res_type: "",
          value:
            "1st Step: Complete two advance courses on AI and ML - Deadline: 26 October 2023",
        },
        {
          id: 3,
          res_type: "",
          value:
            "2nd Step: Submit my first research paper on AI in Healthcare by the end of the year - Deadline: 26 December",
        },
        {
          id: 4,
          res_type: "",
          value:
            "3rd Step: Within next 6 months, learning and performing a project on Generative AI - Deadline: 28 February 2024",
        },
        {
          id: 5,
          res_type: "",
          value:
            "4th Step: Filling atleast one big industry level gap in AI before the end of the year - Deadline: 31 December 2024",
        },
        {
          id: 6,
          res_type: "",
          value:
            "5th Step: Celebrate the success but at the same time, will learn more and will always be developing my designed model with current changes and developments. - Deadline: No such (Forever)",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 86,
      question_type: "la",
      response: [
        {
          id: 1,
          res_type: "",
          value:
            "I want to develop a noble device using Generative AI and fill a big gap in Healthcare by completing my goal.",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 87,
      question_type: "la",
      response: [
        {
          id: 1,
          res_type: "",
          value:
            "I want to give it my best and try to save as many lives, as much I can as I have seen my grandmother losing her life due to this cancer which always knocks my mind when I find out some time in between studies to roam, enjoy and getting into a comfort zone.",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 88,
      question_type: "vmti",
      response: [
        {
          id: 1,
          res_type: "",
          value:
            "First Step is to learn some advance courses related to AI and Generative AI.",
        },
        {
          id: 2,
          res_type: "",
          value:
            "Second Step is to publish a research paper about my findings related to the field.",
        },
        {
          id: 3,
          res_type: "",
          value:
            "Third Step is to develop a model related to Healthcare by the integration of Generative AI.",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 89,
      question_type: "la",
      response: [
        {
          id: 1,
          res_type: "",
          value:
            "It's always the first step that is important, the most important indeed to me because without that, proceeding further is not even an option for me. I would like to do smart work but not in the path of following shortcuts.",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 90,
      question_type: "la",
      response: [
        {
          id: 1,
          res_type: "",
          value:
            "I need to improvise my efficiency in both reading and writing in order to strengthen my goal accomplishment. I need to have a grip on writing part which will help me with the thesis and the reading part will help me understand and figure out the gaps studying various literature reviews.",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 91,
      question_type: "la",
      response: [
        {
          id: 1,
          res_type: "",
          value:
            "Artificial Intelligence, Machine Learning, Deep Learning, Enthusiasm, Reading and Writing Proficiency, Team Work, Time Management, Team Management, Project Management, Listening, Curiosity to learn new things enthusiastically, Team Player",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 92,
      question_type: "la",
      response: [
        {
          id: 1,
          res_type: "",
          value:
            "Sometimes that old thought of my grandmother stands as an obstacle. This is very strange as I consider it my strength as well but the thought of loss sometimes pulls me down but my motivation towards my goals and enthusiasm always pushes me out of the comfort zone to work hard.",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
    {
      question_id: 93,
      question_type: "vmti",
      response: [
        {
          id: 1,
          res_type: "",
          value:
            "First Step is to learn some advance courses related to AI and Generative AI - by 26th October",
        },
        {
          id: 2,
          res_type: "",
          value:
            "Second Step is to publish a research paper about my findings related to the field - by 31 December 2023",
        },
        {
          id: 3,
          res_type: "",
          value:
            "Third Step is to develop a model related to Healthcare by the integration of Generative AI - 31 December 2024",
        },
      ],
      score_obtained: NaN,
      score_by: NaN,
    },
  ];

  let skillAuditRes = {
    organized_learning: data[0]?.question_response.filter((response) => {
      // Loop through each learning style (audio, visual, kinesthetic)
      for (const style in round2_ques.skill_audit.organized_learning) {
        // Check if the question_id exists in the learning style array
        if (
          round2_ques.skill_audit.organized_learning.includes(
            response.question_id
          )
        ) {
          return true; // Include the response in the filtered array
        }
      }
      return false; // Exclude the response from the filtered array
    }),
    information_seeking: data[0]?.question_response.filter((response) => {
      // Loop through each learning style (audio, visual, kinesthetic)
      for (const style in round2_ques.skill_audit.information_seeking) {
        // Check if the question_id exists in the learning style array
        if (
          round2_ques.skill_audit.information_seeking.includes(
            response.question_id
          )
        ) {
          return true; // Include the response in the filtered array
        }
      }
      return false; // Exclude the response from the filtered array
    }),
    note_making: data[0]?.question_response.filter((response) => {
      // Loop through each learning style (audio, visual, kinesthetic)
      for (const style in round2_ques.skill_audit.note_making) {
        // Check if the question_id exists in the learning style array
        if (
          round2_ques.skill_audit.note_making.includes(response.question_id)
        ) {
          return true; // Include the response in the filtered array
        }
      }
      return false; // Exclude the response from the filtered array
    }),
    writing: data[0]?.question_response.filter((response) => {
      // Loop through each learning style (audio, visual, kinesthetic)
      for (const style in round2_ques.skill_audit.writing) {
        // Check if the question_id exists in the learning style array
        if (round2_ques.skill_audit.writing.includes(response.question_id)) {
          return true; // Include the response in the filtered array
        }
      }
      return false; // Exclude the response from the filtered array
    }),
    spoken_communication: data[0]?.question_response.filter((response) => {
      // Loop through each learning style (audio, visual, kinesthetic)
      for (const style in round2_ques.skill_audit.spoken_communication) {
        // Check if the question_id exists in the learning style array
        if (
          round2_ques.skill_audit.spoken_communication.includes(
            response.question_id
          )
        ) {
          return true; // Include the response in the filtered array
        }
      }
      return false; // Exclude the response from the filtered array
    }),
    information_technology: data[0]?.question_response.filter((response) => {
      // Loop through each learning style (audio, visual, kinesthetic)
      for (const style in round2_ques.skill_audit.information_technology) {
        // Check if the question_id exists in the learning style array
        if (
          round2_ques.skill_audit.information_technology.includes(
            response.question_id
          )
        ) {
          return true; // Include the response in the filtered array
        }
      }
      return false; // Exclude the response from the filtered array
    }),
    working_with_numbers: data[0]?.question_response.filter((response) => {
      // Loop through each learning style (audio, visual, kinesthetic)
      for (const style in round2_ques.skill_audit.working_with_numbers) {
        // Check if the question_id exists in the learning style array
        if (
          round2_ques.skill_audit.working_with_numbers.includes(
            response.question_id
          )
        ) {
          return true; // Include the response in the filtered array
        }
      }
      return false; // Exclude the response from the filtered array
    }),
    revision_exam: data[0]?.question_response.filter((response) => {
      // Loop through each learning style (audio, visual, kinesthetic)
      for (const style in round2_ques.skill_audit.revision_exam) {
        // Check if the question_id exists in the learning style array
        if (
          round2_ques.skill_audit.revision_exam.includes(response.question_id)
        ) {
          return true; // Include the response in the filtered array
        }
      }
      return false; // Exclude the response from the filtered array
    }),
    stress_management: data[0]?.question_response.filter((response) => {
      // Loop through each learning style (audio, visual, kinesthetic)
      for (const style in round2_ques.skill_audit.stress_management) {
        // Check if the question_id exists in the learning style array
        if (
          round2_ques.skill_audit.stress_management.includes(
            response.question_id
          )
        ) {
          return true; // Include the response in the filtered array
        }
      }
      return false; // Exclude the response from the filtered array
    }),
    plp: data[0]?.question_response.filter((response) => {
      // Loop through each learning style (audio, visual, kinesthetic)
      for (const style in round2_ques.skill_audit.plp) {
        // Check if the question_id exists in the learning style array
        if (round2_ques.skill_audit.plp.includes(response.question_id)) {
          return true; // Include the response in the filtered array
        }
      }
      return false; // Exclude the response from the filtered array
    }),
  };
  

  let learningStyleRes = {
    audio: data[0]?.question_response.filter((response) => {
      // Loop through each learning style (audio, visual, kinesthetic)
      for (const style in round2_ques.learning_style.audio) {
        // Check if the question_id exists in the learning style array
        if (round2_ques.learning_style.audio.includes(response.question_id)) {
          return true; // Include the response in the filtered array
        }
      }

      return false; // Exclude the response from the filtered array
    }),
    visual: data[0]?.question_response.filter((response) => {
      // Loop through each learning style (audio, visual, kinesthetic)
      for (const style in round2_ques.learning_style.visual) {
        // Check if the question_id exists in the learning style array
        if (round2_ques.learning_style.visual.includes(response.question_id)) {
          return true; // Include the response in the filtered array
        }
      }

      return false; // Exclude the response from the filtered array
    }),
    kinesthetic: data[0]?.question_response.filter((response) => {
      // Loop through each learning style (audio, visual, kinesthetic)
      for (const style in round2_ques.learning_style.kinesthetic) {
        // Check if the question_id exists in the learning style array
        if (
          round2_ques.learning_style.kinesthetic.includes(response.question_id)
        ) {
          return true; // Include the response in the filtered array
        }
      }

      return false; // Exclude the response from the filtered array
    }),
  };

  let goalSetting = {
    smart: {
      specific: data[0]?.question_response.filter((response) => {
        for (const style in round2_ques.smart_goals.smart.specific) {
          if (
            round2_ques.smart_goals.smart.specific.includes(
              response.question_id
            )
          ) {
            return true;
          }
        }

        return false;
      }),
      measurable: data[0]?.question_response.filter((response) => {
        for (const style in round2_ques.smart_goals.smart.measurable) {
          if (
            round2_ques.smart_goals.smart.measurable.includes(
              response.question_id
            )
          ) {
            return true;
          }
        }

        return false;
      }),
      achievable: data[0]?.question_response.filter((response) => {
        for (const style in round2_ques.smart_goals.smart.specific) {
          if (
            round2_ques.smart_goals.smart.specific.includes(
              response.question_id
            )
          ) {
            return true;
          }
        }

        return false;
      }),
      relavent: data[0]?.question_response.filter((response) => {
        for (const style in round2_ques.smart_goals.smart.relavent) {
          if (
            round2_ques.smart_goals.smart.relavent.includes(
              response.question_id
            )
          ) {
            return true;
          }
        }

        return false;
      }),
      time: data[0]?.question_response.filter((response) => {
        for (const style in round2_ques.smart_goals.smart.time) {
          if (
            round2_ques.smart_goals.smart.time.includes(response.question_id)
          ) {
            return true;
          }
        }

        return false;
      }),
    },
    goal_setting: data[0]?.question_response.filter((response) => {
      for (const style in round2_ques.smart_goals.goal_setting) {
        if (
          round2_ques.smart_goals.goal_setting.includes(response.question_id)
        ) {
          return true;
        }
      }

      return false;
    }),
    action_plan: data[0]?.question_response.filter((response) => {
      for (const style in round2_ques.smart_goals.action_plan) {
        if (
          round2_ques.smart_goals.action_plan.includes(response.question_id)
        ) {
          return true;
        }
      }
      return false;
    }),
  };
  let engComm = {
    basic: {
      time_spent: data[0]?.question_response.filter((response) => {
        for (const style in round2_ques.eng_comm.basic.time_spent) {
          if (
            round2_ques.eng_comm.basic.time_spent.includes(response.question_id)
          ) {
            return true;
          }
        }

        return false;
      }),
      grade: data[0]?.question_response.filter((response) => {
        for (const style in round2_ques.eng_comm.basic.grade) {
          if (round2_ques.eng_comm.basic.grade.includes(response.question_id)) {
            return true;
          }
        }

        return false;
      }),
      current_ability: data[0]?.question_response.filter((response) => {
        for (const style in round2_ques.eng_comm.basic.current_ability) {
          if (
            round2_ques.eng_comm.basic.current_ability.includes(
              response.question_id
            )
          ) {
            return true;
          }
        }

        return false;
      }),
      interest: data[0]?.question_response.filter((response) => {
        for (const style in round2_ques.eng_comm.basic.interest) {
          if (
            round2_ques.eng_comm.basic.interest.includes(response.question_id)
          ) {
            return true;
          }
        }

        return false;
      }),
      travel: data[0]?.question_response.filter((response) => {
        for (const style in round2_ques.eng_comm.basic.travel) {
          if (
            round2_ques.eng_comm.basic.travel.includes(response.question_id)
          ) {
            return true;
          }
        }
        return false;
      }),
    },
    learning_activities: data[0]?.question_response.filter((response) => {
      for (const style in round2_ques.eng_comm.learning_activities) {
        if (
          round2_ques.eng_comm.learning_activities.includes(
            response.question_id
          )
        ) {
          return true;
        }
      }

      return false;
    }),
    future_activities: data[0]?.question_response.filter((response) => {
      for (const style in round2_ques.eng_comm.future_activities) {
        if (
          round2_ques.eng_comm.future_activities.includes(response.question_id)
        ) {
          return true;
        }
      }

      return false;
    }),
    feeling_about_english: data[0]?.question_response.filter((response) => {
      for (const style in round2_ques.eng_comm.feeling_about_english) {
        if (
          round2_ques.eng_comm.feeling_about_english.includes(
            response.question_id
          )
        ) {
          return true;
        }
      }

      return false;
    }),
    eng_presentation: data[0]?.question_response.filter((response) => {
      for (const style in round2_ques.eng_comm.eng_presentation) {
        if (
          round2_ques.eng_comm.eng_presentation.includes(response.question_id)
        ) {
          return true;
        }
      }

      return false;
    }),
    improvement: data[0]?.question_response.filter((response) => {
      for (const style in round2_ques.eng_comm.improvement) {
        if (round2_ques.eng_comm.improvement.includes(response.question_id)) {
          return true;
        }
      }

      return false;
    }),
    // proficiency_needed: data[0]?.question_response.filter((response) => {
    //   for (const style in round2_ques.eng_comm.proficiency_needed) {
    //     if (
    //       round2_ques.eng_comm.proficiency_needed.includes(response.question_id)
    //     ) {
    //       return true;
    //     }
    //   }

    //   return false;
    // }),
    way_to_improve: data[0]?.question_response.filter((response) => {
      for (const style in round2_ques.eng_comm.way_to_improve) {
        if (
          round2_ques.eng_comm.way_to_improve.includes(response.question_id)
        ) {
          return true;
        }
      }

      return false;
    }),
  };

  let personalityTestRes = data[0]?.question_response.filter((response) => {
    for (const style in round2_ques.personality_test) {
      if (round2_ques.personality_test.includes(response.question_id)) {
        return true;
      }
    }

    return false;
  });

  return (
    <div>
      <div className="w-full rounded-md bg-white ">
        <div className="w-full flex flex-wrap py-3">
          {menus.map((e) => {
            return (
              <div
                className={
                  selectedMenus === e
                    ? "p-2 px-2 text-sm text-blue-500 cursor-pointer border-b-4 border-blue-500"
                    : "text-sm cursor-pointer p-2 px-2 hover:border-b-4 border-blue-300"
                }
                onClick={() => {
                  setSelectedMenus(e);
                }}
              >
                {e}
              </div>
            );
          })}
        </div>
        <div>
          {(selectedMenus === menus[0] && <EnglishComm data={engComm} />) ||
            (selectedMenus === menus[1] && <SkilAudit data={skillAuditRes} />) ||
            (selectedMenus === menus[2] && (
              <LearningStyle data={learningStyleRes} />
            )) ||
            (selectedMenus === menus[3] && (
              <PersonalityTest data={personalityTestRes} />
            )) ||
            (selectedMenus === menus[4] && <GoalSetting data={goalSetting} />)}
        </div>
      </div>
    </div>
  );
}
