import axios from "axios";

const API_URL = `${process.env.REACT_APP_HOST}/file/`

const uploadFile = (file,fileParent) => {
    return axios.post(API_URL + fileParent + "/create",file);
}

const getFile = async (file_id) => {
  try {
    console.log(file_id);
    const response = await axios.get(API_URL + file_id);
    return response; // This will return the response to where getFile is called
  } catch (error) {
    console.error('Error fetching file:', error);
    // Handle or log the error based on your application's requirements
    // You might want to return a default value or null, for instance
    return null; // Or any other error handling mechanism
  }
}


const uploadImage = async (formData, onUploadProgress, cancelToken) => {
    try {
      const response = await axios.post(API_URL + "uploadImage", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
        cancelToken,
      });
  
      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Upload cancelled.");
      } else {
        console.log(error);
        throw new Error("An error occurred while uploading the image.");
      }
    }
  };

const FileService = {
    uploadFile,
    getFile,
    uploadImage
}

export default FileService;