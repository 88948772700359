import React from "react";
import ReactQuillParser from "../../../ReactQuillComponent/ReactQuillParser";

export default function Goals({ data }) {
  return (
    <div className=" rounded-lg">
      <div className="w-full rounded-t-lg bg-amber-500 text-white text-md font-semibold p-2 px-3">
        Goals
      </div>
      <div
        className=" rounded-b-lg border-b-2 border-x-2 bg-white custom-scrollbar p-3"
        style={{ height: "350px", overflowY: "auto" }}
      >
        {(data?.question_type === "vmti" && (
          <div className="">
            {data?.response.map((e) => {
              return <div className="text-sm p-2  pt-2 flex ">{e?.value}</div>;
            })}
          </div>
        )) ||
          (data?.question_type === "sa" && (
            <div>
              <div className="text-sm p-2 ">{data.response[0]?.value}</div>
            </div>
          )) ||
          (data?.question_type === "la" && (
            <div>
              <div className="text-sm p-2 ">{data.response[0]?.value}</div>
            </div>
          )) ||
          (data?.question_type === "ebq" && (
            <div>
              <ReactQuillParser content={data.response[0]?.value?data.response[0]?.value:""}  />
            </div>
          ))}
      </div>
    </div>
  );
}
