import React from "react";

export default function Introduction({ data }) {
  return (
    <div className="  rounded-lg ">
      <div className="w-full rounded-t-lg  bg-sky-500 text-white text-lg font-semibold  p-2 px-3">
        Introduction
      </div>
      <div
        className="text-sm p-2 rounded-b-lg border-b-2 border-x-2   bg-white"
        style={{ height: "200px", overflowY: "auto" }}
      >
        {data?.response[0]?.value}
      </div>
    </div>
  );
}
