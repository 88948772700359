import axios from "axios";
import {toast} from "react-toastify"

const API_URL = `${process.env.REACT_APP_HOST}/auth/`;

const register = (fname, lname, username, email, password, phone_number, tnc_accepted, is_underage,parent_email, parent_phone_number, parent_name, roles, expert_type) => {
  console.log(fname, lname, username, email, password, phone_number, tnc_accepted, is_underage,parent_email, parent_phone_number, parent_name, roles, expert_type )
  return axios.post(API_URL + "signup", {fname, lname, username, email, password, phone_number, tnc_accepted, is_underage,parent_email, parent_phone_number, parent_name, roles, expert_type });
};

const login = async (username, password) => {
  try {
    console.log(API_URL + "signin")
    const response = await axios.post(API_URL + "signin", {
      username,
      password,
    });

    if (response.data.username) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }

    console.log(response)

    return response.data;
  } catch (error) {
    // Handle error as you see fit
    console.error("An error occurred during the login process:", error);
    throw error; // or return some error data
  }
};


const logout = async () => {
  localStorage.removeItem("user");
  return axios.post(API_URL + "signout").then((response) => {
    return response.data;
  });
};

const getCurrentUser = async () => {
  return JSON.parse(localStorage.getItem("user"));
};

const generateOtp = async (email, fname, parent_email, is_underage,type) => {
  console.log("email at service for OTP generation:",email);
  return axios.post(API_URL + "generateOtp",{email, fname, parent_email, is_underage, type}).then((response) => {
    return response.data;
  }).catch((err)=>{
    toast.error("Error Sending OTP. Please re-evaluate your details.")
  })
}

const verifyOtp = async (email,otp) => {
  return axios.post(API_URL + "verifyOtp", {email,otp}).then((response) => {
    return response.data;
  });
}

const updatePassword = async (email, old_password, new_password) => {
  console.log("email at service for password update:",email);
  return axios.post(API_URL + "updatePassword",{email, old_password, new_password}).then((response) => {
    return response.data;
  });
}

const selectRole = async ({ role, user_id }) => {
  try {
    const response = await axios.post(API_URL + "selectRole", {
      role,
      user_id
    });

    if (response.data.user_group) {
      let user = JSON.parse(localStorage.getItem("user"));
      user.user_group = response.data.user_group;
      user.user_group_id = response.data.user_group_id;
      localStorage.setItem("user", JSON.stringify(user));
      user = JSON.parse(localStorage.getItem("user"));
    }

    return response.data;
  } catch (error) {
    // Handle error
    console.error(error);
    throw error;
  }
};


const roleRegistration = async (formData) => {
  console.log("formData at service for role registration:",formData);
  return axios.post(API_URL + "roleRegistration",formData)
}

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  generateOtp,
  verifyOtp,
  updatePassword,
  selectRole,
  roleRegistration
}

export default AuthService;