import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

import { login, logout, setAuthState } from "../slices/auth";
import { clearMessage } from "../slices/message";
import { generateOtp } from "../slices/auth";
import AuthService from "../services/auth.service";

import Logo from "../../assets/images/new-logo.png";
import logo from "../../assets/images/login-img.jpg";

import OtpVerification from "./OtpVerification";

const Login = () => {
  let navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [isOtpVerified, setIsOtpVerified] = useState(
    AuthService.getCurrentUser()
      ? AuthService.getCurrentUser().otp_verified
      : false
  );
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [email, setEmail] = useState("");

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const initialValues = {
    username: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("This field is required!"),
    password: Yup.string().required("This field is required!"),
  });

  const sendOtp = (email) => {
    dispatch(generateOtp({ email }))
      .unwrap()
      .then(() => {
        // setSuccessful(true);
        toast.success("OTP Sent Successfully. Might Take a minute to be delivered.")
      })
      .catch(() => {
        // setSuccessful(false);
      });
  };

  const handleLogin = (formValue) => {
    const { username, password } = formValue;
    setLoading(true);

    dispatch(login({ username, password }))
      .unwrap()
      .then((res) => {
        // console.log(res.user);
        toast.success("Login successful!");
        setEmail(res.user.email);
        setIsOtpVerified(res.user.otp_verified);
        if (user && !user.otp_verified) {
          toast.warning("Please Verify your Email", {
            autoClose: 5000, // Auto close the toast after 5000 milliseconds (5 seconds)
            position: toast.POSITION.TOP_CENTER, // Display position
            closeButton: true, // Enable close button
          });
        }
        if (res.user.otp_verified) navigate("/select-role");
      })
      .catch(() => {
        toast.error("Login failed!");
        setLoading(false);
      });
  };

  const handleOtpVerified = async (isVerified) => {
    setIsOtpVerified(isVerified);
    if (isVerified) {
      const currentUser = await AuthService.getCurrentUser();
      if (currentUser) {
        // Await the dispatch of setAuthState action
        dispatch(setAuthState({
          isLoggedIn: true,
          user: currentUser
        }));
        navigate("/"); // Redirect after state update
      }
    }
  };

  if (isLoggedIn && isOtpVerified) {
    // return <Navigate to="/" />;
    if (user.user_group) {
      return <Navigate to="/" />;
    } else {
      return <Navigate to="/select-role" />;
    }
  }
  const showVerificationWarning = () => {};

  return (
    <>
      <div className="w-full poppins">
        <div className="w-full grid grid-cols-12">
          <div className="col-span-12 md:col-span-6">
            <div className=" max-w-lg mx-auto flex justify-center items-start flex-col h-[100vh]">

            {!isLoggedIn ? (
              <div className="card border-0 w-full">
                <div className="p-6 card-body">
                  <Link to="/" className>
                    <img
                      loading="lazy"
                      src={Logo}
                      alt="Logo"
                      className="w-96 h-12 object-contain"
                    />
                  </Link>
                  <div className="ml-3">

                  <div className="mb-4 mt-3">
                    <h3 className="mb-1 fw-bold">Sign in</h3>
                    <span>Enter your email and password to sign in</span>
                  </div>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleLogin}
                  >
                    {({ errors, touched }) => (
                      <Form>
                        <div className="form-group  mb-2">
                          <div htmlFor="username" className="">
                            Username/Email <span className="text-red-500">*</span>
                          </div>
                          <Field
                            name="username"
                            type="text"
                            className={
                              "form-control" +
                              (errors.username && touched.username
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="username"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group">
                          <div htmlFor="password" className="">
                            Password <span className="text-red-500">*</span>
                          </div>
                          <Field
                            name="password"
                            type="password"
                            className={
                              "form-control" +
                              (errors.password && touched.password
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="my-3 col-lg-12 col-md-12">
                          <div className="d-md-flex justify-content-between align-items-center">
                            <div className="mb-3 mb-md-0">
                              <div className="form-check form-switch">
                                <input
                                  type="checkbox"
                                  id="formBasicCheckbox"
                                  className="form-check-input"
                                />
                                <label title="" className="form-check-label">
                                  Remember me
                                </label>
                              </div>
                            </div>
                            <a href="/forgot-password">Forgot your password?</a>
                          </div>
                        </div>
                        <div className="bg-blue">
                          <button
                            type="submit"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded"
                            onClick={showVerificationWarning}
                          >
                            <span>Login</span>
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  <span className="justify-content-center">
                    Don't have an account?{" "}
                    <a className="ml-2 font-semibold cursor-pointer underline text-xl text-blue-500" href="/sign-up">
                      Sign up
                    </a>
                  </span>
                  </div>
                </div>
                {message && (
                  <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                      {message}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <>
                {!isOtpVerified && isOtpSent ? (
                  <>
                    <OtpVerification email={user.email} redirectLocation="/" setIsOtpVerified={handleOtpVerified} />
                  </>
                ) : (
                  <>
                    {user && !user.otp_verified ? (
                      <>
                        <div>
                          <div className="">
                            <button
                              type="button"
                              className="  text-slate-500 mx-10 text-sm bg-slate-100 hover:bg-slate-200 p-2 rounded-full"
                              onClick={() => {
                                logOut();
                                navigate("/sign-in");
                              }}
                            >
                              <FontAwesomeIcon icon={faAngleLeft} /> Back
                            </button>
                            <div className="mx-10 mt-4">
                              <a href="/">
                                <img
                                  loading="lazy"
                                  src={Logo}
                                  alt="logo"
                                 
                      className="w-96 h-12 object-contain"
                                />
                              </a>
                            </div>

                            <div className="mt-4 mx-10 text-lg md:text-xl lg:text-2xl font-semibold text-dark">
                              Please verify the Email
                            </div>
                            <div className="text-sm mx-10  text-slate-400 mt-2">
                              Press the verify button to send an OTP
                            </div>
                            <div className="mx-10 mt-4">
                              <button
                                id="sendOtp"
                                name="sendOtp"
                                type="button"
                                className="p-2 text-white text-sm bg-blue-600 hover:bg-blue-700 rounded-md shadow-md"
                                onClick={() => {
                                  sendOtp(user.email);
                                  setIsOtpSent(true);
                                }}
                              >
                                Send OTP
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <Navigate to={user.user_group ? "/" : "/select-role"} />
                    )}
                  </>
                )}
              </>
            )}
            </div>
          </div>
          <div className="col-span-12 md:col-span-6">
            <div className="h-[100vh] w-full flex justify-end items-center">
              <img
                loading="lazy"
                src={logo}
                alt="Login"
                className="w-fit h-full object-contain"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
