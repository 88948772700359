import React from "react";

import landing from "../../../../assets/recruitment-images/landing.png";

export default function LandingSection({screenSize}) {
  const data = {
    title: "Embark on a transformative journey with HPDSx Engine",
    sub: "Creating Futuristic system for India’s bright future",
    message:"HPDSx - Your path to Action, Success and Happiness"
  };
  return (
    <div className=" poppins">
      <div className={screenSize==="xl"?" max-w-screen-xl mx-auto p-10 pt-20":" max-w-screen-xl mx-auto p-10 "}>
        <div className="w-full text-full">
          <div className=" grid grid-cols-12 ">
            <div className="col-span-12 md:col-span-6 p-2 h-full">
              <div className="w-full h-full flex justify-center items-start  flex-col">
                
                <div className="text-md md:text-xl lg:text-5xl font-semibold  ">
                  <span className="text-blue-500">Human </span>
                  <span className="text-orange-500">Potential </span>
                  <span className="text-green-500">Development </span>
                  <span className="text-red-500">Science</span>
                </div>
                <div className="mt-4 text-lg md:text-2xl lg:text-3xl">
                  {data.title}
                </div>
                <div className="mt-3 text-lg md:text-xl">
                    {data.sub}
                </div>
                <div className="my-2 bg-green-200 text-green-700 text-sm rounded-full px-2 py-1">
                        Welcome
                </div>
                <div className="text-base mt-2 text-slate-400">
                    {data.message}
                </div>
                <div className=" pt-20">
                    
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-6 p-2 h-full">
              <div className="w-full h-full   ">
                
                <img
                  className=" w-full h-fit object-contain "
                  src={landing}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
