import React from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AutomationService from "../../../services/automation.service";

import ImageUploader from "../../ImageUploader";

export default function RegistrationForm({
  registrationStatus,
  setRegistrationStatus,
  automationId,
  currentUser,
}) {
  const [showImageInput, setShowImageInput] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const [institutes, setInstitutes] = useState();
  const [loading, setLoading] = useState();
  const [loadError, setLoadError] = useState(false);

  const handleError = () => {
    setLoadError(true);
  };

  const handleSetProfileImage = (link) => {
    setProfileImage(link);
  };

  useEffect(() => {
    const getInstitutes = async () => {
      setLoading(true);
      const allInstitutes = await AutomationService.getAllInstitute({
        automation_id: automationId,
      });

      setInstitutes(allInstitutes.data);
      setLoading(false);
    };
    getInstitutes();
  }, [automationId]);

  const [formData, setFormData] = useState({
    institute: "",
    email: "",
    student_id: "",
    profile_image: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Here you can use formData for your submission logic
    const currentInstitute = institutes?.find(
      (e) => e.institute_name === formData?.institute
    )?.student_email_format;
    let breakEmail = formData?.email?.split("@");
    const format = currentInstitute?.split("@");
    let verifyEmail = breakEmail[1] === format[1];
// console.log(verifyEmail)
    if (!formData?.institute) {
      toast.warning("Select the institute!");
    } else if (!formData?.email) {
      toast.warning("Enter your college email!");
    } else if (!verifyEmail) {
      toast.warning("Please Enter your Institute provided Email id");
    } else if (!formData?.student_id) {
      toast.warning("Enter your Roll Number / College Id / Student Id!");
    } else if (!profileImage) {
      toast.warning("Please upload your profile image");
    } else {
      let institute_id = institutes?.find(
        (e) => e.institute_name === formData?.institute
      )?.institute_id;
      const submitData = {
        fname: currentUser.fname,
        lname: currentUser.lname,
        email: formData.email,
        institute: formData.institute,
        profile_image: formData.profile_image,
        user_id: currentUser.user_id,
        student_id: formData.student_id,
      };

      const userRegistered = await AutomationService.registerUserForRecruitment(
        {
          automation_id: automationId,
          institute_id: institute_id,
          data: submitData,
        }
      );
      setRegistrationStatus(userRegistered.data?.registration_status);
      const registrationSuccessful = userRegistered.data?.registration_status;

      if (registrationSuccessful) {
        // Retrieve and parse the user object
        let user = localStorage.getItem("user"); // Replace "user" with the actual key if different
        user = JSON.parse(user);

        // Update the profile picture
        user.profile_image = userRegistered.data?.profile_image;

        // Save the updated object back to localStorage
        localStorage.setItem("user", JSON.stringify(user));

        // Show a success toast notification
        toast.success("Registration successful!");
      } else {
        // Show an error toast notification if registration fails
        toast.error("Registration failed. Please try again.");
      }
    }
    // Add your form submission logic here
  };
  return (
    <>
      {loading ? (
        <> loading </>
      ) : (
        <div className="rounded-lg border-2 border-blue-500 shadow-md my-8">
          <div class="px-4 py-5 bg-white space-y-6 sm:p-6 rounded-lg">
            <div class="md:col-span-1">
              <div class="">
                <h3 class="text-lg font-bold leading-6 text-gray-900">
                  Your Career Journey - Potential Identification
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  Please enter the details to start the Potential Identification
                  process
                </p>
              </div>
            </div>

            <div className="my-2">
              <div className="block text-sm font-bold text-gray-700 mb-1">
                Institute
              </div>
              <div className="m-0">
                <select
                  name="institute"
                  id="institute"
                  value={formData.institute}
                  onChange={handleInputChange}
                  className="shadow-sm border border-1 p-2 focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                >
                  <option value="">Select an Institute</option>
                  {institutes?.map((option, index) => (
                    <option key={index} value={option?.institute_name}>
                      {option?.institute_name}
                    </option>
                  ))}
                </select>
                {/* <input
              id="institute"
              type="text"
              name="institute"
              value={formData.institute}
              onChange={handleInputChange}
              className="shadow-sm border border-1 p-2 focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="e.g. IIIT Bhubaneswar"
              required
            /> */}
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Profile Image*
              </label>
              <div className="mt-1 flex items-center">
                <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                  {profileImage ? (
                    <>
                      {loadError ? (
                        <>
                          <svg
                            className="h-full w-full text-gray-300"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                          </svg>
                        </>
                      ) : (
                        <img src={profileImage} />
                      )}
                    </>
                  ) : (
                    <svg
                      className="h-full w-full text-gray-300"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  )}
                </span>
                <button
                  type="button"
                  className="ml-5 bg-green-100 py-2 px-3 border border-gray-300 rounded-md shadow-sm hover:bg-blue  -500 hover:text-white text-sm leading-4 font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                  onClick={() => {
                    setShowImageInput(!showImageInput);
                  }}
                >
                  Change
                </button>
              </div>
            </div>
            {showImageInput && (
              <>
                <ImageUploader
                  onImageUpload={(imageData) => {
                    const data = {
                      ...formData,
                      profile_image: imageData?.filename,
                    };
                    setFormData(data);

                    handleSetProfileImage(imageData?.imageLink);
                    setShowImageInput(!showImageInput);
                  }}
                  purpose={"profileImage"}
                />
              </>
            )}
            <div>
              <div className="block font-bold text-sm text-gray-700 mt-1">
                Institute Email
              </div>
              <div className="">
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="shadow-sm p-2 border border-1 focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="e.g. b419041@iit-xyz.ac.in"
                  required
                />
              </div>
              <p className="mt-2 text-sm text-gray-500">
                Enter the email provided by your institute
              </p>
            </div>
            <div>
              <div className="block text-sm font-medium text-gray-700 mt-1">
                Roll Number / College Id / Student Id
              </div>
              <div className="mt-1">
                <input
                  id="student_id"
                  name="student_id"
                  value={formData.student_id}
                  onChange={handleInputChange}
                  className="shadow-sm p-2 border border-1 focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="e.g. A319044"
                  required
                />
              </div>
            </div>
          </div>
          <div className="px-4 py-3 bg-blue-500 text-white text-right sm:px-6">
            <button
              type="submit"
              onClick={handleSubmit}
              className="inline-flex justify-center py-2 mx-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </>
  );
}
