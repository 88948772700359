import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useSelector } from 'react-redux';
import FileService from '../services/file.service';
import FileUploader from './FileUploader';
import ReactQuillParser from "../components/ReactQuillComponent/ReactQuillParser"
import { CustomQuillEditor } from './ReactQuillComponent/ReactQuill';
import AssessmentService from '../services/assessment.service';
import {toast} from "react-toastify"

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}


const MCQ = ({question, questionIndex, questionResponse, setQuestionResponse, questionNo, resetTrigger}) => {
  const [curQuestionResponse, setCurQuestionResponse] = useState(questionResponse.find(
    (element) => {
      return element.question_id === question.question_id
    }
  ))

  useEffect(() => {
    // Find the response for the current question and update the state
    const updatedResponse = questionResponse.find(element => element.question_id === question.question_id);
    setCurQuestionResponse(updatedResponse || null); // or a default state if no response is found
  }, [resetTrigger]);
  
    
    return (<>
      <div className='py-3'>
          {
            question.question_options.map(
              (option,optionIndex) => {
                const inputId = `question-${questionNo + questionIndex} option ${option.option_id}`;
                return(
                  <div className='' key={optionIndex}>
                    <div className='form-check'>
                      <input className='form-check-input' type='radio' 
                        name={'question-'+`${questionNo + questionIndex}`}
                        id={inputId}
                        value={option.option_value}
                        checked={
                          curQuestionResponse?.response?.[0]?.value == option.option_value
                        }
                        onPaste={(e) => {
                          e.preventDefault();
                          toast.info('Pasting is not allowed.');
                        }}
                        onChange={
                            (e)=>{
                                if(setQuestionResponse){
                                    let temp = [...questionResponse];
                                    let index = temp.findIndex(
                                      (element) => {
                                        return element.question_id === question.question_id
                                      }
                                    )
    
                                    temp[index] = {
                                      ...temp[index],
                                      response: [{ 
                                        id: option.option_id,
                                        res_type: "option",
                                        value: e.target.value 
                                      }],
                                    };
                                    setQuestionResponse(temp);

                                    setCurQuestionResponse(temp[index])
                                  }
                            }
                          }
                        disabled={!setQuestionResponse?true:false}  
                      />
                      <label className='form-check-label' 
                        htmlFor={inputId}
                      >
                        <span className='text-normal'>
                          <span className='text-sky-800'>
                            {option.option_value}
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                )
              }
            )
          }
        </div>
    </>)
}

const TrueFalse = ({question, questionIndex, questionResponse, setQuestionResponse, questionNo, resetTrigger}) => {
  const [curQuestionResponse, setCurQuestionResponse] = useState(questionResponse.find(
    (element) => {
      return element.question_id === question.question_id
    }
  ))

  useEffect(() => {
    // Find the response for the current question and update the state
    const updatedResponse = questionResponse.find(element => element.question_id === question.question_id);
    setCurQuestionResponse(updatedResponse || null); // or a default state if no response is found
  }, [resetTrigger]);
  
  return(<>
    <div className='py-3'>
        {
          question.question_options.map(
            (option,optionIndex) => {
              return(
                <div className=''>
                  <div className='form-check'>
                    <input className='form-check-input' type='radio' name={
                      'question '+`${questionNo + questionIndex}`
                    }
                    onPaste={(e) => {
                      e.preventDefault();
                      toast.info('Pasting is not allowed.');
                    }}
                      id={
                        'question '+`${questionNo + questionIndex}`+' option '+ optionIndex
                      }
                      value={option.option_value}
                      checked={
                        curQuestionResponse?.response?.[0]?.value === option.option_value
                      }
                      onChange={(e)=>{
                        if(setQuestionResponse){
                            let temp = [...questionResponse];
                            let index = temp.findIndex(
                            (element) => {
                                return element.question_id === question.question_id
                            }
                            )

                            temp[index] = {
                              ...temp[index],
                              response: [{
                                id: option.option_id,
                                res_type: "option",
                                value: e.target.value
                              }]
                            }
                            setQuestionResponse(temp);
                            setCurQuestionResponse(temp[index])
                        }
                      }}
                      disabled={!setQuestionResponse?true:false}
                    />
                    <label className='form-check-label' htmlFor={
                      'question '+`${questionNo + questionIndex}`+' option '+optionIndex
                    }>
                      <span className='text-normal'>
                        <span className='text-sky-800'>
                          {option.option_value}
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              )
            }
          )
        }
      </div>
  </>)
}

const MCA = ({question, questionIndex, questionResponse, setQuestionResponse, questionNo, resetTrigger}) => {
  const [curQuestionResponse, setCurQuestionResponse] = useState(questionResponse.find(
    (element) => {
      return element.question_id === question.question_id
    }
  ))

  useEffect(() => {
    // Find the response for the current question and update the state
    const updatedResponse = questionResponse.find(element => element.question_id === question.question_id);
    setCurQuestionResponse(updatedResponse || null); // or a default state if no response is found
  }, [resetTrigger]);
  
  return(<>
    <div className='py-3'>
        {
          question.question_options.map(
            (option,optionIndex) => {
              return(
                <div className=''>
                  <div className='form-check'>
                    <input
                      onPaste={(e) => {
                        e.preventDefault();
                        toast.info('Pasting is not allowed.');
                      }}
                     className='form-check-input' 
                     type='checkbox' 
                     name={'question '+`${questionNo + questionIndex}`+' option '+optionIndex}
                     id={'question '+`${questionNo + questionIndex}`+' option '+optionIndex}
                     value={option.option_value}
                     checked={curQuestionResponse?.response?.find((element) => {return element?.value === option.option_value}) ? true : false}
                     onChange={(e)=>{
                      if(setQuestionResponse){
                          let temp = [...questionResponse];
                          let index = temp.findIndex(
                            (element) => {
                              return element.question_id === question.question_id
                            }
                          )

                          if(e.target.checked){
                            temp[index] = {
                              ...temp[index],
                              response: [
                                ...temp[index].response,
                                {
                                  id: option.option_id,
                                  res_type: "option",
                                  value: e.target.value
                                }
                              ]
                            }
                          }else{
                            temp[index] = {
                              ...temp[index],
                              response: [
                                ...temp[index].response.filter(
                                  (element) => {
                                    return element.value !== e.target.value
                                  }
                                )
                              ]
                            }
                          }
                          setQuestionResponse(temp);
                          setCurQuestionResponse(temp[index])
                        }
                     }}
                    />
                    <label className='form-check-label' htmlFor={
                      'question '+`${questionNo + questionIndex}`+' option '+optionIndex
                    }>
                      <span className='text-normal'>
                        <span className='text-sky-800'>
                          {option.option_value}
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              )
            }
          )
        }
      </div>
  </>)
}

const TFNSA = ({question, questionIndex, questionResponse, setQuestionResponse, questionNo, resetTrigger}) => {
  const [curQuestionResponse, setCurQuestionResponse] = useState(questionResponse.find(
    (element) => {
      return element.question_id === question.question_id
    }
  ))

  useEffect(() => {
    // Find the response for the current question and update the state
    const updatedResponse = questionResponse.find(element => element.question_id === question.question_id);
    setCurQuestionResponse(updatedResponse || null); // or a default state if no response is found
  }, [resetTrigger]);
  
  return(<>
    <div className='pt-3'>
        {
          question.question_options.map(
            (option,optionIndex) => {
              return(
                <div className=''>
                  <div className='form-check'>
                    <input className='form-check-input' type='radio' name={
                      'question '+`${questionNo + questionIndex}`
                    } 
                    id={
                      'question '+`${questionNo + questionIndex}` + ' option ' + optionIndex
                    }
                    onPaste={(e) => {
                      e.preventDefault();
                      toast.info('Pasting is not allowed.');
                    }}
                    value={option.option_value}
                    checked={
                      curQuestionResponse.response.find(
                        (element) => {
                          return element.id === option.option_id && element.res_type === "option"
                        }
                      )?.value === option.option_value
                    }
                    onChange={
                      (e)=>{
                          if(setQuestionResponse){
                              let temp = [...questionResponse];
                              let index = temp.findIndex(
                                (element) => {
                                  return element.question_id === question.question_id
                                }
                              )

                              if(index === -1){
                                temp = [
                                  ...temp,
                                  {
                                    question_id: question.question_id,
                                    question_type: question.question_type,
                                    response: [
                                      {
                                        id: option.option_id,
                                        res_type: "option",
                                        value: e.target.value
                                      },
                                      {
                                        id: option.option_id,
                                        res_type: "text",
                                        value: ""
                                      }
                                    ]
                                  }
                                ]
                                setQuestionResponse(temp)
                                setCurQuestionResponse({
                                  question_id: question.question_id,
                                  question_type: question.question_type,
                                  response: [
                                    {
                                      id: option.option_id,
                                      res_type: "option",
                                      value: e.target.value
                                    },
                                    {
                                      id: option.option_id,
                                      res_type: "text",
                                      value: ""
                                    }
                                  ]
                                })
                              }else{
                                temp[index] = {
                                  ...temp[index],
                                  response: [
                                    {
                                      id: option.option_id,
                                      res_type: "option",
                                      value: e.target.value
                                    },{
                                      id: option.option_id,
                                      res_type: "text",
                                      value: ""
                                    }]
                                }
  
                                setQuestionResponse(temp);
                                setCurQuestionResponse(temp[index])
                              }

                            }
                      }
                    }
                    disabled={!setQuestionResponse?true:false}
                    />
                    <label className='form-check-label' htmlFor={
                      'question '+`${questionNo + questionIndex}`+' option '+optionIndex
                    }>
                      <span className='text-normal'>
                        <span className='text-sky-800'>
                          {option.option_value}
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              )
            }
          )
        }
      </div>
      {
        question.question_options.find(
          (option) => {
            return option.option_value === curQuestionResponse.response.find(
              (element) => {
                return element.res_type === "option"
              }
            )?.value
          }
        )  ? (<>
          <div className='pb-2'>
            <span className='heading-sub '>
              <span className='text-sky-800'>
                Explain:
              </span>
            </span>
          </div>
          <div className=''>
            <div className="">
              <div className="relative mb-3 xl:w-full" >
                <textarea
                  onPaste={(e) => {
                    e.preventDefault();
                    toast.info('Pasting is not allowed.');
                  }}
                  className="peer block min-h-[auto] w-full rounded  border-2 border-blue-500 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none text-sky-800 dark:placeholder:text-neutral-200 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"                  id={
                    'question '+`${questionNo + questionIndex}`+' explain'
                  }
                  value={
                    curQuestionResponse.response[1].value
                  }
                  onChange={
                      (e) => {
                          if(setQuestionResponse){
                              const index = questionResponse.findIndex(
                                (item) => item.question_id === question.question_id
                              )
                              let temp = [...questionResponse]
                              temp[index] = {
                                ...temp[index],
                                response: [
                                  ...temp[index].response.filter(
                                    (element) => {
                                      return element.res_type !== "text"
                                    }
                                  ),
                                  {
                                    id: question.question_options.find(
                                      (option) => {
                                        return option.option_value === curQuestionResponse.response.find(
                                          (element) => {
                                            return element.res_type === "option"
                                          }
                                        )?.value
                                      }
                                    ).option_id,
                                    res_type: "text",
                                    value: e.target.value
                                  }
                                ]
                              }
                              setQuestionResponse(temp)
                              setCurQuestionResponse(temp[index])
                            }
                      }
                    }
                  disabled={!setQuestionResponse?true:false}
                  placeholder="Your message"></textarea>
                <label
                  htmlFor="exampleFormControlTextarea1"
                  className="pointer-events-none absolute top-0 left-3 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-neutral-200"
                  ></label>
              </div>
            </div>
          </div>
        </>):(<></>)
      }
  </>)
}

const LA = ({ question, questionIndex, questionResponse, setQuestionResponse, questionNo, resetTrigger }) => {
  const [inputValue, setInputValue] = useState(() => {
    const existingResponse = questionResponse.find(element => element.question_id === question.question_id);
    return existingResponse ? existingResponse.response[0]?.value : '';
  });
  const textareaRef = useRef(null);

  useEffect(() => {
    textareaRef.current.style.height = "auto";
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight + 2}px`;
  }, [inputValue]);

  useEffect(() => {
    const existingResponse = questionResponse.find(element => element.question_id === question.question_id);
    setInputValue(existingResponse?.response[0]?.value || '');
  }, [resetTrigger, questionResponse, question.question_id]);
  

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);

    const responseIndex = questionResponse.findIndex(element => element.question_id === question.question_id);

    if (responseIndex >= 0) {
      setQuestionResponse(prevResponse => prevResponse.map((resp, idx) => 
        idx === responseIndex 
          ? { ...resp, response: [{ id: 0, res_type: "text", value: newValue }] }
          : resp
      ));
    } else {
      setQuestionResponse(prevResponse => [
        ...prevResponse,
        { question_id: question.question_id, question_type: question.question_type, response: [{ id: 0, res_type: "text", value: newValue }] }
      ]);
    }
  };

  return (
    <div className='py-3'>
      <div className="relative mb-3 xl:w-full">
        <textarea
          onPaste={(e) => {
            e.preventDefault();
            toast.info('Pasting is not allowed.');
          }}
          ref={textareaRef}
          className="peer block min-h-[auto] w-full rounded  border-2 border-blue-500 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none text-sky-800 dark:placeholder:text-neutral-200 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"          id={`question-${questionNo + questionIndex}`}
          name={`question-${questionNo + questionIndex}`}
          value={inputValue}
          onChange={handleInputChange}
          disabled={!setQuestionResponse}
          placeholder="Your response"
        />
      </div>
    </div>
  );
};

const SA = ({ question, questionIndex, questionResponse, setQuestionResponse, questionNo, resetTrigger }) => {
  const [inputValue, setInputValue] = useState(() => {
    const existingResponse = questionResponse.find(element => element.question_id === question.question_id);
    return existingResponse ? existingResponse.response[0]?.value : '';
  });

  useEffect(() => {
    const existingResponse = questionResponse.find(element => element.question_id === question.question_id);
    setInputValue(existingResponse?.response[0]?.value || '');
  }, [resetTrigger, questionResponse, question.question_id]);
  

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);

    const responseIndex = questionResponse.findIndex(element => element.question_id === question.question_id);

    if (responseIndex >= 0) {
      setQuestionResponse(prevResponse => prevResponse.map((resp, idx) => 
        idx === responseIndex 
          ? { ...resp, response: [{ id: 0, res_type: "text", value: newValue }] }
          : resp
      ));
    } else {
      setQuestionResponse(prevResponse => [
        ...prevResponse,
        { question_id: question.question_id, question_type: question.question_type, response: [{ id: 0, res_type: "text", value: newValue }] }
      ]);
    }
  };

  return (
    <div className='py-3'>
      <div className="relative mb-3 xl:w-full">
        <input
          onPaste={(e) => {
            e.preventDefault();
            toast.info('Pasting is not allowed.');
          }}
          type="text"
          className="peer block min-h-[auto] w-full rounded  border-2 border-blue-500 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none text-sky-800 dark:placeholder:text-neutral-200 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"          id={`question-${questionNo + questionIndex}`}
          name={`question-${questionNo + questionIndex}`}
          value={inputValue}
          onChange={handleInputChange}
          disabled={!setQuestionResponse}
          placeholder="Your message"
        />
      </div>
    </div>
  );
};

const OWFIB = ({ question, questionIndex, questionResponse, setQuestionResponse, questionNo, resetTrigger }) => {
  const [curQuestionResponse, setCurQuestionResponse] = useState(
    questionResponse.find((element) => element.question_id === question.question_id) || { response: [] }
  );

  useEffect(() => {
    const updatedResponse = questionResponse.find(element => element.question_id === question.question_id);
    setCurQuestionResponse(updatedResponse || { response: [] });
  }, [questionResponse, question.question_id, resetTrigger]);

  const handleOptionChange = (option, checked) => {
    let temp = [...questionResponse];
    let index = temp.findIndex((element) => element.question_id === question.question_id);

    if (index === -1) {
      temp.push({
        question_id: question.question_id,
        question_type: question.question_type,
        response: checked ? [{ id: option.option_id, res_type: "option", value: option.option_value }] : []
      });
    } else {
      let updatedResponse = temp[index].response.filter((res) => res.id !== option.option_id);
      if (checked) {
        updatedResponse.push({ id: option.option_id, res_type: "option", value: option.option_value });
      }
      temp[index].response = updatedResponse;
    }

    setQuestionResponse(temp);
    setCurQuestionResponse(temp.find((element) => element.question_id === question.question_id) || { response: [] });
  };

  const handleTextChange = (option, text) => {
    let temp = [...questionResponse];
    let index = temp.findIndex((element) => element.question_id === question.question_id);

    if (index !== -1) {
      let updatedResponse = temp[index].response.filter((res) => res.id !== option.option_id || res.res_type !== "text");
      updatedResponse.push({ id: option.option_id, res_type: "text", value: text });
      temp[index].response = updatedResponse;
    }

    setQuestionResponse(temp);
    setCurQuestionResponse(temp.find((element) => element.question_id === question.question_id) || { response: [] });
  };
  
  return (<>
    <div className='py-3'>
          <ul>
            {
              question.question_options.map((option, index) => {
                return(
                  <>
                    <li key={index}>
                      <div className="">
                        <div className='form-check'>
                            <input className='form-check-input' type='checkbox' name={
                              'question '+`${questionNo + questionIndex}`+' option '+option.option_id
                            }
                            onPaste={(e) => {
                              e.preventDefault();
                              toast.info('Pasting is not allowed.');
                            }}
                            id={
                              'question '+`${questionNo + questionIndex}`+' option '+option.option_id
                            }
                            value={option.option_value}
                            checked={
                              curQuestionResponse.response.find(
                                (element) => {
                                  return element.id === option.option_id
                                }
                              )?.value ? true : false
                            }
                            onChange={(e) => handleOptionChange(option, e.target.checked)}
                            disabled={!setQuestionResponse?true:false}
                          />
                          <label
                            htmlFor={'question '+`${questionNo + questionIndex}`+ " text option"+`${option.option_id}`}
                            className="text-sky-800 dark:text-neutral-200 font-medium"
                            >
                              <span className='text-normal'>
                                <span className='text-sky-800'>
                                  {option.option_value}
                                </span>
                              </span>
                            </label>
                          {
                            curQuestionResponse.response.find(
                              (element) => {
                                return element.id === option.option_id && element.res_type === "option"
                              }
                            )?.value
                             && (<>
                              <div className="relative mb-3 xl:w-full" >
                                <textarea
                                  onPaste={(e) => {
                                    e.preventDefault();
                                    toast.info('Pasting is not allowed.');
                                  }}
                                  className="peer block min-h-[auto] w-full rounded  border-2 border-blue-500 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none text-sky-800 dark:placeholder:text-neutral-200 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"                                  id={'question '+`${questionNo + questionIndex}`+ " text option"+`${option.option_id}`}
                                  name={'question '+`${questionNo + questionIndex}`+ " text option"+`${option.option_id}`}
                                  value={curQuestionResponse.response.find(res => res.id === option.option_id && res.res_type === "text")?.value || ''}
                                  onChange={(e) => handleTextChange(option, e.target.value)}
                                  disabled={!setQuestionResponse?true:false}
                                  rows="3"
                                  placeholder="Your message"></textarea>
                              </div>
                            </>)
                          }
                        </div>
                      </div>
                    </li>
                  </>
                )
              })
            }
          </ul>
        
      </div>
  </>)
}

const SCH = ({question, questionIndex, questionResponse, setQuestionResponse, questionNo, resetTrigger}) => {
  const [curQuestionResponse, setCurQuestionResponse] = useState(questionResponse.find(
    (element) => {
      return element.question_id === question.question_id
    }
  ))

  useEffect(() => {
    // Find the response for the current question and update the state
    const updatedResponse = questionResponse.find(element => element.question_id === question.question_id);
    setCurQuestionResponse(updatedResponse || null); // or a default state if no response is found
  }, [resetTrigger]);
  
  return(<>
    <div className='p-2 flex'>
      <label className='form-check-label pe-2' htmlFor='flexCheckDefault'>
        <span className='text-normal'>
          <span className='text-sky-800'>
            {questionNo + questionIndex}. 
          </span>
        </span>
      </label>
      <div className=''>
        <div className='form-check'>
          <input className='form-check-input border-2' type='checkbox' 
            id={'question '+ `${questionNo + questionIndex}`}
            name={'question '+ `${questionNo + questionIndex}`}
            onPaste={(e) => {
              e.preventDefault();
              toast.info('Pasting is not allowed.');
            }}
            checked={
              curQuestionResponse.response?.[0]?.value === "True" ? true : false
            }
            onChange={
              (e) => {
                if(setQuestionResponse){
                    const index = questionResponse.findIndex(
                      (item) => item.question_id === question.question_id
                    )
                    
                    if(index === -1){
                      setQuestionResponse([
                        ...questionResponse,
                        {
                          question_id: question.question_id,
                          question_type: question.question_type,
                          response: [{
                            id: 0,
                            res_type: "option",
                            value: (e.target.checked)?"True":"False"
                          }]
                        }
                      ])

                      setCurQuestionResponse({
                          question_id: question.question_id,
                          question_type: question.question_type,
                          response: [{
                            id: 0,
                            res_type: "option",
                            value: (e.target.checked)?"True":"False"
                          }]
                        })
                    }else{
                      questionResponse[index] = {
                        ...questionResponse[index],
                        response: [
                          {
                            id: 0,
                            res_type: "option",
                            value: (e.target.checked)?"True":"False"
                          }
                        ]
                      }
                      setCurQuestionResponse(questionResponse[index])
                    }
                  }
              }
            }
            disabled={!setQuestionResponse?true:false}
          />
          <label className='form-check-label' htmlFor='flexCheckDefault'>
            <span className='text-normal'>
              <span className='text-sky-800'>
                {question.question_name}
              </span>
            </span>
          </label>
        </div>
      </div>
    </div>
  </>)
}

const Date = ({question, questionIndex, questionResponse, setQuestionResponse, questionNo, resetTrigger}) => {
  const [curQuestionResponse, setCurQuestionResponse] = useState(questionResponse.find(
    (element) => {
      return element.question_id === question.question_id
    }
  ))

  useEffect(() => {
    // Find the response for the current question and update the state
    const updatedResponse = questionResponse.find(element => element.question_id === question.question_id);
    setCurQuestionResponse(updatedResponse || null); // or a default state if no response is found
  }, [resetTrigger]);
  
  return(<>
    <div className='py-3'>
        <div className="">
          <div className="relative mb-3 xl:w-full" >
            <input
              onPaste={(e) => {
                e.preventDefault();
                toast.info('Pasting is not allowed.');
              }}
              type="date"
              className="peer block w-full rounded border border-1 border-sky-800 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none text-sky-800 dark:placeholder:text-neutral-200 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
              id={'question '+ `${questionNo + questionIndex}`}
              name={'question '+ `${questionNo + questionIndex}`}
              value={
                curQuestionResponse.response?.[0]?.value
              }
              onChange={
                  (e) => {
                      if(setQuestionResponse){
                          const index = questionResponse.findIndex(
                            (item) => item.question_id === question.question_id
                          )
                          let temp = [...questionResponse]
                          temp[index] = {
                            ...temp[index],
                            response: [
                              {
                                id: 0,
                                res_type: "date",
                                value: e.target.value
                              }
                            ]
                          }
                          setQuestionResponse(temp)
                          setCurQuestionResponse(temp[index])
                      }
                  }
                }
              disabled={!setQuestionResponse?true:false}
              />
          </div>
        </div>
      </div>
  </>)
}

const Email = ({question, questionIndex, questionResponse, setQuestionResponse, questionNo, resetTrigger}) => {
  const [curQuestionResponse, setCurQuestionResponse] = useState(questionResponse.find(
    (element) => {
      return element.question_id === question.question_id
    }
  ))

  useEffect(() => {
    // Find the response for the current question and update the state
    const updatedResponse = questionResponse.find(element => element.question_id === question.question_id);
    setCurQuestionResponse(updatedResponse || null); // or a default state if no response is found
  }, [resetTrigger]);
  
  return(<>
    <div className='py-3'>
        <div className="">
          <div className="relative mb-3 xl:w-full" >
            <input
              onPaste={(e) => {
                e.preventDefault();
                toast.info('Pasting is not allowed.');
              }}
              type="email"
              className="peer block w-full rounded border border-1 border-sky-800 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none text-sky-800 dark:placeholder:text-neutral-200 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
              id={'question '+ `${questionNo + questionIndex}`}
              name={'question '+ `${questionNo + questionIndex}`}
              value={
                curQuestionResponse.response?.[0]?.value
              }
              onChange={
                  (e) => {
                      if(setQuestionResponse){
                          const index = questionResponse.findIndex(
                            (item) => item.question_id === question.question_id
                          )
                          let temp = [...questionResponse]
                          temp[index] = {
                            ...temp[index],
                            response: [
                              {
                                id: 0,
                                res_type: "email",
                                value: e.target.value
                              }
                            ]
                          }
                        }
                  }
                }
              disabled={!setQuestionResponse?true:false}
              />
          </div>
        </div>
      </div>
  </>)
}

const VMTI = ({ question, questionIndex, questionResponse, setQuestionResponse, questionNo, resetTrigger }) => {
  const [curQuestionResponse, setCurQuestionResponse] = useState({ response: [] });
  const [currentText, setCurrentText] = useState("");

  useEffect(() => {
    // Update the current question response state when the question changes
    const foundResponse = questionResponse.find(element => element.question_id === question.question_id);
    setCurQuestionResponse(foundResponse || { response: [] });
  }, [question, questionResponse]);

  useEffect(() => {
    // Reset current text input
    setCurrentText("");
  
    // Update the current question response state when the question or reset trigger changes
    const foundResponse = questionResponse.find(element => element.question_id === question.question_id);
    setCurQuestionResponse(foundResponse || { response: [] });
  }, [resetTrigger]);
  

  const addTextResponse = () => {
    if (currentText.trim() !== "") {
      const updatedResponse = [
        ...curQuestionResponse.response,
        {
          id: curQuestionResponse.response.length,
          res_type: "text",
          value: currentText
        }
      ];

      updateQuestionResponse(updatedResponse);
      setCurrentText("");
    }
  };

  const removeTextResponse = (indexToRemove) => {
    const updatedResponse = curQuestionResponse.response.filter((_, index) => index !== indexToRemove);
    updateQuestionResponse(updatedResponse);
  };

  const updateQuestionResponse = (updatedResponse) => {
    setCurQuestionResponse({ ...curQuestionResponse, response: updatedResponse });

    const responseIndex = questionResponse.findIndex(
      item => item.question_id === question.question_id
    );

    if (responseIndex >= 0) {
      setQuestionResponse(prev => prev.map((item, idx) => 
        idx === responseIndex 
          ? { ...item, response: updatedResponse }
          : item
      ));
    } else {
      setQuestionResponse(prev => [
        ...prev,
        { 
          question_id: question.question_id, 
          question_type: question.question_type, 
          response: updatedResponse 
        }
      ]);
    }
  };

  return (
    <>
      <div className='py-3'>
        <div className="relative mb-3 xl:w-full">
          <textarea
            onPaste={(e) => {
              e.preventDefault();
              toast.info('Pasting is not allowed.');
            }}
            className="peer block min-h-[auto] w-full rounded  border-2 border-blue-500 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none text-sky-800 dark:placeholder:text-neutral-200 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"            id={`question-${questionNo + questionIndex}`}
            value={currentText}
            onChange={(e) => setCurrentText(e.target.value)}
            placeholder="Your message"
          ></textarea>
          <div className=" mt-2 w-full flex justify-between items-center">
            <div className="text-sm italic text-slate-600">  
              *Note - This question accept a list as a response, so for all the answers press add to give input
            </div>

            <button
              className=" px-2 py-1  shadow-sm bg-blue-600 text-white cursor-pointer hover:bg-blue-700  rounded-md flex justify-center items-center"
              onClick={addTextResponse}
            >
              <div className="ml-1">+ Add</div>
            </button>
          </div>
        </div>
      </div>
      <div className='py-3'>
        <div className="relative mb-3 xl:w-full">
          <div className="flex flex-wrap">
            {curQuestionResponse.response.map((element, index) => (
              <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-2" key={index}>
                <div className="relative mb-3 xl:w-full flex items-center">
                  <input
                    onPaste={(e) => {
                      e.preventDefault();
                      toast.info('Pasting is not allowed.');
                    }}
                    type="text"
                    className="peer block w-full rounded border border-1 border-sky-800 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none text-sky-800 dark:placeholder:text-neutral-200 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                    id={`question-${questionNo + questionIndex}-text-option-${element.id}`}
                    value={element.value}
                    onChange={(e) => updateResponseValue(index, e.target.value)}
                    disabled={!setQuestionResponse}
                  />
                  <button
                    className='absolute top-1 right-1 ml-2 px-1 py-1 rounded-full shadow-sm bg-red-100 text-red-700 cursor-pointer hover:bg-red-600 hover:text-white'
                    onClick={() => removeTextResponse(index)}
                  >
                    <svg className="w-5 h-5" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" stroke="currentColor" viewBox="0 0 24 24">
                      <path d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );

  function updateResponseValue(index, value) {
    let updatedResponses = curQuestionResponse.response.map((resp, idx) =>
      idx === index ? { ...resp, value: value } : resp
    );

    updateQuestionResponse(updatedResponses);
  }
};

const EditorBasedQ = ({question, questionIndex, questionResponse, setQuestionResponse, questionNo, resetTrigger}) => {
  const [editorContent, setEditorContent] = useState(questionResponse.find(
    (element) => {
      return element.question_id === question.question_id
    }
  )?.response?.[0]?.value)

  useEffect(() => {
    // Update editor content based on the latest question response or reset
    const foundResponse = questionResponse.find(element => element.question_id === question.question_id);
    setEditorContent(foundResponse?.response?.[0]?.value || "");
  }, [resetTrigger]);
  

  return(<>
    <div className='py-3'>
      <div className="">
        <div className="relative mb-3 xl:w-full" >
          {setQuestionResponse ? (<>
            <CustomQuillEditor
              data={editorContent}
              onChange={(value) => {
                if (setQuestionResponse) {
                  const index = questionResponse.findIndex(
                    (item) => item.question_id === question.question_id
                  );
                  let temp = [...questionResponse];
                  temp[index] = {
                    ...temp[index],
                    response: [
                      {
                        id: 0,
                        res_type: "text",
                        value: value
                      }
                    ]
                  };
                  setQuestionResponse(temp);
                  setEditorContent(value);
                }
              }}
              uploadService={AssessmentService.uploadImage}
              purpose={"assessment"}
              disableCopyPaste={true}
            />


          </>) : (
          <div className=' border border-blue-500 rounded-lg'>
            {/* use react quill parser to show the data */}
            <ReactQuillParser
              content={editorContent?editorContent:""}
            />
          </div>)}
        </div>
      </div>
    </div>
  </>)
}

const Upload = ({question, questionIndex, questionResponse, setQuestionResponse, questionNo, resetTrigger}) => {
  const [loading, setLoading] = useState(true)
  const [curQuestionResponse, setCurQuestionResponse] = useState(questionResponse.find(
    (element) => {
      return element.question_id === question.question_id
    }
  ))

  useEffect(() => {
    // Find the response for the current question and update the state
    const updatedResponse = questionResponse.find(element => element.question_id === question.question_id);
    setCurQuestionResponse(updatedResponse || null); // or a default state if no response is found
  }, [resetTrigger]);

  useEffect(()=>{
    const fetchFile = async () => {
      const file_id = questionResponse.find(
        (element) => {
          return element.question_id === question.question_id
      })?.response?.value
      const res = await FileService.getFile(file_id)
      // console.log("Res:",res)
      setLoading(false)
    }
    fetchFile()
  },[curQuestionResponse])

  return(<>
    {loading ? loading : <div className='py-3'>
        <div className="">
            {setQuestionResponse ? (<>
              <div className="flex items-center justify-center w-full">
                <FileUploader
                  uploadService={FileService.uploadFile}
                  onUploadComplete={(file)=>{
                    if(setQuestionResponse){
                      const index = questionResponse.findIndex(
                        (item) => item.question_id === question.question_id
                      )
                      if(index === -1){
                        setQuestionResponse([
                          ...questionResponse,
                          {
                            question_id: question.question_id,
                            question_type: question.question_type,
                            response: [
                              {
                                id: 0,
                                res_type: "file",
                                value: file
                              }
                            ]
                          }
                        ])
                        setCurQuestionResponse({
                          question_id: question.question_id,
                          question_type: question.question_type,
                          response: [
                            {
                              id: 0,
                              res_type: "file",
                              value: file
                            }
                          ]
                        })
                      }else{
                        let temp = [...questionResponse]
                        temp[index] = {
                          ...temp[index],
                          response: [
                            ...temp[index].response,
                            {
                              id: temp[index].response.length,
                              res_type: "file",
                              value: file
                            }
                          ]
                        }
                        setQuestionResponse(temp)
                        setCurQuestionResponse(temp[index])
                      }
                    }
                  }}
                  fileParent={"assessments"}
                  maxNumberOfFiles={10}
                  acceptedTypes={[
                    "image/*",
                    "application/pdf",
                    "application/msword",
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "application/vnd.ms-excel",
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "application/vnd.ms-powerpoint",
                    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                    "text/plain"
                  ]}
                />
              </div>
            </>) : (<>
              <div className="flex items-center justify-center w-full">
                  <FileUploader
                    uploadService={FileService.uploadFile}
                    onUploadComplete={(file)=>{
                      
                    }}
                    existingFile={questionResponse.find(
                      (element) => {
                        return element.question_id === question.question_id
                    })?.response}
                  />
              </div>
            </>)}
        </div>
      </div>}
  </>)
}

const QuestionTypeSelector = ({question, questionIndex, questionResponse, setQuestionResponse, questionNo, resetTrigger}) => {
  switch(question.question_type){
    case 'mcq':  {
      questionNo++;
      
      return(
        <>
          <MCQ
            key={question?.question_id}
            resetTrigger={resetTrigger}
            question={question}
            questionIndex={questionIndex}
            questionResponse={questionResponse}
            setQuestionResponse={setQuestionResponse}
            questionNo={questionNo}
          />
        </>
      )
    };
    case 'tf': {
      questionNo++
      return(
        <>
            <TrueFalse
              key={question?.question_id}
            resetTrigger={resetTrigger}
              question={question}
              questionIndex={questionIndex}
              questionResponse={questionResponse}
              setQuestionResponse={setQuestionResponse}
              questionNo={questionNo}
            />
        </>
      )
    };
    // Multiple choice answers where we can choose multiple options as answer
    case 'mca':  {
      questionNo++
      return(
        <>
          <MCA
            key={question?.question_id}
            resetTrigger={resetTrigger}
            question={question}
            questionIndex={questionIndex}
            questionResponse={questionResponse}
            setQuestionResponse={setQuestionResponse}
            questionNo={questionNo}
          />
        </>
      )
    };
    // True or False with short answer describing the choice
    case 'tfnsa':  {
      questionNo++
      return(
        <>
          <TFNSA
            key={question?.question_id}
            resetTrigger={resetTrigger}
            question={question}
            questionIndex={questionIndex}
            questionResponse={questionResponse}
            setQuestionResponse={setQuestionResponse}
            questionNo={questionNo}
          />
        </>
      )
    };
    // Long Answer type
    case 'la':{
      questionNo++
      return(
        <>
          <LA
            key={question?.question_id}
            resetTrigger={resetTrigger}
            question={question}
            questionIndex={questionIndex}
            questionResponse={questionResponse}
            setQuestionResponse={setQuestionResponse}
            questionNo={questionNo}
          />
        </>
      )
    };
    // Short answer type
    case 'sa':{
      questionNo++
      return(
        <>
          <SA
            key={question?.question_id}
            resetTrigger={resetTrigger}
            question={question}
            questionIndex={questionIndex}
            questionResponse={questionResponse}
            setQuestionResponse={setQuestionResponse}
            questionNo={questionNo}
          />
        </>
      )
    }
    // Options with fill in the blanks
    case 'owfib': {
      questionNo++
      return(
        <>
          <OWFIB
            key={question?.question_id}
            resetTrigger={resetTrigger}
            question={question}
            questionIndex={questionIndex}
            questionResponse={questionResponse}
            setQuestionResponse={setQuestionResponse}
            questionNo={questionNo}
          />
        </>
      )
    };
    // Single checkbox
    case 'sch': {
      questionNo++
      return(
        <>
          <SCH
            key={question?.question_id}
            resetTrigger={resetTrigger}
            question={question}
            questionIndex={questionIndex}
            questionResponse={questionResponse}
            setQuestionResponse={setQuestionResponse}
            questionNo={questionNo}
          />
        </>
      )
    };
    // Date
    case 'date': {
      questionNo++
      return(
        <>
          <Date
            key={question?.question_id}
            resetTrigger={resetTrigger}
            question={question}
            questionIndex={questionIndex}
            questionResponse={questionResponse}
            setQuestionResponse={setQuestionResponse}
            questionNo={questionNo}
          />
        </>
      )
    };
    // E-mail
    case 'email': {
      questionNo++
      return(
        <>
          <Email
            key={question?.question_id}
            resetTrigger={resetTrigger}
            question={question}
            questionIndex={questionIndex}
            questionResponse={questionResponse}
            setQuestionResponse={setQuestionResponse}
            questionNo={questionNo}
          />
        </>
      )
    };
    // Vmti - variable multiple text inputs
    case 'vmti': {
      questionNo++
      return(<>
        <VMTI
          key={question?.question_id}
            resetTrigger={resetTrigger}
          question={question}
          questionIndex={questionIndex}
          questionResponse={questionResponse}
          setQuestionResponse={setQuestionResponse}
          questionNo={questionNo}
        />
      </>)
    }
    // Editor based question
    case 'ebq': {
      questionNo++
      return(<>
        <EditorBasedQ
          key={question?.question_id}
            resetTrigger={resetTrigger}
          question={question}
          questionIndex={questionIndex}
          questionResponse={questionResponse}
          setQuestionResponse={setQuestionResponse}
          questionNo={questionNo}
        />
      </>)
    }
    // Upload
    case 'upload': {
      questionNo++
      return(
        <>
          <Upload
            key={question?.question_id}
            resetTrigger={resetTrigger}
            question={question}
            questionIndex={questionIndex}
            questionResponse={questionResponse}
            setQuestionResponse={setQuestionResponse}
            questionNo={questionNo}
          />
        </>
      )
    };
    // default
    default: {
      return(
        <div>Question Type Not Supported - {question.question_type}</div>
      )
    }
  }
}

const QuestionTypes = ({question, questionIndex, questionResponse, setQuestionResponse, resetTrigger, userAllowedToScore, scores, setScores}) => {
  let questionNo = 0;
  const [marks, setMarks] = useState(scores?.find((score)=>{
    return score.question_id === question.question_id
  })?.score_obtained || 0)
  const {user} = useSelector((state)=>state.auth)
  // console.log(question)

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    const maxValue = question?.question_weight ? question.question_weight : 1

    // Test if newValue is numeric or empty (for backspace/delete)
    if (!newValue || (/^[0-9]+$/.test(newValue) && (newValue>=0) && newValue<=maxValue)) {
      setMarks(newValue);
      // console.log(scores)
      setScores(scores.map((item)=>{
        // console.log(item)
        if(item.question_id === question.question_id){
          const newItem = {
            ...item,
            score_obtained: newValue,
            score_by: user.user_id
          }
          return newItem
        }
        return item
      }))
    }
  };
  
  return(<>
    <div className='p-2'>
      {userAllowedToScore ? (<>
        <div className="flex justify-end items-end">
            <div className="mt-1 relative rounded-md shadow-sm w-auto">
              <input
                onPaste={(e) => {
                  e.preventDefault();
                  toast.info('Pasting is not allowed.');
                }}
                type="text"
                inputMode='numeric'
                name="marks"
                id="marks"
                value={marks}
                onChange={handleInputChange}
                className="peer block w-full rounded border border-1 border-sky-800 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none text-sky-800 dark:placeholder:text-neutral-200 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                placeholder="Enter Score"
              />
              <div className="absolute inset-y-0 right-0 flex items-center">
                <span className="text-gray-500 sm:text-sm pr-3">/ {question?.question_weight ? question.question_weight : 1}</span>
              </div>
            </div>
        </div>
      </>) : (<></>)}
      <div className="bg-white p-6 h-[82vh] overflow-y-auto custom-scrollbar">
        {/* question name */}
        <div className="">
          <span className="text-2xl font-semibold text-sky-800">
            {typeof questionIndex === "number" ? questionNo + questionIndex + 1 + ". " : ""} {question.question_name}
            
          </span>
        </div>

        {/* question description */}
        <div className="mt-4 py-2">
          <span className="text-base text-gray-700">
            {question.question_desc?<ReactQuillParser content={question?.question_desc} />:<></>}
          </span>
        </div>
        
        <div className="mt-6">
          <QuestionTypeSelector
            question={question}
            questionIndex={questionIndex}
            questionResponse={questionResponse}
            setQuestionResponse={setQuestionResponse}
            questionNo={questionNo}
          />
      </div>

      </div>
    </div>
  </>)
}
  
  export default QuestionTypes;