import { useState } from "react";
import AuthService from "../services/auth.service";

import Logo from "../../assets/images/new-logo.png";

const ChangePassword = ({ email_id_passed }) => {
  const [old_password, set_old_password] = useState("");
  const [new_password, set_new_password] = useState("");
  const [confirm_password, set_confirm_password] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem("user"));

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("email at ChangePassword:", currentUser.email);
    if (new_password !== confirm_password) {
      return setError("Passwords do not match");
    }
    try {
      setError("");
      setSuccess("");
      setLoading(true);
      AuthService.updatePassword({
        email: currentUser ? currentUser.email : email_id_passed,
        old_password,
        new_password,
      });
      setSuccess("Password updated successfully");
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  };

  return (
    <div className="container-fluid d-flex flex-column poppins">
      <div
        className="row align-items-center justify-content-center
            min-vh-100"
      >
        <div className="col-12 col-md-8 col-lg-4">
          <div className="card">
            <div className="card-body">
              <a href="/">
                <img
                  loading="lazy"
                  src={Logo}
                  alt="logo"
                  className="rounded mb-3"
                  height={50}
                />
              </a>
              <div className=" mt-4 text-lg md:text-xl lg:text-2xl font-semibold text-dark">
                {currentUser ? "Change Password" : "Forgot Password"}
              </div>
              {error && (
                <div
                  className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <strong className="font-bold">Error!</strong>
                  <span className="block sm:inline">{error}</span>
                </div>
              )}
              {success && (
                <div
                  className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <strong className="font-bold">Success!</strong>
                  <span className="block sm:inline">{success}</span>
                </div>
              )}
              <form onSubmit={handleSubmit}>
                {email_id_passed ? null : (
                  <div className="mb-4 mt-4">
                    <div htmlFor="email" className="">
                      Current Password <span className="text-red-500">*</span>
                    </div>
                    <input
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      type="password"
                      placeholder="Enter Current Password"
                      required
                      value={old_password}
                      onChange={(e) => set_old_password(e.target.value)}
                    />
                  </div>
                )}
                <div className="mb-4 mt-4">
                  <div htmlFor="email" className="">
                      New Password <span className="text-red-500">*</span>
                    </div>
                    <input
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      type="password"
                      placeholder="Enter new Pasword"
                      required
                      value={new_password}
                      onChange={(e) => set_new_password(e.target.value)}
                    />
                </div>
                <div className="mb-4">
                  <div htmlFor="email" className="">
                              Confirm Password <span className="text-red-500">*</span>
                            </div>
                    <input
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      type="password"
                      placeholder="Confirm the password"
                      required
                      value={confirm_password}
                      onChange={(e) => set_confirm_password(e.target.value)}
                    />
                </div>
                {!email_id_passed ? (
                  <div className="w-100 text-center mt-3">
                    <a href="/forgot-password">Forgot Password?</a>
                  </div>
                ) : null}
                <button
                  disabled={loading}
                  className="w-full mt-3 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  Change Password
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
