import React, { useEffect, useState } from "react";
import CandidateEvaluation from "./CandidateEvaluation/CandidateEvaluation";
import HumanIntel from "./HumanIntelligence/HumanIntel";
import AutomationService from "../../../services/automation.service";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

import moment from "moment";
import UserService from "../../../services/user.service";
import AssessmentResponseService from "../../../services/assessment-response.service";
import AssessmentResponse from "../../../pages/User/AssessmentResponse";

export default function MainReport({
  round_id,
  automationId,
  user_id,
  screeSize,
}) {
  console.log(user_id, automationId, round_id, "data params")
  
  const [rounds, setRounds] = useState([]);
  const [userRoundData, setUserRoundData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userRecruitmentData, setUserRecruitmentData] = useState();
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [currentUser, setCurrentUser] = useState();

  const [assessmentResponse, setAssessmentResponse] = useState();

  const [selectedRoundUserDetail, setSelectedRoundUserDetail] = useState();
  const [roundNumber, setRoundNumber] = useState();

  // useEffect(() => {
  //   const getRounds = async () => {
  //     setLoading(true);
  //     const userRecruitmentDataTemp =
  //       await AutomationService.getRegistrationStatus({
  //         user_id: user_id,
  //       });

  //     setUserRecruitmentData(userRecruitmentDataTemp.data.recruitment_data);

  //     //component round data
  //     const components = await AutomationService.getAllComponents({
  //       automation_id: automationId,
  //     });

  //     let selectedCompTemp;
  //     for (let i = 0; i < components.data?.length; i++) {
  //       if (components.data[i]?.component_id == round_id) {
  //         selectedCompTemp = components.data[i];
  //       }
  //     }

  //     setSelectedComponent(selectedCompTemp);
  //     setRounds(components.data);

  //     const user = await UserService.getUser(user_id);
  //     // console.log(user, "Hello");
  //     setCurrentUser(user.data);

  //     const userData = await AutomationService.getSingleUserDetail({
  //       automation_id: automationId,
  //       institute_name:
  //         userRecruitmentDataTemp.data?.recruitment_data?.institute,
  //       user_id: user_id,
  //     });
  //     // console.log(userData.data, "user data");
  //     //finding round detail for the user
  //     for (let i = 0; i < userData?.data?.user_data?.rounds?.length; i++) {
  //       if (userData?.data?.user_data?.rounds[i]?.round == round_id) {
  //         setSelectedRoundUserDetail(userData?.data?.user_data?.rounds[i]);
  //       }
  //     }

  //     if (selectedRoundUserDetail?.response_id) {
  //       const response = await AssessmentResponseService.getAssessmentResponse(
  //         selectedRoundUserDetail.response_id
  //       );
  //       // console.log(response.data, "response")
  //       setAssessmentResponse(response.data);
  //     }

  //     setUserRoundData(userData.data);
  //     setLoading(false);
  //   };
  //   getRounds();
  // }, [automationId, user_id, round_id, selectedRoundUserDetail?.response_id]);

  // useEffect(() => {
  //   const func = () => {
  //     setLoading(true);
  //     if (rounds?.length) {
  //       const sortedRound = rounds.sort((a, b) => a.priority - b.priority);
  //       let roundNumberData = sortedRound?.findIndex(
  //         (e) => e?.component_id === selectedComponent?.component_id
  //       );
  //       setRoundNumber(roundNumberData);
  //     }
  //     setLoading(false);
  //   };
  //   func();
  // }, [rounds, selectedComponent]);

  useEffect(() => {
    const getRounds = async () => {
      setLoading(true);
      try {
        const userRecruitmentDataTemp =
          await AutomationService.getRegistrationStatus({ user_id });
        setUserRecruitmentData(userRecruitmentDataTemp.data.recruitment_data);

        const components = await AutomationService.getAllComponents({
          automation_id: automationId,
        });

        let selectedCompTemp;
        for (let i = 0; i < components.data?.length; i++) {
          // console.log("component data with round",components.data[i]?.component_id, round_id);
          if (components.data[i]?.component_id === parseInt(round_id)) {
            selectedCompTemp = components.data[i];
            break;
          }
        }
        // console.log(selectedCompTemp);

        setSelectedComponent(selectedCompTemp);
        setRounds(components.data);

        if (components.data?.length) {
          const sortedRound = [...components.data].sort(
            (a, b) => a.priority - b.priority
          );
          const roundNumberData = sortedRound.findIndex(
            (e) => e?.component_id === selectedCompTemp?.component_id
          );
          // console.log(roundNumberData);
          setRoundNumber(roundNumberData);
        }

        const user = await UserService.getUser(user_id);
        // console.log(user);
        setCurrentUser(user.data);

        const userData = await AutomationService.getSingleUserDetail({
          automation_id: automationId,
          institute_name:
            userRecruitmentDataTemp.data?.recruitment_data?.institute,
          user_id,
        });
        // console.log(userData.data);

        let selRoundUserDetail = {};

        for (let i = 0; i < userData?.data?.user_data?.rounds?.length; i++) {
          if (
            userData?.data?.user_data?.rounds[i]?.round === parseInt(round_id)
          ) {
            setSelectedRoundUserDetail(userData?.data?.user_data?.rounds[i]);
            selRoundUserDetail = userData?.data?.user_data?.rounds[i];
            break;
          }
        }
        // console.log(selRoundUserDetail);

        if (
          selectedRoundUserDetail?.response_id ||
          selRoundUserDetail.response_id
        ) {
          const response =
            await AssessmentResponseService.getAssessmentResponse(
              selRoundUserDetail.response_id ||
                selectedRoundUserDetail.response_id
            );
          setAssessmentResponse(response.data);
        }

        setUserRoundData(userData.data);
      } catch (error) {
        console.error("Error in fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    getRounds();
  }, [automationId, user_id]);
  // }, [automationId, user_id, round_id, selectedRoundUserDetail?.response_id, selectedComponent]);

  // console.log("rounds", rounds, selectedRoundUserDetail);
  let defaultImage =
    "https://img.freepik.com/free-vector/illustration-businessman_53876-5856.jpg?w=740&t=st=1705223161~exp=1705223761~hmac=1b2cb13a4492ec7357c3e9a42f10e7574d8ae81d9f629ac19909c924aa774c64";

  // console.log(rounds,selectedComponent, "Roundnumber")
  const submissionDate = selectedRoundUserDetail
    ? moment(selectedRoundUserDetail?.submission_time).format(
        "h:mmA, Do MMMM YYYY"
      )
    : "";

  let prevComp = 0;
  let nextComp = 0;
  for (let i = 0; i < rounds?.length; i++) {
    if (selectedComponent?.component_id === rounds[i]?.component_id) {
      prevComp = rounds[i - 1] ? rounds[i - 1] : 0;
      nextComp = rounds[i + 1] ? rounds[i + 1] : 0;
    }
  }
  // console.log("assessment Respose", assessmentResponse);
  // console.log("Round Number", roundNumber);

  return (
    <>
      {loading ? (
        <>loading</>
      ) : (
        <div className="w-full max-w-screen-xl mx-auto pb-10">
          <div>
            <div className="px-3">
              <div className="  rounded-lg mt-4 bg-slate-100 p-3  border border-1">
                <div className=" grid grid-cols-12">
                  <div className="col-span-12 md:col-span-8 flex justify-start items-center">
                    <div className="w-32 h-full flex justify-center items-center ">
                      <img
                        className=" w-24 h-24 rounded-full object-cover"
                        alt=""
                        src={
                          currentUser?.profile_image
                            ? currentUser.profile_image
                            : defaultImage
                        }
                      />
                    </div>
                    <div className="p-2 ">
                      <div className="flex justify-start items-center">
                        <div className="w-full text-lg md:text-xl lg:text-3xl text-dark py-2 mr-3">
                          {currentUser?.fname} {currentUser?.lname}
                        </div>
                        <div
                          className={
                            userRecruitmentData?.status === "Selected"
                              ? "w-fit px-2 py-1 text-xs text-white rounded-md bg-green-500  "
                              : userRecruitmentData?.status === "Ongoing"
                              ? "w-fit  py-1 text-xs text-white rounded-md bg-amber-500  px-2 "
                              : "w-fit  py-1 text-xs text-white rounded-md bg-red-500  px-2 "
                          }
                        >
                          {userRecruitmentData?.status}
                        </div>
                      </div>
                      <div className="w-full text-base font-semibold ">
                        {userRecruitmentData?.institute}
                      </div>
                      <div className="w-full text-sm mt-2 flex justify-start items-center  ">
                        <div className="px-1 ">
                          {userRecruitmentData?.student_id}
                        </div>
                        <div className="text-slate-400 px-1">|</div>
                        <div className="px-1">{userRecruitmentData?.email}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-4">
                    <div className="w-full h-full flex justify-center items-center text-2xl md:text-4xl lg:text-5xl font-semibold mx-4">
                      <span className="text-blue-500">UP</span>
                      <span className="text-green-500">MY</span>
                      <span className="text-amber-400">SKILL</span>
                      {/* <img
                    className="w-full h-full object-cover"
                    alt=""
                    src={checkImg}
                  /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {assessmentResponse ? (
              <div className=" pb-4 pt-2">
                {roundNumber === 0 ? (
                  <CandidateEvaluation data={assessmentResponse} />
                ) : roundNumber === 1 ? (
                  <HumanIntel data={assessmentResponse} />
                ) : roundNumber === 2 ? (
                  <AssessmentResponse
                    assessmentResponseId={assessmentResponse[0]?.assess_res_id}
                  />
                ) : roundNumber === 3 ? (
                  <AssessmentResponse
                    assessmentResponseId={assessmentResponse[0]?.assess_res_id}
                  />
                ) : roundNumber === 4 ? (
                  <AssessmentResponse
                    assessmentResponseId={assessmentResponse[0]?.assess_res_id}
                  />
                ) : roundNumber === 5 ? (
                  <AssessmentResponse
                    assessmentResponseId={assessmentResponse[0]?.assess_res_id}
                  />
                ) : roundNumber === 6 ? (
                  <AssessmentResponse
                    assessmentResponseId={assessmentResponse[0]?.assess_res_id}
                  />
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <>
                <div className="py-20 text-2xl font-semibold bg-white flex justify-center items-center">
                  <div>Yet to Attempt</div>
                </div>
              </>
            )}
          </div>
          {/* ) : (
            <>
            <div className="w-full h-[80vh] flex justify-center items-center">
                <div className="text-3xl font-semibold">
                  Access Denied
                </div>
            </div>
            </>
          )} */}
        </div>
      )}
    </>
  );
}
