/* eslint-disable */
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import moment from "moment";

import { logout } from "../slices/auth";

import Logo from "../../assets/images/new-logo.png";

import { BiBell, BiCalendar, BiHome } from "react-icons/bi";

import { BiSearchAlt } from "react-icons/bi";
import Notifications from "./Notifications";

import {
  getAllNotifications,
  createNotification,
  updateNotification,
  setNotifications,
} from "../slices/notification";

const Navigationbar = () => {
  const [isLearner, setIsLearner] = useState(false);
  const [smallScreenNavShow, setSmallScreenNavShow] = useState(false);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const [notificationDropdown, setNotificationDropdown] = useState(false);
  const [refreshNav, setRefreshNav] = useState(true);

  let navigate = useNavigate();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { notifications } = useSelector((state) => state.notification);
  // console.log(notifications);
  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      dispatch(
        getAllNotifications({
          id: currentUser.user_id,
        })
      );
    }
  }, [dispatch, refreshNav]);

  useEffect(() => {
    if (currentUser) {
      if (currentUser.user_group === "learner") {
        setIsLearner(true);
      }
    } else {
      const currentURL = window.location.href;
      if (currentURL.includes("/become")) {
        // stay where we are
      } else {
        navigate("/");
      }
    }
  }, [currentUser]);

  const expand = "lg";

  const [isOpenForIndividual, setIsOpenForIndividual] = useState(false);
  const dropdownRef = useRef(null);
  const [offering, setOffering] = useState(false);
  const offeringdropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpenForIndividual(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const currentURL = window.location.href;
  return (
    <>
      <nav className="relative px-2  flex justify-between items-center bg-transparent">
        <a className="py-2" href="/">
          <img
            loading="lazy"
            src={Logo}
            className="w-96 h-12 object-contain"
            alt="Logo"
          />
        </a>
        <div className="lg:hidden">
          <button
            className="navbar-burger flex items-center text-blue-600 p-3"
            onClick={() => setSmallScreenNavShow(true)}
          >
            <svg
              className="block h-4 w-4 fill-current"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Mobile menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
            </svg>
          </button>
        </div>
        {isLearner &&
          (window.location.pathname.includes("/home") ||
            window.location.pathname.includes("/offerings/programs") ||
            window.location.pathname.includes("/offerings/assessments") ||
            window.location.pathname.includes("/offerings/events") ||
            window.location.pathname.includes("/offerings/expert-support") ||
            window.location.pathname.includes("/offerings/chapters-clubs") ||
            window.location.pathname.includes("/becomeInstructor") ||
            window.location.pathname.includes("/becomeExpert") ||
            window.location.pathname.includes("/becomeSpeaker") ||
            window.location.pathname.includes("/registration/organization") ||
            window.location.pathname.includes("/registration/instructor") ||
            window.location.pathname.includes("/registration/expert") ||
            window.location.pathname.includes(
              "/registration/eventContributor"
            )) && (
            <div className=" w-full flex flex-row">
              <div className=" mx-2 flex flex-row text-normal w-fit font-bold  rounded-full px-2 py-1 cursor-pointer">
                <Link to="/" className="no-underline text-dark">
                  <div className="mx-1  flex justify-center items-center ">
                    Dashboard
                  </div>
                </Link>
              </div>
              <div className=" mx-2 flex flex-row text-normal font-bold hover:bg-slate-100 rounded-full px-2 py-1 cursor-pointer">
                <div
                  className="mx-1 flex justify-center items-center "
                  onClick={() => {
                    setOffering(!offering);
                  }}
                >
                  Offerings
                </div>
                {offering && (
                  <div
                    ref={offeringdropdownRef}
                    className="absolute mt-8 z-50 py-2 bg-white border border-gray-300 rounded shadow"
                  >
                    <div className="w-full h-full flex justify-center items-start flex-col">
                      <Link
                        to="/offerings/programs"
                        className="no-underline text-dark w-full"
                      >
                        <div className="w-full text-dark text-sm font-normal hover:bg-slate-100 py-2 px-3 cursor-pointer">
                          Programs and Courses
                        </div>
                      </Link>
                      <Link
                        to="/offerings/assessments"
                        className="no-underline text-dark w-full"
                      >
                        <div className="w-full text-dark text-sm font-normal hover:bg-slate-100 py-2 px-3 cursor-pointer">
                          Assessments
                        </div>
                      </Link>
                      <Link
                        to="/offerings/events"
                        className="no-underline text-dark w-full"
                      >
                        <div className="w-full text-dark text-sm font-normal hover:bg-slate-100 py-2 px-3 cursor-pointer">
                          Events
                        </div>
                      </Link>
                      <Link
                        to="/offerings/expert-support"
                        className="no-underline text-dark w-full"
                      >
                        <div className="w-full text-dark text-sm font-normal hover:bg-slate-100 py-2 px-3 cursor-pointer">
                          Expert Support
                        </div>
                      </Link>
                      <Link
                        to="/offerings/chapters-clubs"
                        className="no-underline text-dark w-full"
                      >
                        <div className="w-full text-dark text-sm font-normal hover:bg-slate-100 py-2 px-3 cursor-pointer">
                          Chapters and Clubs
                        </div>
                      </Link>
                    </div>
                  </div>
                )}
                <div className="flex justify-center items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </div>
              </div>
              <div className=" mx-2 flex flex-row text-normal font-bold hover:bg-slate-100 rounded-full px-2 py-1 cursor-pointer">
                <Link
                  to="/registration/organization"
                  target="_black"
                  className="no-underline text-dark"
                >
                  <div className="mx-1  flex justify-center items-center ">
                    For Organization
                  </div>
                </Link>
              </div>
              <div className=" mx-2 flex flex-row text-normal font-bold hover:bg-slate-100 rounded-full px-2 py-1 cursor-pointer">
                <div
                  className="mx-1 flex justify-center items-center "
                  onClick={() => {
                    setIsOpenForIndividual(!isOpenForIndividual);
                  }}
                >
                  For Your Facilitators
                </div>
                {isOpenForIndividual && (
                  <div
                    ref={dropdownRef}
                    className="absolute mt-8 z-50 py-2 bg-white border border-gray-300 rounded shadow"
                  >
                    <div className="w-full h-full flex justify-center items-start flex-col">
                      <Link
                        to="/becomeInstructor"
                        className="no-underline text-dark w-full"
                      >
                        <div className="w-full text-dark text-sm font-normal hover:bg-slate-100 py-2 px-3 cursor-pointer">
                          Become an Instructor
                        </div>
                      </Link>
                      <Link
                        to="/becomeExpert"
                        className="no-underline text-dark w-full"
                      >
                        <div className="w-full text-dark text-sm font-normal hover:bg-slate-100 py-2 px-3 cursor-pointer">
                          Become an Expert
                        </div>
                      </Link>
                      <Link
                        to="/becomeSpeaker"
                        className="no-underline text-dark w-full"
                      >
                        <div className="w-full text-dark text-sm font-normal hover:bg-slate-100 py-2 px-3 cursor-pointer">
                          Become an Speaker
                        </div>
                      </Link>
                    </div>
                  </div>
                )}
                <div className="flex justify-center items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </div>
              </div>
            </div>
          )}
        <ul className="hidden absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 lg:flex lg:mx-auto  lg:items-center lg:w-auto lg:space-x-6">
          {isLearner &&
            !(
              window.location.pathname.includes("/home") ||
              window.location.pathname.includes("/offerings/programs") ||
              window.location.pathname.includes("/offerings/assessments") ||
              window.location.pathname.includes("/offerings/events") ||
              window.location.pathname.includes("/offerings/expert-support") ||
              window.location.pathname.includes("/offerings/chapters-clubs") ||
              window.location.pathname.includes("/becomeInstructor") ||
              window.location.pathname.includes("/becomeExpert") ||
              window.location.pathname.includes("/becomeSpeaker") ||
              window.location.pathname.includes("/registration/organization") ||
              window.location.pathname.includes("/registration/instructor") ||
              window.location.pathname.includes("/registration/expert") ||
              window.location.pathname.includes(
                "/registration/eventContributor"
              )
            ) && (
              <>
                {/* <li className="nav-item">
                <Link
                  to={"/"}
                  className={
                    ["/user", "/"].includes(window.location.pathname)
                      ? "no-underline text-lg text-blue-600 font-bold"
                      : "no-underline text-lg text-gray-400 hover:text-gray-500"
                  }
                >
                  Dashboard
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={"/programs"}
                  className={
                    window.location.pathname.includes("/programs") ||
                    window.location.pathname.includes("/program-overview") ||
                    window.location.pathname.includes("/program-content")
                      ? "no-underline text-lg text-blue-600 font-bold"
                      : "no-underline text-lg text-gray-400 hover:text-gray-500"
                  }
                >
                  Programs
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={"/assessments"}
                  className={
                    window.location.pathname.includes("/assessments")
                      ? "no-underline text-lg text-blue-600 font-bold"
                      : "no-underline text-lg text-gray-400 hover:text-gray-500"
                  }
                >
                  Assessments
                </Link>
              </li>
              <li className="nav-item w-36 text-center">
                <Link
                  to={"/expert-support"}
                  className={
                    window.location.pathname.includes("/expert-support")
                      ? "no-underline text-lg text-blue-600 font-bold"
                      : "no-underline text-lg text-gray-400 hover:text-gray-500"
                  }
                >
                  Expert Support
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={"/events"}
                  className={
                    window.location.pathname.includes("/events")
                      ? "no-underline text-lg text-blue-600 font-bold"
                      : "no-underline text-lg text-gray-400 hover:text-gray-500"
                  }
                >
                  Events
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={"/event/competitions"}
                  className={
                    window.location.pathname.includes("/event/competitons")
                      ? "no-underline text-lg text-blue-600 font-bold"
                      : "no-underline text-lg text-gray-400 hover:text-gray-500"
                  }
                >
                  Competitions
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={"/institute/2/chapters"}
                  className={
                    window.location.pathname.includes("/institute/2/chapters")
                      ? "no-underline text-lg text-blue-600 font-bold"
                      : "no-underline text-lg text-gray-400 hover:text-gray-500"
                  }
                >
                  Chapter
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={"/institute/2/clubs"}
                  className={
                    window.location.pathname.includes("/institute/2/clubs")
                      ? "no-underline text-lg text-blue-600 font-bold"
                      : "no-underline text-lg text-gray-400 hover:text-gray-500"
                  }
                >
                  Clubs
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={"/institute/2/collaborations"}
                  className={
                    window.location.pathname.includes("/institute/2/collaborations")
                      ? "no-underline text-lg text-blue-600 font-bold"
                      : "no-underline text-lg text-gray-400 hover:text-gray-500"
                  }
                >
                  Collaborations
                </Link>
              </li> */}
              </>
            )}
        </ul>
        {currentUser ? (
          <div className="hidden lg:flex lg:items-center lg:w-auto">
            {window.location.pathname.includes("/") && (
              <div className="flex flex-row w-[30rem]">
                <div className="flex flex-row w-full ">
                  <div className="flex justify-start ml-3"></div>
                </div>
              </div>
            )}
            <div className="relative px-3">
              <div className="relative inline-block text-left bg-transparent">
                <div>
                  <button
                    type="button"
                    className="inline-flex justify-center w-full border-0 shadow-none py-2 bg-transparent text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    id="options-menu"
                    aria-haspopup="true"
                    aria-expanded="true"
                    onClick={() => {
                      setNotificationDropdown(!notificationDropdown);
                      // set all unread messages as read
                      if (notifications) {
                        notifications.forEach((notification) => {
                          if (!notification.read) {
                            dispatch(
                              updateNotification({
                                notification_id: notification.notification_id,
                                is_read: true,
                              })
                            );
                            setRefreshNav(!refreshNav);
                          }
                        });
                      }
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                      />
                    </svg>
                    {
                      // number of unread notifications
                      Array.isArray(notifications) &&
                      notifications?.filter(
                        (notification) => !notification.is_read
                      ).length ? (
                        <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
                          {
                            notifications?.filter(
                              (notification) => !notification.is_read
                            ).length
                          }
                        </span>
                      ) : null
                    }
                  </button>
                </div>
              </div>
              {notificationDropdown ? (
                <>
                  {notifications.length ? (
                    <>
                      <Notifications notifications={notifications} />
                    </>
                  ) : (
                    <div className="absolute right-0 mt-2 w-96 bg-white rounded-md overflow-hidden shadow-xl z-10">
                      <div className="px-4 py-3">
                        <p className="text-sm leading-5">No notifications</p>
                      </div>
                    </div>
                  )}
                </>
              ) : null}
            </div>
            <div className="relative px-3">
              <button
                className="relative z-10 block h-8 w-8 rounded-full overflow-hidden shadow focus:outline-none"
                onClick={() => setProfileDropdown(!profileDropdown)}
              >
                <img
                  loading="lazy"
                  className="h-full w-full object-cover"
                  src={
                    currentUser.avatar
                      ? `${process.env.REACT_APP_HOST}/files/` +
                        currentUser.avatar
                      : "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"
                  }
                  alt="Your avatar"
                />
              </button>
              {profileDropdown ? (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md overflow-hidden shadow-xl z-10">
                  <Link
                    to={`/profile/${currentUser.user_id}`}
                    className="no-underline block px-4 py-2 text-sm text-gray-700 hover:bg-blue-600 hover:text-white"
                  >
                    Profile
                  </Link>
                  <Link
                    to={"/change-password"}
                    className="no-underline block px-4 py-2 text-sm text-gray-700 hover:bg-blue-600 hover:text-white"
                  >
                    Change Password
                  </Link>
                  <div
                    className="no-underline block px-4 py-2 text-sm text-gray-700 hover:bg-blue-600 hover:text-white"
                    onClick={() => {
                      logOut();
                      navigate("/sign-in");
                    }}
                  >
                    Sign out
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <>
            <div className="flex flex-row w-full ">
              <div className="flex justify-start ml-3 md:basis-1/2">
                <div className=" mx-2 flex flex-row text-normal font-bold hover:bg-slate-100 rounded-full px-2 py-1 cursor-pointer">
                  <div
                    className="mx-1 flex justify-center items-center "
                    onClick={() => {
                      setOffering(!offering);
                    }}
                  >
                    Offerings
                  </div>
                  {offering && (
                    <div
                      ref={offeringdropdownRef}
                      className="absolute mt-8 z-50 py-2 bg-white border border-gray-300 rounded shadow"
                    >
                      <div className="w-full h-full flex justify-center items-start flex-col">
                        <Link
                          to="/offerings/programs"
                          className="no-underline text-dark w-full"
                        >
                          <div className="w-full text-dark text-sm font-normal hover:bg-slate-100 py-2 px-3 cursor-pointer">
                            Programs and Courses
                          </div>
                        </Link>
                        <Link
                          to="/offerings/assessments"
                          className="no-underline text-dark w-full"
                        >
                          <div className="w-full text-dark text-sm font-normal hover:bg-slate-100 py-2 px-3 cursor-pointer">
                            Assessments
                          </div>
                        </Link>
                        <Link
                          to="/offerings/events"
                          className="no-underline text-dark w-full"
                        >
                          <div className="w-full text-dark text-sm font-normal hover:bg-slate-100 py-2 px-3 cursor-pointer">
                            Events
                          </div>
                        </Link>
                        <Link
                          to="/offerings/expert-support"
                          className="no-underline text-dark w-full"
                        >
                          <div className="w-full text-dark text-sm font-normal hover:bg-slate-100 py-2 px-3 cursor-pointer">
                            Expert Support
                          </div>
                        </Link>
                        <Link
                          to="/offerings/chapters-clubs"
                          className="no-underline text-dark w-full"
                        >
                          <div className="w-full text-dark text-sm font-normal hover:bg-slate-100 py-2 px-3 cursor-pointer">
                            Chapters and Clubs
                          </div>
                        </Link>
                      </div>
                    </div>
                  )}
                  <div className="flex justify-center items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </div>
                </div>
                <div className=" mx-2 flex flex-row text-normal font-bold hover hover:bg-slate-100 rounded-full px-2 py-1 cursor-pointer">
                  <Link to="/sign-in" className="no-underline text-dark">
                    <div className="mx-1  flex justify-center items-center ">
                      For Organization
                    </div>
                  </Link>
                </div>
                <div className=" mx-2 flex flex-row text-normal font-bold hover:bg-slate-100 rounded-full px-2 py-1 cursor-pointer">
                  <div
                    className="mx-1 flex justify-center items-center "
                    onClick={() => {
                      setIsOpenForIndividual(!isOpenForIndividual);
                    }}
                  >
                    For Your Facilitators
                  </div>
                  {isOpenForIndividual && (
                    <div
                      ref={dropdownRef}
                      className="absolute mt-8 z-50 py-2 bg-white border border-gray-300 rounded shadow"
                    >
                      <div className="w-full h-full flex justify-center items-start flex-col">
                        <Link
                          to="/becomeInstructor"
                          className="no-underline text-dark w-full"
                        >
                          <div className="w-full text-dark text-sm font-normal hover:bg-slate-100 py-2 px-3 cursor-pointer">
                            Become an Instructor
                          </div>
                        </Link>
                        <Link
                          to="/becomeExpert"
                          className="no-underline text-dark w-full"
                        >
                          <div className="w-full text-dark text-sm font-normal hover:bg-slate-100 py-2 px-3 cursor-pointer">
                            Become an Expert
                          </div>
                        </Link>
                        <Link
                          to="/becomeSpeaker"
                          className="no-underline text-dark w-full"
                        >
                          <div className="w-full text-dark text-sm font-normal hover:bg-slate-100 py-2 px-3 cursor-pointer">
                            Become an Speaker
                          </div>
                        </Link>
                      </div>
                    </div>
                  )}
                  <div className="flex justify-center items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="flex justify-end items-center w-full md:basis-1/2">
                <div>
                  {/* <a
                    className="no-underline hidden lg:inline-block lg:ml-auto lg:mr-3 py-2 px-6  hover:bg-gray-100 text-sm text-gray-900 font-bold  rounded-xl transition duration-200"
                    href="/sign-in"
                  >
                    Sign In
                  </a> */}
                  {/* <a
                    className="no-underline hidden lg:inline-block py-2 px-6 bg-blue-500 hover:bg-blue-600 text-sm text-white font-bold rounded-xl transition duration-200"
                    href="/sign-up"
                  >
                    Sign up
                  </a> */}
                  {/* convert to Link */}
                  <Link
                    to="/sign-in"
                    className="no-underline hidden lg:inline-block lg:ml-auto lg:mr-3 py-2 px-6  hover:bg-gray-100 text-sm text-gray-900 font-bold  rounded-xl transition duration-200"
                  >
                    Sign In
                  </Link>
                  <Link
                    to="/sign-up"
                    className="no-underline hidden lg:inline-block py-2 px-6 bg-blue-500 hover:bg-blue-600 text-sm text-white font-bold rounded-xl transition duration-200"
                  >
                    Sign up
                  </Link>
                </div>
              </div>
            </div>
          </>
        )}
      </nav>
      <div
        className={`navbar-menu relative z-50 w-full lg:hidden ${
          smallScreenNavShow ? "block" : "hidden"
        }`}
      >
        <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"></div>
        <nav className="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white border-r overflow-y-auto">
          <div className="flex items-center mb-8">
            <a className="mr-auto text-3xl font-bold leading-none" href="/">
              <img
                loading="lazy"
                src={Logo}
                className="rounded h-10 w-10"
                alt="Logo"
                height={38}
              />
            </a>
            <button
              className="navbar-close"
              onClick={() => {
                setSmallScreenNavShow(false);
              }}
            >
              <svg
                className="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
          <div>
            <ul>
              {isLearner && (
                <>
                  <li className="mb-1">
                    <Link
                      to={"/user"}
                      className={
                        ["/user", "/"].includes(window.location.pathname)
                          ? "block p-3 no-underline text-blue-600 font-bold"
                          : "block p-3 no-underline text-gray-400 hover:text-gray-500"
                      }
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li className="mb-1">
                    <Link
                      to={"/programs"}
                      className={
                        window.location.pathname === "/programs"
                          ? "block p-3 no-underline text-blue-600 font-bold"
                          : "block p-3 no-underline text-gray-400 hover:text-gray-500"
                      }
                    >
                      Programs
                    </Link>
                  </li>

                  <li className="mb-1">
                    <Link
                      to={"/assessments"}
                      className={
                        window.location.pathname === "/assessments"
                          ? "block p-3 no-underline text-blue-600 font-bold"
                          : "block p-3 no-underline text-gray-400 hover:text-gray-500"
                      }
                    >
                      Assessments
                    </Link>
                  </li>
                  <li className="mb-1">
                    <Link
                      to={"/events"}
                      className={
                        window.location.pathname === "/events"
                          ? "block p-3 no-underline text-blue-600 font-bold"
                          : "block p-3 no-underline text-gray-400 hover:text-gray-500"
                      }
                    >
                      Events
                    </Link>
                  </li>

                  <li className="mb-1">
                    <Link
                      to={"/expert-support"}
                      className={
                        window.location.pathname === "/expert-support"
                          ? "block p-3 no-underline text-blue-600 font-bold"
                          : "block p-3 no-underline text-gray-400 hover:text-gray-500"
                      }
                    >
                      Expert Support
                    </Link>
                  </li>
                  <li className="mb-1">
                    <Link
                      to={"/assessments"}
                      className={
                        window.location.pathname === "/assessments"
                          ? "block p-3 no-underline text-blue-600 font-bold"
                          : "block p-3 no-underline text-gray-400 hover:text-gray-500"
                      }
                    >
                      Chapter
                    </Link>
                  </li>
                  <li className="mb-1">
                    <Link
                      to={"/assessments"}
                      className={
                        window.location.pathname === "/assessments"
                          ? "block p-3 no-underline text-blue-600 font-bold"
                          : "block p-3 no-underline text-gray-400 hover:text-gray-500"
                      }
                    >
                      Club
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
          {currentUser ? (
            <div className="mt-auto">
              <div className="flex items-center mb-4 -mx-2">
                <div className="px-2">
                  <img
                    loading="lazy"
                    className="h-10 w-10 rounded-full"
                    src={
                      currentUser.avatar
                        ? currentUser.avatar
                        : "https://ui-avatars.com/api/?name=" +
                          currentUser.username
                    }
                    alt="Avatar"
                  />
                </div>
                <div className="px-2">
                  <div className="relative inline-block text-left">
                    <div>
                      <button
                        type="button"
                        className="inline-flex justify-center w-full border-0 shadow-none px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        id="options-menu"
                        aria-haspopup="true"
                        aria-expanded="true"
                        onClick={() =>
                          setNotificationDropdown(!notificationDropdown)
                        }
                      >
                        <BiBell size={25} color={"gray"} />
                      </button>
                    </div>
                  </div>
                  {notificationDropdown ? (
                    <div className="absolute right-0 mt-2 w-48 bg-white rounded-md overflow-hidden shadow-xl z-10 border-2">
                      <a
                        href="#"
                        className=" no-underline cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-blue-600 hover:text-white"
                      >
                        Piyush Kumar replied to your Query
                      </a>
                      <a
                        href="#"
                        className=" no-underline cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-blue-600 hover:text-white"
                      >
                        Piyush Kumar Created a New course
                      </a>
                      <a
                        href="#"
                        className=" no-underline cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-blue-600 hover:text-white"
                      >
                        Check all notifications
                      </a>
                    </div>
                  ) : null}
                </div>
                <div className="px-2">
                  <div className="text-gray-400 text-xs">Signed in as</div>
                  <div className="text-gray-700 font-bold text-sm">
                    {currentUser.username}
                  </div>
                </div>
              </div>
              <div className="flex mb-6 -mx-3">
                <div className="px-3">
                  <a
                    href={`/profile/${currentUser.user_id}`}
                    className="block text-center no-underline text-gray-400 hover:text-gray-500"
                  >
                    <svg
                      className="h-6 w-6 mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    ></svg>
                    <div className="text-xs font-bold">Profile</div>
                  </a>
                </div>
                <div className="px-3">
                  <a
                    onClick={() => {
                      logOut();
                      navigate("/sign-in");
                    }}
                    className="block text-center no-underline text-gray-400 hover:text-gray-500"
                  >
                    <svg
                      className="h-6 w-6 mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    ></svg>
                    <div className="text-xs font-bold">Logout</div>
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-auto">
              <div className="pt-6">
                <a
                  className="block px-4 py-3 mb-3 leading-loose text-xs text-center font-semibold  hover:bg-gray-100 rounded-xl"
                  href="sign-in"
                >
                  Sign in
                </a>
                <a
                  className="block px-4 py-3 mb-2 leading-loose text-xs text-center text-white font-semibold bg-blue-600 hover:bg-blue-700  rounded-xl"
                  href="sign-up"
                >
                  Sign Up
                </a>
              </div>
            </div>
          )}
          <p className="my-4 text-xs text-center text-gray-400">
            <span>Copyright © 2023</span>
          </p>
        </nav>
      </div>
    </>
  );
};

export default Navigationbar;
