import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';

const getLocalToUTCDatetime = ({ datetime, format }) => {
    dayjs.extend(utc);
    // Convert the given datetime to UTC. If no datetime is provided, use the current time.
    // Format the UTC datetime according to the provided format, or use a default format.
    return dayjs(datetime ? datetime : Date.now()).utc().format(format ? format : 'YYYY-MM-DDTHH:mm:ss[Z]');
}

export default getLocalToUTCDatetime;
